import React from 'react'
import { ImageContain } from '../../../components/primitives/ImageCover'
import { Flex, Text } from '../../../components/primitives'
import ClearIcon from '@material-ui/icons/Clear'

export const Item = ({ item, onRemove }) => {
  return (
    <Flex
      pt={2}
      minHeight={'100px'}
      alignItems={'center'}
      pb={2}
      bg={item.inStock ? 'white' : '#F0F0F0'}
      style={{ borderBottom: '1px solid grey' }}>
      <ImageContain height={'60px'} width={'60px'} src={item.imgSrc} />
      <Flex
        ml={4}
        jusifyContent={'center'}
        flexDirection={'column'}
        width={'100%'}>
        {item.inStock && (
          <Flex width={'100%'} jusifyContent={'flex-end'}>
            <ClearIcon
              onClick={onRemove}
              style={{ fontSize: '15px', cursor: 'pointer' }}
            />
          </Flex>
        )}
        <Text style={{ overflow: 'hidden', height: '20px' }} fontWeight={600}>
          {item.title}
        </Text>
        <Text>Antal:{item.quantity}st</Text>
        <Text>{item.price}kr</Text>
        {item.deliveryTime && <Text>{item.deliveryTime}</Text>}

        {!item.inStock && (
          <Text color={'red'}>Produkt inte längre tillgänglig</Text>
        )}
      </Flex>
    </Flex>
  )
}

export const EllosItem = ({ item, onClick }) => {
  return (
    <Flex
      pt={2}
      minHeight={'100px'}
      alignItems={'center'}
      pb={2}
      bg={'#F0F0F0'}
      style={{ borderBottom: '1px solid grey' }}>
      <ImageContain height={'60px'} width={'60px'} src={item.imgSrc} />
      <Flex
        ml={4}
        jusifyContent={'center'}
        flexDirection={'column'}
        width={'100%'}>
        <Text style={{ overflow: 'hidden', height: '20px' }} fontWeight={600}>
          {item.title}
        </Text>
        <Text>Antal:{item.quantity}st</Text>
        <Text>{item.price}kr</Text>
        {item.deliveryTime && <Text>{item.deliveryTime}</Text>}
        <Text style={{ cursor: 'pointer' }} color={'red'} onClick={onClick}>
          Produkt inte tillgänglig. Går att köpa via deras sida genom att klicka
          här
        </Text>
      </Flex>
    </Flex>
  )
}
