import { makeStyles } from '@material-ui/styles'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel'
import FileContainer from '../../../components/FileContainer'
import AddFilesButton from '../../../components/AddFilesButton'

const useStyles = makeStyles(theme => ({
  filesLabel: {
    flex: 1
  },
  image: {
    width: 'auto',
    height: 'auto',
    maxHeight: '100%',
    maxWidth: '100%'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function ProductDialogContent ({
  name,
  price,
  currency,
  url,
  files,
  onNameChanged,
  onPriceChanged,
  onCurrencyChanged,
  onUrlChanged,
  onFilesChanged,
  onFileDeleted,
  onBlurName,
  onBlurPrice,
  onBlurCurrency,
  errors,
  ...otherProps
}) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <TextField
        autoFocus
        autoComplete='product-form product-name'
        margin='dense'
        id='name'
        label='Name'
        type='text'
        name='name'
        required
        fullWidth
        value={name}
        onChange={onNameChanged}
        onBlur={onBlurName}
        error={Boolean(errors.name)}
        helperText={errors.name}
      />
      <Grid container spacing={1}>
        <Grid item xs={8} >
          <TextField
            autoComplete='product-form transaction-amount'
            margin='dense'
            name='price'
            label='Price'
            type='number'
            required
            value={price}
            onChange={onPriceChanged}
            onBlur={onBlurPrice}
            error={Boolean(errors.price)}
            helperText={errors.price}
          />
        </Grid>
        <Grid item xs={4} >
          <TextField
            autoComplete='product-form transaction-currency'
            margin='dense'
            name='currency'
            label='Currency'
            type='text'
            required
            value={currency}
            onChange={onCurrencyChanged}
            onBlur={onBlurCurrency}
            error={Boolean(errors.currency)}
            helperText={errors.currency}
          />
        </Grid>
      </Grid>
      <TextField
        autoComplete='product-form url'
        margin='dense'
        name='url'
        label='Link'
        type='url'
        fullWidth
        value={url}
        onChange={onUrlChanged}
      />
      <Box py={2} width={1} display='flex'
      >
        <InputLabel shrink className={classes.filesLabel}>
          Files
        </InputLabel>
        <AddFilesButton onAddFiles={onFilesChanged} />
      </Box>
      <FileContainer multiple files={files} onAddFiles={onFilesChanged} onDeleteFile={onFileDeleted} />
    </React.Fragment>
  )
}

export default ProductDialogContent
