import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Flex, Text } from '../../components/primitives'
import { HookInput } from '../../components/sharable/form/HookInput'
import { ButtonWithLoading } from '../../components/sharable/button'
import { useMutation } from '@apollo/react-hooks'
import { showAlert, showError } from '../../modules/Alert'
import { UPDATE_CUSTOMER_ORDER } from '../../gql/orders'

const BasicSchema = Yup.object().shape({
  email: Yup.string().required('email is required')
})

export const EditOrderForm = ({ initial, orderId }) => {
  const [formLoading, setFormLoading] = useState(false)
  const [updateCustomerOrder] = useMutation(UPDATE_CUSTOMER_ORDER)

  return (
    <Flex
      width={'100%'}
      maxWidth={'700px'}
      bg={'white'}
      p={4}
      style={{ borderRadius: '5px' }}
      flexDirection={'column'}>
      <Formik
        enableReinitialize
        initialValues={{
          email: initial.email ? initial.email : '',
          phone: initial.phone ? initial.phone : ''
        }}
        validationSchema={BasicSchema}
        onSubmit={(values) => {
          setFormLoading(true)

          updateCustomerOrder({
            variables: {
              orderId: orderId,
              email: values.email.trim(),
              phone: values.phone ? values.phone.trim() : ''
            },
            update: (proxy, mutationResult) => {
              console.log(mutationResult)
              if (!mutationResult.data.updateCustomerOrder.success) {
                showError({
                  message: mutationResult.data.updateCustomerOrder.message
                })
                setFormLoading(false)
              } else {
                showAlert({ message: 'Saved!  👏🏄‍️💾' })
                setFormLoading(false)
              }
            }
          })
        }}>
        {(props) => (
          <form style={{ width: '100%' }} onSubmit={props.handleSubmit}>
            <Text fontWeight={'bold'} fontSize={5}>
              Edit customer data
            </Text>

            <Flex mb={2} mt={2} flexDirection={'column'}>
              <Text>Email</Text>
              <HookInput name={'email'} style={{ width: '100%' }} />
              {props.errors.email && (
                <Text color={'red'}>{props.errors.email}</Text>
              )}
            </Flex>

            <Flex mb={2} mt={2} flexDirection={'column'}>
              <Text>Phone</Text>
              <HookInput name={'phone'} style={{ width: '100%' }} />
            </Flex>

            <Flex
              style={{ justifyContent: 'flex-end' }}
              width={'100%'}
              mt={2}
              mb={2}>
              <ButtonWithLoading isLoading={formLoading} type='submit'>
                Save
              </ButtonWithLoading>
            </Flex>
          </form>
        )}
      </Formik>
    </Flex>
  )
}
