import { gql } from 'apollo-boost'

export const ADD_DISCOUNT = gql`
  mutation($name: String!, $discount: Int!) {
    addDiscount(name: $name, discount: $discount) {
      success
      message
    }
  }
`

export const DELETE_DISCOUNT = gql`
  mutation($id: ID!) {
    deleteDiscount(id: $id) {
      success
      message
    }
  }
`

export const DISCOUNTS = gql`
  query {
    discounts {
      _id
      createdAt
      updatedAt
      name
      value
    }
  }
`
