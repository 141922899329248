import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from './DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/styles'
import { SecondaryButton } from '../sharable/button/'
import { Button } from '../primitives/'

const useStyles = makeStyles((theme) => {
  return {
    dialogContentText: {
      padding: theme.spacing(2),
      alignSelf: 'center'
    }
  }
})

export default function EditItemDialog({
  fullScreen,
  onClose,
  onSubmit,
  disableSubmit,
  title,
  contentText,
  formId,
  children,
  submitText
}) {
  const classes = useStyles()

  return (
    <Dialog open fullScreen={fullScreen} onClose={onClose}>
      <DialogTitle id='create-admin' onClose={onClose}>
        {title || 'Create Form'}
      </DialogTitle>
      {contentText && (
        <DialogContentText className={classes.dialogContentText}>
          {contentText}
        </DialogContentText>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <SecondaryButton mr={2} onClick={onClose} color='primary'>
          Cancel
        </SecondaryButton>
        <Button type='submit' form={formId} color='primary'>
          {disableSubmit && <CircularProgress size={24} />}
          {submitText || 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
