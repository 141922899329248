import React, { useRef } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'

function AddFilesButton({
  ButtonComponent = IconButton,
  multiple,
  onAddFiles,
  children,
  ...otherProps
}) {
  const fileInputRef = useRef()

  const handleClick = (e) => {
    fileInputRef.current.click()
  }

  const handleAddFiles = (e) => {
    if (e.target.files && e.target.files.length > 0 && onAddFiles) {
      const files = []
      for (let i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i])
      }
      onAddFiles(files)
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title='Add file'>
        <IconButton size='small' onClick={handleClick} {...otherProps}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <input
        ref={fileInputRef}
        type='file'
        name='files'
        multiple={multiple ? true : undefined}
        style={{ display: 'none' }}
        onChange={handleAddFiles}
      />
    </div>
  )
}

export default AddFilesButton
