import { createMuiTheme } from '@material-ui/core/styles'
import { colors } from './colors'

const theme = createMuiTheme({
  palette: {
    background: {
    },
    primary: {
      main: colors.primary,
      light: colors.primaryLight,
      dark: colors.primaryDark
    },
    secondary: {
      main: colors.secondary,
      light: colors.secondaryLight,
      dark: colors.secondaryDark
    },
    dark: colors.dark
  },
  typography: {
    fontFamily: 'Nunito, Roboto, sans-serif'
  }
})

export default theme
