import React, { useEffect } from 'react'
import { useDebounce } from '../../hooks/useDebounce'
import TextField from '@material-ui/core/TextField'

export const SearchInput = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 700)

  useEffect(
    () => {
      onSearch(debouncedSearchTerm)
    },
    /* eslint-disable-next-line */
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  )

  return (
    <TextField
      value={searchTerm}
      placeholder={'Search on order id'}
      margin='normal'
      onKeyDown={() => {}}
      onChange={(e) => {
        setSearchTerm(e.target.value)
      }}
    />
  )
}
