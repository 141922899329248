import React from 'react'
import { Button } from '../../../components/primitives'
import { SecondaryButton } from '../../../components/sharable/button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { MoodboardCreator } from '../../../components/moodboard/MoodboardCreator'
import { clipTo } from '../../../modules/canvasHelpers'

export const MoodboardDialog = ({ open, onClose, onCreate }) => {
  let canvas

  return (
    <Dialog
      fullWidth={true}
      fullScreen={true}
      maxWidth={'lg'}
      open={open}
      onClose={onClose}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>
        Create moodboard (double click or drag and drop to add images)
      </DialogTitle>
      <DialogContent style={{  minWidth: '800px', minHeight: '600px' }}>
        <MoodboardCreator
          onFabricCanvasCreated={(f) => {
            canvas = f
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          mr={2}
          onClick={() => {
            canvas.getObjects().forEach((obj) => {
              if (obj.isImage) {
                canvas.sendToBack(obj)
                obj.clipTo = clipTo
                obj.opacity = 1
                obj.selectable = true
              }
            })
            canvas.renderAll()
            canvas.discardActiveObject().renderAll()
          }}>
          Done
        </Button>
        <SecondaryButton bg={'secondary'} mr={2} onClick={onClose}>
          Cancel
        </SecondaryButton>
        <Button
          onClick={() => {
            canvas.getObjects().forEach((obj) => {
              if (obj.isImage) {
                obj.clipTo = clipTo
                obj.opacity = 1
                obj.selectable = true
                canvas.sendToBack(obj)
              }
            })
            canvas.renderAll()
            onClose()
            //canvas.backgroundColor = '#fff'
            onCreate(canvas.toDataURL('png'))
          }}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
