import React, { Fragment, useContext, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import { Button } from '../components/primitives'
import { NavLink, withRouter } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import UserIcon from '@material-ui/icons/AccountCircleOutlined'
import Face from '@material-ui/icons/Face'
import OrderIcon from '@material-ui/icons/AssignmentOutlined'
import AttachMoney from '@material-ui/icons/AttachMoney'
import CardGiftcard from '@material-ui/icons/CardGiftcard'
import Menu from '@material-ui/icons/Menu'
import Create from '@material-ui/icons/Create'
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import AuthContext from '../modules/AuthContext'
import logo from '../assets/images/hemset-horizontal.png'
import {
  isAdmin,
  isCustomer,
  isDecorator,
  isMasterAdmin
} from '../modules/const'
import { Flex, Image, Text } from './primitives'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'

const useStyles = makeStyles((theme) => {
  return {
    navBar: {
      zIndex: 900,
      boxShadow: 'none',
      backgroundColor: 'white'
    },
    appBar: {
      flexGrow: 1,
      justifyContent: 'space-between'
    },
    userNavRoot: {
      display: 'flex',
      alignItems: 'flex-end'
    },
    adminNavRoutes: {
      marginRight: 10
    },
    button: {
      color: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark
    },
    logoWrapper: {
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer'
    },
    logo: {
      height: '32px'
    },
    navigationButton: {
      color: theme.palette.primary.dark,
      fontSize: 14,
      textDecoration: 'none',
      textTransform: 'uppercase',
      '&:hover': { cursor: 'pointer' },
      marginRight: theme.spacing(3)
    },
    activeClassName: {
      fontWeight: 'bold',
      color: theme.palette.secondary.dark
    },
    navigationItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
})

const getLogoutNavbar = (props, classes) => {
  return (
    <AppBar className={classes.navBar} position='sticky' color='default'>
      <Toolbar className={classes.appBar}>
        <Logo onClick={() => props.history.push(`/`)} {...props} />
        <Button
          className={classes.button}
          color='primary'
          variant='outlined'
          onClick={() => props.history.push('/login')}>
          Login
        </Button>
      </Toolbar>
    </AppBar>
  )
}

const getMasterAdminNavbar = (props, classes, drawerOpen, setDrawerOpen) => {
  const desktop = (
    <Fragment>
      {/*<NavLink
        to='/products'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <ProductIcon fontSize='small' />
          Products
        </Box>
      </NavLink>*/}

      <NavLink
        to='/orders'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <OrderIcon fontSize='small' />
          Orders
        </Box>
      </NavLink>
      <NavLink
        to='/users'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <UserIcon fontSize='small' />
          Users
        </Box>
      </NavLink>
      <NavLink
        to='/admin-invoices'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <AttachMoney fontSize='small' />
          Invoices
        </Box>
      </NavLink>

      <NavLink
        to='/blog'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <Create fontSize='small' />
          Blog
        </Box>
      </NavLink>

      <NavLink
        to='/instagram'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <StayCurrentPortrait fontSize='small' />
          Instagram
        </Box>
      </NavLink>

      <NavLink
        to='/discount'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <CardGiftcard fontSize='small' />
          Discount
        </Box>
      </NavLink>

      <NavLink
        exact
        activeClassName={classes.activeClassName}
        to='/'
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <HomeIcon fontSize='small' />
          Home
        </Box>
      </NavLink>

      <NavLink
        to='/profile'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <Face fontSize='small' />
          Profile
        </Box>
      </NavLink>
    </Fragment>
  )

  const mobile = (
    <Flex flexDirection={'column'} width={'100%'} mt={2}>
      {/*Ä<NavLink
        style={{ width: '100%', height: '45px' }}
        to='/products'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Products</Text>
        </Flex>
      </NavLink>*/}
      <NavLink
        style={{ width: '100%', height: '45px' }}
        to='/orders'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Orders</Text>
        </Flex>
      </NavLink>
      <NavLink
        style={{ width: '100%', height: '45px' }}
        to='/users'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Users</Text>
        </Flex>
      </NavLink>
      <NavLink
        style={{ width: '100%', height: '45px' }}
        to='/admin-invoices'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Invoices</Text>
        </Flex>
      </NavLink>

      <NavLink
        style={{ width: '100%', height: '45px' }}
        to='/blog'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Blog</Text>
        </Flex>
      </NavLink>

      <NavLink
        style={{ width: '100%', height: '45px' }}
        to='/instagram'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Instagram</Text>
        </Flex>
      </NavLink>

      <NavLink
        style={{ width: '100%', height: '45px' }}
        to='/discount'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Discount</Text>
        </Flex>
      </NavLink>

      <NavLink
        style={{ width: '100%', height: '45px' }}
        to='/'
        onClick={setDrawerOpen}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Home</Text>
        </Flex>
      </NavLink>
      <NavLink
        style={{ width: '100%', height: '45px' }}
        to='/profile'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Profile</Text>
        </Flex>
      </NavLink>
    </Flex>
  )
  return (
    <NavBar
      history={props.history}
      classes={classes}
      desktopChildren={desktop}
      mobileChildren={mobile}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
    />
  )
}

const getAdminNavbar = (props, classes, drawerOpen, setDrawerOpen) => {
  const desktop = (
    <Fragment>
      {/*<NavLink
        to='/products'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <ProductIcon fontSize='small' />
          Products
        </Box>
      </NavLink>*/}
      <NavLink
        to='/orders'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <OrderIcon fontSize='small' />
          Orders
        </Box>
      </NavLink>
      <NavLink
        to='/users'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <UserIcon fontSize='small' />
          Users
        </Box>
      </NavLink>

      <NavLink
        to='/blog'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <Create fontSize='small' />
          Blog
        </Box>
      </NavLink>

      <NavLink
        exact
        activeClassName={classes.activeClassName}
        to='/'
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <HomeIcon fontSize='small' />
          Home
        </Box>
      </NavLink>

      <NavLink
        to='/profile'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <Face fontSize='small' />
          Profile
        </Box>
      </NavLink>
    </Fragment>
  )

  const mobile = (
    <Fragment>
      <Flex flexDirection={'column'} width={'100%'} mt={2}>
        {/* <NavLink
          style={{ width: '100%', height: '45px' }}
          to='/products'
          onClick={setDrawerOpen}
          activeClassName={classes.activeClassName}
          className={classes.navigationButton}>
          <Flex
            width={'100%'}
            alignItems={'center'}
            height={'100%'}
            jusifyContent={'center'}>
            <Text textSize={4}>Products</Text>
          </Flex>
        </NavLink>*/}
        <NavLink
          style={{ width: '100%', height: '45px' }}
          to='/orders'
          onClick={setDrawerOpen}
          activeClassName={classes.activeClassName}
          className={classes.navigationButton}>
          <Flex
            width={'100%'}
            alignItems={'center'}
            height={'100%'}
            jusifyContent={'center'}>
            <Text textSize={4}>Orders</Text>
          </Flex>
        </NavLink>
        <NavLink
          style={{ width: '100%', height: '45px' }}
          to='/users'
          onClick={setDrawerOpen}
          activeClassName={classes.activeClassName}
          className={classes.navigationButton}>
          <Flex
            width={'100%'}
            alignItems={'center'}
            height={'100%'}
            jusifyContent={'center'}>
            <Text textSize={4}>Users</Text>
          </Flex>
        </NavLink>

        <NavLink
          style={{ width: '100%', height: '45px' }}
          to='/'
          onClick={setDrawerOpen}
          className={classes.navigationButton}>
          <Flex
            width={'100%'}
            alignItems={'center'}
            height={'100%'}
            jusifyContent={'center'}>
            <Text textSize={4}>Home</Text>
          </Flex>
        </NavLink>
        <NavLink
          style={{ width: '100%', height: '45px' }}
          to='/profile'
          onClick={setDrawerOpen}
          activeClassName={classes.activeClassName}
          className={classes.navigationButton}>
          <Flex
            width={'100%'}
            alignItems={'center'}
            height={'100%'}
            jusifyContent={'center'}>
            <Text textSize={4}>Profile</Text>
          </Flex>
        </NavLink>
      </Flex>
    </Fragment>
  )

  return (
    <NavBar
      history={props.history}
      classes={classes}
      desktopChildren={desktop}
      mobileChildren={mobile}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
    />
  )
}

const getDecoratorNavbar = (props, classes, drawerOpen, setDrawerOpen) => {
  const desktop = (
    <Fragment>
      {/* <NavLink
        to='/products'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <ProductIcon fontSize='small' />
          Products
        </Box>
      </NavLink>*/}
      <NavLink
        to='/orders'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <OrderIcon fontSize='small' />
          Orders
        </Box>
      </NavLink>
      <NavLink
        to='/invoice'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <AttachMoney fontSize='small' />
          Invoices
        </Box>
      </NavLink>
      <NavLink
        to='/profile'
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Box className={classes.navigationItem}>
          <Face fontSize='small' />
          Profile
        </Box>
      </NavLink>
    </Fragment>
  )

  const mobile = (
    <Flex flexDirection={'column'} width={'100%'} mt={2}>
      {/*<NavLink
        style={{ width: '100%', height: '45px' }}
        to='/products'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Products</Text>
        </Flex>
      </NavLink>*/}
      <NavLink
        style={{ width: '100%', height: '45px' }}
        to='/orders'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Orders</Text>
        </Flex>
      </NavLink>

      <NavLink
        style={{ width: '100%', height: '45px' }}
        to='/invoice'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Invoices</Text>
        </Flex>
      </NavLink>

      <NavLink
        style={{ width: '100%', height: '45px' }}
        to='/profile'
        onClick={setDrawerOpen}
        activeClassName={classes.activeClassName}
        className={classes.navigationButton}>
        <Flex
          width={'100%'}
          alignItems={'center'}
          height={'100%'}
          jusifyContent={'center'}>
          <Text textSize={4}>Profile</Text>
        </Flex>
      </NavLink>
    </Flex>
  )
  return (
    <NavBar
      history={props.history}
      classes={classes}
      desktopChildren={desktop}
      mobileChildren={mobile}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
    />
  )
}

const getCustomerNavbar = (props, classes, logout) => {
  return (
    <AppBar
      className={classes.navBar + ' dont-show-in-pdf'}
      position='sticky'
      color='default'>
      <Toolbar className={classes.appBar}>
        <Flex onClick={() => (window.location.href = `https://www.hemset.se/`)}>
          <Logo src={logo} classes={classes} alt='Logo' />
        </Flex>
      </Toolbar>
    </AppBar>
  )
}

const NavBar = ({
  desktopChildren,
  mobileChildren,
  classes,
  setDrawerOpen,
  drawerOpen,
  history
}) => {
  return (
    <Fragment>
      <AppBar
        className={classes.navBar + '  dont-show-in-pdf'}
        position='sticky'
        color='default'>
        <Toolbar className={classes.appBar}>
          <Flex display={['flex', 'flex', 'none']}>
            <IconButton
              onClick={setDrawerOpen}
              edge='start'
              color='inherit'
              aria-label='Menu'>
              <Menu />
            </IconButton>
          </Flex>
          <Logo onClick={() => history.push(`/`)} classes={classes} />
          <Flex
            flex={1}
            display={['none', 'none', 'flex']}
            jusifyContent={['flex-end']}
            flexDirection={['column', 'column', 'row']}>
            {desktopChildren}
          </Flex>
        </Toolbar>
      </AppBar>
      <Drawer variant='persistent' open={drawerOpen}>
        <Flex width={'250px'} jusifyContent={'flex-end'}>
          <IconButton onClick={setDrawerOpen}>
            <ChevronLeft />
          </IconButton>
        </Flex>
        <Flex width={'250px'}>{mobileChildren}</Flex>
      </Drawer>
    </Fragment>
  )
}

const Logo = ({ onClick }) => {
  return (
    <Flex onClick={onClick} display={['none', 'none', 'flex']}>
      <Image height={['0px', '0px', '35px']} src={logo} alt='Logo' />
    </Flex>
  )
}

function AppHeader(props) {
  const classes = useStyles()
  const { currentUser } = useContext(AuthContext)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const changeDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  console.log(currentUser)

  if (!currentUser) {
    return getLogoutNavbar(props, classes)
  }

  if (isMasterAdmin(currentUser['__typename'])) {
    return getMasterAdminNavbar(props, classes, drawerOpen, changeDrawer)
  }

  if (isAdmin(currentUser['__typename'])) {
    return getAdminNavbar(props, classes, drawerOpen, changeDrawer)
  }

  if (isDecorator(currentUser['__typename'])) {
    return getDecoratorNavbar(props, classes, drawerOpen, changeDrawer)
  }

  if (isCustomer(currentUser['__typename'])) {
    return getCustomerNavbar(props, classes)
  } else {
    // is no one
    return <div />
  }
}

export default withRouter(AppHeader)
