import React, { Fragment } from 'react'
import 'typeface-nunito'
import { withCookies } from 'react-cookie'
import { compose } from 'recompose'
import { Route, Switch } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppHeader from './components/AppHeader'
import OrdersPage from './pages/ordersPage/OrdersPage'
import AdminInvoicesPage from './pages/adminInvoices/AdminInvoicesPage'
import InvoicePage from './pages/invoicePage/InvoicePage'
import ProductsPage from './pages/productPage/ProductsPage'
import ProfilePage from './pages/profilePage/ProfilePage'
import LandingPage from './pages/LandingPage'
import CustomerPage from './pages/customerPage/CustomerPage'
import CommentFilesPage from './pages/CommentFilesPage'
import ProtectedRoute from './ProtectedRoute'
import LoginPage from './pages/loginPage/LoginPage'
import UserPage from './pages/userPage/UserPage'
import OrderPage from './pages/orderPage/OrderPage'
import { Footer } from './components/sharable'
import { Flex } from './components/primitives'
import { ApplyPage } from './pages/applyPage/ApplyPage'
import BlogPage from './pages/blogPage/BlogPage'
import InstagramPage from './pages/instagramPage/InstagramPage'
import CheckoutPage from './pages/checkout/CheckoutPage'
import DiscountPage from './pages/discountPage/DiscountPage'
import { EditOrderPage } from './pages/editOrderPage/EditOrderPage'
import CreateOrderPage from './pages/CreateOrderPage/CreateOrder'

function App() {
  return (
    <Fragment>
      <CssBaseline />
      <AppHeader />
      <Flex
        style={{ margin: 'auto' }}
        flexDirection={'column'}
        maxWidth={'1524px'}>
        <Switch>
          <Route path='/login' render={() => <LoginPage />} />
          <ProtectedRoute path='/users' component={UserPage} />
          <ProtectedRoute path='/products' component={ProductsPage} />
          <ProtectedRoute path='/profile' component={ProfilePage} />
          <ProtectedRoute path='/blog' component={BlogPage} />
          <ProtectedRoute path='/instagram' component={InstagramPage} />
          <ProtectedRoute path='/discount' component={DiscountPage} />
          <ProtectedRoute path='/editorder/:id' component={EditOrderPage} />

          <ProtectedRoute
            path={[
              '/orders',
              '/orders/incoming',
              '/orders/completed',
              '/orders/current'
            ]}
            component={OrdersPage}
          />
          <ProtectedRoute path={'/order/:id'} component={OrderPage} />
          <ProtectedRoute
            path={'/admin-invoices'}
            component={AdminInvoicesPage}
          />

          <ProtectedRoute path={'/invoice'} component={InvoicePage} />
          <ProtectedRoute path='/profile' component={ProfilePage} />
          <ProtectedRoute path='/create' component={CreateOrderPage} />

          <Route path='/customer/:orderId/:token' component={CustomerPage} />
          <Route path='/customercheckout/:orderId' component={CheckoutPage} />

          <Route
            path='/commentfiles/:orderId/:commentId'
            component={CommentFilesPage}
          />

          <Route path='/apply-decorator' render={() => <ApplyPage />} />

          <Route path='/' component={LandingPage} />
        </Switch>
      </Flex>
      <Switch>
        <Route path='/customer/:token' component={Footer} />
      </Switch>
    </Fragment>
  )
}
export default compose(withCookies)(App)
