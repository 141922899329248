import { gql } from 'apollo-boost'

const LIST_PRODUCTS = gql`
  query {
    products {
      _id
      name
      url
      price {
        value
        currency
      }
      creatorId
      files {
        origName
        name
        url
      }
    }
  }
`

const CREATE_PRODUCT = gql`
  mutation ($input:ProductInput!) {
    createProduct(input: $input) {
      success
      message
      product {
        _id
        name
        url
        price {
          value
          currency
        }
        creatorId
        files {
          origName
          name
          url
          uploadUrl
        }        
      }
    }
  }
`

const ARCHIVE_PRODUCT = gql`
  mutation ($id: ID!) {
    archiveProduct(id: $id) {
      success
      message
    }
  }
`

export { LIST_PRODUCTS, CREATE_PRODUCT, ARCHIVE_PRODUCT }
