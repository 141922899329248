import React from 'react'
import DecoratorApplications from './DecoratorApplications'
import { Flex, Text } from '../primitives'

const AdminDashboard = () => {
  return (
    <Flex flexDirection={'column'}>
      <Text fontSize={3} mb={2}>
        Incoming Decorator Requests
      </Text>

      <DecoratorApplications />
    </Flex>
  )
}

export default AdminDashboard
