import React, { useState } from 'react'
import useForm from '../../hooks/useForm'
import { Textfield } from '../../components/primitives'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { makeStyles } from '@material-ui/styles'
import { ButtonWithLoading } from '../../components/sharable/button'
import { useMutation } from '@apollo/react-hooks'
import { CHANGE_PASSWORD } from '../../gql/auth'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: '500px'
  },
  loginButton: {
    color: '#ffffff'
  },
  card: {
    padding: theme.spacing(4)
  },
  loginButtonWrapper: {
    marginTop: theme.spacing(4)
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    marginRight: 10
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    height: 48
  },
  errorSnackbar: {
    position: 'relative',
    marginTop: theme.spacing(4)
  },
  errorSnackbarContent: {
    backgroundColor: theme.palette.error.dark
  },
  succesSnackbarContent: {
    backgroundColor: '#3BA35E'
  }
}))

const validate = (values) => {
  let errors = {}
  if (!values.oldPassword) {
    errors.oldPassword = 'Old password is required'
  }
  if (!values.newPassword) {
    errors.newPassword = 'New password is required'
  }
  if (!values.newPasswordAgain) {
    errors.newPasswordAgain = 'new password is required'
  }

  return errors
}

function ChangePasswordForm({ initialValues, userId }) {
  const [changePassword] = useMutation(CHANGE_PASSWORD)
  const [errorSnack, setSetErrorSnack] = useState(false)
  const [successSnack, setSuccessSnack] = useState(false)

  const { values, errors, handleChange, handleSubmitTwo } = useForm(
    (vals, er) => {
      if (Object.keys(er).length === 0) {
        console.log(vals)
        submit(vals)
      }
    },
    validate,
    initialValues
  )
  const classes = useStyles()
  const [submiting, setSubmiting] = useState(false)

  const submit = (values) => {
    if (values.newPassword === values.newPasswordAgain) {
      setSubmiting(true)
      changePassword({
        variables: {
          currentPassword: values.oldPassword,
          newPassword: values.newPassword
        },
        update: (proxy, mutationResult) => {
          setSubmiting(false)
          if (!mutationResult.data.changePassword.success) {
            setSetErrorSnack(true)
          } else {
            console.log('saved!!')
            setSuccessSnack(true)
          }
        }
      })
    } else {
      alert('New password not the same')
    }
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <form
          onSubmit={handleSubmitTwo}
          className={classes.container}
          noValidate
          autoComplete='off'>
          <Textfield
            required
            error={errors.hasOwnProperty('oldPassword')}
            helperText={errors.oldPassword || ''}
            label='Old password'
            type='password'
            margin='normal'
            name='oldPassword'
            value={values.oldPassword || ''}
            onChange={handleChange}
          />

          <Textfield
            required
            error={errors.hasOwnProperty('newPassword')}
            helperText={errors.newPassword || ''}
            label='New password'
            type='password'
            margin='normal'
            name='newPassword'
            value={values.newPassword || ''}
            onChange={handleChange}
          />
          <Textfield
            required
            error={errors.hasOwnProperty('newPasswordAgain')}
            helperText={errors.newPasswordAgain || ''}
            label='New password again'
            type='password'
            margin='normal'
            name='newPasswordAgain'
            value={values.newPasswordAgain || ''}
            onChange={handleChange}
          />

          <ButtonWithLoading
            style={{ alignSelf: 'center' }}
            isLoading={submiting}
            mt={4}>
            Save
          </ButtonWithLoading>
        </form>
      </CardContent>
      <Snackbar
        open={errorSnack}
        className={classes.errorSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setSetErrorSnack(false)
        }}>
        <SnackbarContent
          className={classes.errorSnackbarContent}
          message={<span>Failed :(</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={() => {
                setSetErrorSnack(false)
              }}>
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
      <Snackbar
        open={successSnack}
        className={classes.errorSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setSuccessSnack(false)
        }}>
        <SnackbarContent
          className={classes.succesSnackbarContent}
          message={<span>Saved!</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={() => {
                setSuccessSnack(false)
              }}>
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    </Card>
  )
}

export default ChangePasswordForm
