import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useQuery } from '@apollo/react-hooks'
import { FILES_FROM_COMMENT } from '../gql/orders'

const useStyles = makeStyles((theme) => {
  return {
    holder: {
      marginTop: '50px',
      padding: '10px',
      display: 'flex',
      flexWrap: 'wrap'
    },
    imageHolder: {
      width: '100%',
      margin: '10px',
      maxWidth: '400px'
    },
    img: {
      width: '100%',
      objectFit: 'contain'
    }
  }
})

const CommentFilesPage = ({ match }) => {
  const classes = useStyles()

  const queryParams = {
    variables: {
      commentId: match.params.commentId,
      orderId: match.params.orderId
    }
  }
  const { data } = useQuery(FILES_FROM_COMMENT, queryParams)


  return (
    <div className={classes.holder}>
      {data &&
        data.filesFromComment &&
        data.filesFromComment.map((file, index) => {
          return (
            <div key={index} className={classes.imageHolder}>
              <a target='_blank' rel='noopener noreferrer' href={file.url}>
                {file.name ? file.name : 'Link'}
              </a>
            </div>
          )
        })}
    </div>
  )
}

export default CommentFilesPage
