import { Flex, Text } from '../primitives'
import { FlexWithHover } from './FlexWithHover'
import { ImageContain } from '../primitives/ImageCover'
import React from 'react'

export const ListItem = ({  files, onClick, title, subTitle, actions }) => {
  return (
    <FlexWithHover
      hoverColor={'#ededed'}
      mb={2}
      width={'100%'}
      height={['initial', '100px']}
      bg={'white'}
      p={2}
      flexDirection={['column', 'row']}
      jusifyContent={'space-between'}
      alignItems={'center'}>
      <Flex flex={1} flexDirection={['column', 'row']}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={files && files.length > 0 ? files[0].url : ''}>
          <ImageContain
            height={['50px']}
            width={['80px']}
            src={files && files.length > 0 ? files[0].url : ''}
          />
        </a>
        <Flex
          flex={1}
          onClick={onClick}
          ml={2}
          mr={2}
          jusifyContent={'center'}
          flexDirection={'column'}>
          <Text
            fontSize={3}
            color={'primary'}
            textAlign={['center', 'left']}
            fontWeight={'bold'}>
            {title}
          </Text>
          <Text textAlign={['center', 'left']}>{subTitle}</Text>
        </Flex>
      </Flex>
      <Flex>{actions()}</Flex>
    </FlexWithHover>
  )
}
