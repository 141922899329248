import styled from 'styled-components'
import { Button } from '../../primitives'
import { colors } from '../../../modules/colors'
import { lighten } from 'polished'

const SecondaryButton = styled(Button)`
  &:hover {
    background-color: ${lighten(0.05, colors.secondary)};
  }
  &:focus {
    outline: none;
  }
`

SecondaryButton.defaultProps = {
  bg: 'secondary'
}

export { SecondaryButton }
