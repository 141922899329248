import { gql } from 'apollo-boost'

const INVOICES = gql`
  query {
    invoices: invoices {
      _id
      decoratorId
      url
      createdAt
      sent
    }
  }
`

const ADMIN_INVOICES = gql`
  query {
    adminInvoices {
      _id
      decoratorId
      url
      createdAt
      sent
      paid
    }
  }
`

const SEND_INVOICE = gql`
  mutation($invoiceId: ID!) {
    sendInvoice(invoiceId: $invoiceId) {
      success
      message
    }
  }
`
const SET_INVOICE_TO_PAID = gql`
  mutation($invoiceId: ID!) {
    adminSetInvoiceToPaid(invoiceId: $invoiceId) {
      success
      message
    }
  }
`

export { INVOICES, SEND_INVOICE, ADMIN_INVOICES, SET_INVOICE_TO_PAID }
