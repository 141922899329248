import React from 'react'
import { makeStyles } from '@material-ui/styles'
import FileContainer from '../FileContainer'
import AddFilesButton from '../AddFilesButton'
import { PaperTitle } from '../../pages/orderPage/components'
import { Flex } from '../primitives'

const useStyles = makeStyles((theme) => {
  return {
    header: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  }
})

function FileBoard({
  moodboards,
  loading,
  onAddFile,
  onDeleteFile,
  title = 'MOODBOARD'
}) {
  const classes = useStyles()
  return (
    <div>
      <Flex mb={4} className={classes.header}>
        <PaperTitle title={title} />
        <AddFilesButton onAddFiles={onAddFile} />
      </Flex>
      <FileContainer
        busy={loading}
        multiple
        files={moodboards ? moodboards : []}
        onAddFiles={onAddFile}
        onDeleteFile={onDeleteFile}
      />
    </div>
  )
}

export default FileBoard
