import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Iframe from 'react-iframe'

import { Flex, Text } from '../../components/primitives'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ORDER } from '../../gql/orders'
import { EllosItem, Item } from './components/Item'
import { CREATE_CHECKOUT } from '../../gql/reach'
import { getAffiliateUrl } from '../../modules/util'

const CheckoutPage = ({ match }) => {
  const { data, error, loading, refetch } = useQuery(ORDER, {
    variables: { id: match.params.orderId },
    fetchPolicy: 'network-only'
  })

  const [createCheckout] = useMutation(CREATE_CHECKOUT)
  const [updateLoading, setUpdateLoading] = useState(false)

  const order = data ? data.order : null

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <div>Something went wrong!</div>
  }

  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      height={['initial', 'initial', 'calc(100vh - 64px)']}>
      {order && (
        <Flex
          height={['initial', 'initial', '100%']}
          width={'100%'}
          maxWidth={'1024px'}
          flexDirection={['column', 'column', 'row']}>
          <Flex bg={'white'} flex={1} p={2} flexDirection={'column'}>
            <Text mb={2} mt={2} fontSize={5}>
              Varukorg
            </Text>
            {updateLoading ? (
              <Flex>
                <CircularProgress />
              </Flex>
            ) : (
              order.checkout.items.map((item, index) => {
                console.log(item)
                // ellos fix!
                if (
                  item.vendor &&
                  (item.vendor === 'ellos' ||
                    item.vendor === 'reforma' ||
                    item.vendor === 'jotext' ||
                    item.vendor === 'nordic-nest' ||
                    item.vendor === 'calixter')
                ) {
                  return (
                    <EllosItem
                      key={index}
                      item={item}
                      onClick={() => {
                        window.open(getAffiliateUrl(item.url), '_blank')
                      }}
                    />
                  )
                } else {
                  return (
                    <Item
                      key={index}
                      item={item}
                      onRemove={() => {
                        let products = order.reachProducts.filter((p) => {
                          if (
                            order.checkout.items.filter((i) => i.url === p.url)
                              .length &&
                            p.url !== item.url
                          ) {
                            return true
                          }
                          return false
                        })

                        console.log(products)

                        const productIds = products.map((p) => p.productId)
                        if (productIds && productIds.length > 0) {
                          setUpdateLoading(true)
                          createCheckout({
                            variables: {
                              orderId: match.params.orderId,
                              productIds: productIds
                            },
                            update: async (proxy, mutationResult) => {
                              console.log(mutationResult)
                              if (mutationResult.data.createCheckout.success) {
                                refetch()
                                setUpdateLoading(false)
                              } else {
                                alert('Kunde inte skapa')
                              }
                              setUpdateLoading(false)
                            }
                          })
                        }
                      }}
                    />
                  )
                }
              })
            )}
          </Flex>
          <Flex
            display={['flex', 'flex', 'none']}
            height={['100vh', '100vh', '100%']}>
            <Iframe
              url={order.checkout.url}
              width='100%'
              height={'100%'}
              frameBorder={0}
              display='initial'
              position='relative'
            />
          </Flex>

          <Flex
            display={['none', 'none', 'flex']}
            height={['100vh', '100vh', '100%']}>
            <Iframe
              url={order.checkout.url}
              width='450px'
              height={'100%'}
              frameBorder={0}
              display='initial'
              position='relative'
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default withRouter(CheckoutPage)
