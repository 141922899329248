import { Flex, ImageCover, Text } from '../../../components/primitives'
import React from 'react'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import IconButton from '@material-ui/core/IconButton'
import { ProductHolder } from '../../customerPage/components/ProductHolder'

export const ProductCard = ({
  onClick,
  quantity,
  title,
  stock,
  price,
  selected = false,
  imgSrc,
  showDelete,
  showAdd = true,
  deleteClick
}) => {
  return (
    <ProductHolder
      p={2}
      width={'140px'}
      height={'252px'}
      onClick={showDelete ? () => {} : onClick}
      showAdd={showAdd}
      selected={selected}>
      <>
        {showDelete && (
          <Flex style={{ position: 'absolute', top: 0, right: 0 }}>
            <IconButton aria-label='Delete' onClick={deleteClick}>
              <DeleteIcon />
            </IconButton>
          </Flex>
        )}
        <ImageCover height={'100px'} width={'120px'} src={imgSrc} />
        <Flex
          onClick={onClick}
          flex={1}
          flexDirection={'column'}
          alignItems={'center'}
          jusifyContent={'space-between'}>
          <Text mt={2} style={{ height: '60px', overflow: 'hidden' }}>
            {title}
          </Text>
          <Text fontWeight={500}>{stock}</Text>
          <Text fontWeight={500}>{quantity} st</Text>
          <Text fontWeight={500} mb={2}>
            {price} SEK
          </Text>
        </Flex>
      </>
    </ProductHolder>
  )
}
