import styled from 'styled-components'
import { Flex } from '../primitives'
import * as PropTypes from 'prop-types'

export const FlexWithHover = styled(Flex)`
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`

FlexWithHover.propTypes = {
  ...FlexWithHover.propTypes,
  hoverColor: PropTypes.string
}

FlexWithHover.defaultProps = {
  hoverColor: 'rgba(0, 0, 0, 0.08)'
}
