import React from 'react'

import { makeStyles } from '@material-ui/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'

import { getUserInitials } from '../modules/util'

const useStyles = makeStyles(theme => {
  return {
    menu: {
      maxHeight: 305
    },
    avatar: {
      height: 32,
      width: 32,
      fontSize: theme.typography.pxToRem(14)
    }
  }
})

const UserMenu = ({ users, onSelect, ...otherProps }) => {
  const classes = useStyles()
  const handleClose = (e, reason) => {
    e.stopPropagation()
    if (onSelect) {
      onSelect()
    }
  }
  const handleItemClick = (e, user) => {
    e.stopPropagation()
    if (onSelect) {
      onSelect(user)
    }
  }

  return (
    <Menu className={classes.menu} {...otherProps} onClose={handleClose}>
      {users && users.map(u =>
        <MenuItem key={'usermenu' + u._id} onClick={(e) => handleItemClick(e, u)}>
          <ListItemIcon>
            <Tooltip title={u.name}>
              <Avatar classes={{ root: classes.avatar }}>
                { getUserInitials(u) }
              </Avatar>
            </Tooltip>
          </ListItemIcon>
          <Typography variant='inherit'>{u.name || u.email}</Typography>
        </MenuItem>
      )}
    </Menu>
  )
}

export default UserMenu
