import styled from 'styled-components'
import { Flex } from '../../primitives'

export const FlexWithCorner = styled(Flex)`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }
  &:before {
    border-width: 1.5em;
    border-right-color: #ccc;
    border-top-color: #ccc;
  }
  &:after {
    border-width: 2em;
    border-bottom-right-radius: ${(props) => (props.selected ? '1px' : '2px')};
    border-right-color: ${(props) => (props.selected ? '#9a7652' : 'white')};
    border-bottom-color: ${(props) => (props.selected ? '#9a7652' : 'white')};
  }
`
