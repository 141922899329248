import React, { useContext, useEffect } from 'react'
import { Route, Switch, Redirect, Link, withRouter } from 'react-router-dom'

import OrderList from './components/OrderList'
import AssignList from './components/AssignList'
import AuthContext from '../../modules/AuthContext'
import { isAdmin, isMasterAdmin } from '../../modules/const'
import { withStyles } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Flex, Text } from '../../components/primitives'
import { SearchInput } from '../../components/sharable'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMore from '@material-ui/icons/ArrowDropDown'
import { useGetOrderCount } from '../../hooks/useGetOrdersCount'

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    backgroundColor: theme.palette.primary.dark
  }
}))(Tabs)

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: `1px solid ${theme.palette.primary.dark}`,
      opacity: 1
    },
    '&$selected': {
      color: theme.palette.primary.dark,
      fontWeight: 'bold'
    },
    '&:focus': {
      color: `1px solid ${theme.palette.primary.dark}`
    }
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />)

const getTabIndex = (route = '', admin) => {
  const paths = route.split('/')
  let matchedRoute
  if (paths.length > 2) {
    matchedRoute = paths[2]
  }

  if (admin) {
    if (matchedRoute === 'archived') return 4
    if (matchedRoute === 'completed') return 3
    if (matchedRoute === 'sent') return 2
    if (matchedRoute === 'current') return 1
    if (matchedRoute === 'incoming') return 0
    return 0
  } else {
    if (matchedRoute === 'completed') return 2
    if (matchedRoute === 'sent') return 1
    if (matchedRoute === 'current') return 0
    return 0
  }
}

const getMobileInitialTitle = (route = '') => {
  const paths = route.split('/')
  let matchedRoute
  if (paths.length > 2) {
    matchedRoute = paths[2]
  }

  if (matchedRoute === 'completed') return 'completed'
  if (matchedRoute === 'sent') return 'sent'
  if (matchedRoute === 'current') return 'current'
  if (matchedRoute === 'incoming') return 'incoming'
  if (matchedRoute === 'archived') return 'archived'
  return 'current'
}

const OrdersPage = (props) => {
  const counts = useGetOrderCount()
  const { currentUser } = useContext(AuthContext)
  const [admin, setAdmin] = React.useState(false)
  const [masterAdmin, setMasterAdmin] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [searchId, setSearchId] = React.useState('')

  const [mobileMenuTitle, setMobileMenuTitle] = React.useState(() =>
    getMobileInitialTitle(props.location.pathname)
  )

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose(title) {
    setAnchorEl(null)
    setMobileMenuTitle(title)
  }

  useEffect(() => {
    if (currentUser && isAdmin(currentUser['__typename'])) {
      setAdmin(true)
      if (isMasterAdmin(currentUser['__typename'])) {
        setMasterAdmin(true)
      }
      // setValue(1)
    }
  }, [currentUser])

  return (
    <Flex pr={[0, 2]} pl={[0, 2]} flexDirection={'column'}>
      <Flex bg={'white'} mt={2} mb={2} display={['none', 'none', 'flex']}>
        <AntTabs
          style={{ width: '100%' }}
          value={getTabIndex(props.location.pathname, admin)}
          onChange={() => {}}>
          {admin && (
            <AntTab
              label={`Incoming (${counts.createdCount})`}
              component={Link}
              to='/orders/incoming'
            />
          )}
          <AntTab
            label={`Current (${counts.assignedCount})`}
            component={Link}
            to='/orders/current'
          />
          <AntTab
            label={`Sent (${counts.sendCount})`}
            component={Link}
            to='/orders/sent'
          />
          <AntTab
            label={`Completed (${counts.completedCount})`}
            component={Link}
            to='/orders/completed'
          />
          <AntTab label={`Archived`} component={Link} to='/orders/archived' />
        </AntTabs>
      </Flex>
      <Flex display={['flex', 'flex', 'none']} jusifyContent={'center'}>
        <Flex mt={2} alignItems={'center'} onClick={handleClick}>
          <Text
            fontWeight={'bold'}
            fontSize={4}
            aria-controls='simple-menu'
            aria-haspopup='true'>
            {mobileMenuTitle}
          </Text>
          <ExpandMore />
        </Flex>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}>
          {admin && (
            <MenuItem
              onClick={() => {
                handleClose('Incoming')
                props.history.push('/orders/incoming')
              }}>
              Incoming ({counts.createdCount})
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleClose('Current')
              props.history.push('/orders/current')
            }}>
            Current ({counts.assignedCount})
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose('Sent')
              props.history.push('/orders/sent')
            }}>
            Sent ({counts.sendCount})
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose('Completed')
              props.history.push('/orders/completed')
            }}>
            Completed ({counts.completedCount})
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose('Archived')
              props.history.push('/orders/archived')
            }}>
            Archived
          </MenuItem>
        </Menu>
      </Flex>

      <SearchInput
        onSearch={(a) => {
          setSearchId(a)
        }}
      />
      <Switch>
        {admin && (
          <Route
            path='/orders/incoming'
            render={(props) => (
              <AssignList
                {...props}
                searchId={searchId}
                status={'created'}
                isMasterAdmin={masterAdmin}
              />
            )}
          />
        )}
        <Route
          path='/orders/current'
          render={(props) => (
            <OrderList
              {...props}
              searchId={searchId}
              status={'assigned'}
              isMasterAdmin={masterAdmin}
            />
          )}
        />
        <Route
          path='/orders/completed'
          render={(props) => (
            <OrderList
              {...props}
              searchId={searchId}
              status={'completed'}
              isMasterAdmin={masterAdmin}
            />
          )}
        />

        <Route
          path='/orders/sent'
          render={(props) => (
            <OrderList
              {...props}
              searchId={searchId}
              status={'sent'}
              isMasterAdmin={masterAdmin}
            />
          )}
        />

        {admin && (
          <Route
            path='/orders/archived'
            render={(props) => (
              <OrderList
                {...props}
                searchId={searchId}
                status={'archived'}
                isMasterAdmin={masterAdmin}
              />
            )}
          />
        )}

        <Redirect from='/' to='/orders/current' />
      </Switch>
    </Flex>
  )
}

export default withRouter(OrdersPage)
