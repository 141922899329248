import React, { useState } from 'react'
import { Flex, Text } from '../../components/primitives'
import { ButtonWithLoading } from '../../components/sharable/button'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Input } from '../../components/sharable'
import {
  ADD_INSTAGRAM_POST,
  INSTAGRAMS,
  DELETE_INSTAGRAM_POST
} from '../../gql/instagram'
import { ImageContain } from '../../components/primitives/ImageCover'

const InstagramPage = () => {
  const { refetch, data } = useQuery(INSTAGRAMS)
  const [addInstagramPost] = useMutation(ADD_INSTAGRAM_POST)
  const [deleteInstagramPost] = useMutation(DELETE_INSTAGRAM_POST)

  const [loading, setLoading] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)

  const [igPostId, setIgPostId] = useState(null)
  const [statusText, setStatusText] = useState('')

  return (
    <Flex
      p={2}
      flexDirection={'column'}
      jusifyContent={'center'}
      alignItems={'center'}
      width={'100%'}>
      <Text mt={4} mb={4} fontWeight={'bold'} fontSize={8}>
        Add Instagram posts
      </Text>

      <Text mb={2} textAlign={'center'}>
        Do add a post, find the id on a post at instagram.com. <br /> ex:
        https://www.instagram.com/p/<b>BHlxFMAgWTa</b>/ <br /> add only
        BHlxFMAgWTa
      </Text>
      <Text>{statusText}</Text>

      <Flex flexDirection={'column'}>
        <Text>Instagram id</Text>
        <Input
          onChange={(event) => {
            setIgPostId(event.target.value)
          }}
        />

        <ButtonWithLoading
          isLoading={loading}
          mt={2}
          onClick={async () => {
            setStatusText('')
            if (igPostId) {
              setLoading(true)
              addInstagramPost({
                variables: { igId: igPostId },
                update: (proxy, mutationResult) => {
                  if (!mutationResult.data.addInstagramPost.success) {
                    setStatusText('Could not add instagram post')
                  } else {
                    setStatusText('Added post!')
                    refetch()
                  }
                  setLoading(false)
                }
              })
            }
          }}>
          Add
        </ButtonWithLoading>
      </Flex>

      <Text mt={4} mb={4} fontWeight={'bold'} fontSize={8}>
        Instagram posts
      </Text>

      {data &&
        data.instagrams &&
        data.instagrams.map((post, index) => {
          return (
            <Flex
              key={index}
              maxWidth={'500px'}
              width={'100%'}
              jusifyContent={'space-between'}
              height={'60px'}
              p={2}
              alignItems={'center'}>
              <ImageContain
                height={'50px'}
                width={'50px'}
                src={'https://instagram.com/p/' + post.igId + '/media/?size=m'}
              />
              <Text>{post.igId}</Text>
              <ButtonWithLoading
                isLoading={removeLoading}
                onClick={async () => {
                  setRemoveLoading(true)
                  deleteInstagramPost({
                    variables: { id: post._id },
                    update: (proxy, mutationResult) => {
                      if (!mutationResult.data.deleteInstagramPost.success) {
                        alert('Could not remove instagram post')
                      } else {
                        refetch()
                      }
                      setRemoveLoading(false)
                    }
                  })
                }}>
                Remove
              </ButtonWithLoading>
            </Flex>
          )
        })}
    </Flex>
  )
}

export default InstagramPage
