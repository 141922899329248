import React, { useState, useContext } from 'react'
import { compose, branch, renderComponent } from 'recompose'
import request from 'superagent'
import { useHistory } from 'react-router-dom'

import { useQuery, useMutation, useSubscription } from '@apollo/react-hooks'
import { makeStyles, useTheme } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  ORDER,
  ADD_FILES_TO_ORDER,
  DELETE_FILE_FROM_ORDER,
  UPDATE_ORDER,
  ADD_MOODBOARD,
  ADD_FLOORPLANS,
  DELETE_FLOORPLAN,
  ADD_ORDER_COMMENT,
  SEND_ASK_FLOORPLAN,
  SEND_ORDER_PROPOSAL,
  COMMENTS_CHANGED,
  DELETE_MOODBOARD,
  SET_ORDER_COMPLETE,
  NOTIFY_USER_ON_COMPLETE_FILES_ON_COMMENT,
  GET_PREVIEW_URL,
  SEND_REQUEST_PICTURES
} from '../../gql/orders'
import AuthContext from '../../modules/AuthContext'
import { OrderTitle, PaperTitle } from './components'
import { Flex, Text, Button } from '../../components/primitives'
import { ButtonWithLoading } from '../../components/sharable'
import ErrorCard from '../../components/ErrorCard'
import Tag from '../../components/Tag'
import OrderFiles from './components/OrderFiles'
import CommittableTextField from '../../components/CommittableTextField'
import FileBoard from '../../components/sharable/FileBoard'
import OrderDiscussion from './components/OrderDiscussion'
import { MoodboardDialog } from './components/MoodboardDialog'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { uploadFiles, uploadFilesWithBase64 } from '../../modules/upload'
import { getDateString } from '../../modules/date'
import withMobileDialog from '@material-ui/core/withMobileDialog/withMobileDialog'
import { isAdmin, isMasterAdmin } from '../../modules/const'
import style1 from '../../assets/images/style_1.jpg'
import style2 from '../../assets/images/style_2.jpg'
import style3 from '../../assets/images/style_3.jpg'
import style4 from '../../assets/images/style_4.jpg'
import style5 from '../../assets/images/style_5.jpg'
import style6 from '../../assets/images/style_6.jpg'
import style7 from '../../assets/images/style_7.jpg'
import style8 from '../../assets/images/style_8.jpg'
import style9 from '../../assets/images/style_9.jpg'

import room1 from '../../assets/images/skandinavisk.jpg'
import room2 from '../../assets/images/modern.jpg'
import room3 from '../../assets/images/classic.jpg'

import { ImageContain } from '../../components/primitives/ImageCover'
import { ColorPicker } from './components/ColorPicker'
import OrderProducts from './components/OrderProducts'

const getWhatStyle = (style) => {
  if (style === '1') {
    return room1
  } else if (style === '2') {
    return room2
  } else if (style === '3') {
    return room3
  }
}
const getWhatAppealImgs = (whatAppeal) => {
  if (!whatAppeal) {
    return []
  }
  const srcs = []
  if (whatAppeal.includes('1')) {
    srcs.push(style1)
  }
  if (whatAppeal.includes('2')) {
    srcs.push(style2)
  }
  if (whatAppeal.includes('3')) {
    srcs.push(style3)
  }
  if (whatAppeal.includes('4')) {
    srcs.push(style4)
  }
  if (whatAppeal.includes('5')) {
    srcs.push(style5)
  }
  if (whatAppeal.includes('6')) {
    srcs.push(style6)
  }
  if (whatAppeal.includes('7')) {
    srcs.push(style7)
  }
  if (whatAppeal.includes('8')) {
    srcs.push(style8)
  }
  if (whatAppeal.includes('9')) {
    srcs.push(style9)
  }

  return srcs
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    idContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    status: {
      marginLeft: theme.spacing(1)
    },
    orderSection: {
      padding: theme.spacing(2, 2, 2, 2),
      marginTop: theme.spacing(1),
      boxShadow: 'none'
    },
    rooms: {
      display: 'flex'
    },
    roomLabel: {
      marginBottom: theme.spacing(1) / 2
    },
    errorSnackbarContent: {
      backgroundColor: theme.palette.error.dark
    },
    chip: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '5px',
      color: theme.palette.dark
    }
  }
})

function Order(props) {
  const { data, refetch } = props
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const { currentUser } = useContext(AuthContext)

  const statusColorMapping = {
    created: 'red',
    assigned: 'gold',
    started: theme.palette.primary.dark,
    sent: theme.palette.primary.light,
    completed: 'lightgreen',
    archived: 'grey'
  }

  const [submitting, setSubmitting] = useState(false)
  const [moodboardFileLoading, setMoodboardFileLoading] = useState(false)
  const [floorplanLoading, setFloorplanLoading] = useState(false)
  const [sendingProposal, setSendingProposal] = useState(false)
  const [getUrlLoading, setGetUrlLoading] = useState(false)
  const [openMoodboard, setOpenMoodboard] = useState(false)
  const [sendingCompleteLoading, setSendingCompleteLoading] = useState(false)
  const [commentFiles, setCommentFiles] = useState([])
  const [error, setError] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [askFloorplanLoading, setAskFloorplanLoading] = useState(false)
  const [requestPicturesLoading, setRequestPicturesLoading] = useState(false)

  const [addFiles] = useMutation(ADD_FILES_TO_ORDER)
  const [deleteFile] = useMutation(DELETE_FILE_FROM_ORDER)
  const [getPreviewUrl] = useMutation(GET_PREVIEW_URL)
  const [updateOrder] = useMutation(UPDATE_ORDER)
  const [addMoodboards] = useMutation(ADD_MOODBOARD)
  const [deleteMoodboard] = useMutation(DELETE_MOODBOARD)
  const [addFloorplans] = useMutation(ADD_FLOORPLANS)
  const [deleteFloorplan] = useMutation(DELETE_FLOORPLAN)
  //const [addProduct] = useMutation(ADD_PRODUCT_TO_ORDER)
  //const [removeProduct] = useMutation(REMOVE_PRODUCT_FROM_ORDER)
  const [addOrderComment] = useMutation(ADD_ORDER_COMMENT)
  const [sendOrderProposal] = useMutation(SEND_ORDER_PROPOSAL)
  const [sendAskFloorplan] = useMutation(SEND_ASK_FLOORPLAN)
  const [sendRequestPictures] = useMutation(SEND_REQUEST_PICTURES)
  const [setOrderComplete] = useMutation(SET_ORDER_COMPLETE)
  const [notifyUserOnCompleteFilesOnComment] = useMutation(
    NOTIFY_USER_ON_COMPLETE_FILES_ON_COMMENT
  )

  useSubscription(COMMENTS_CHANGED, {
    variables: { orderId: props.orderId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (
        subscriptionData &&
        subscriptionData.data &&
        subscriptionData.data.commentsChanged
      ) {
        const data = client.readQuery({
          query: ORDER,
          variables: { id: props.orderId }
        })
        data.order.comments = subscriptionData.data.commentsChanged
        client.writeQuery({
          query: ORDER,
          variables: { id: props.orderId },
          data
        })
      }
    },
    skip: props.loading
  })

  const uploadCommentFiles = ({ comment, fileMap, orderId }) => {
    setUploading(true)
    let ulPromises = []
    comment.files.forEach((f) => {
      const file = fileMap[f.origName]
      if (f.uploadUrl && file) {
        ulPromises.push(
          new Promise((resolve, reject) => {
            request
              .put(f.uploadUrl)
              .send(file)
              .retry()
              .on('progress', (event) => {
                console.log(`Uploading file ${f.origName}`, event)
              })
              .end((err, res) => {
                if (err) {
                  reject(err)
                } else {
                  resolve()
                }
              })
          })
        )
      }
    })
    Promise.all(ulPromises)
      .catch((err) => {
        setError('There was a problem uploading the files')
        console.log('Failed to upload files', err)
      })
      .finally(() => {
        setUploading(false)
        // setOpen(false)
        notifyUserOnCompleteFilesOnComment({ variables: { orderId: orderId } })
      })
  }

  const handleFilesAdded = (files) => {
    setSubmitting(true)
    addFiles({
      variables: {
        orderId: data.order._id,
        files: files.map((f) => ({ origName: f.name }))
      },
      update: (proxy, result) => {
        const orderFiles = result.data.addFilesToOrder.files
        uploadFiles(orderFiles.map((f, i) => ({ ...f, ref: files[i] }))).then(
          (result) => {
            setSubmitting(false)
            refetch()
          }
        )
      }
    })
  }

  const handleFileDelete = (fileId) => {
    setSubmitting(true)
    deleteFile({
      variables: {
        orderId: data.order._id,
        fileId
      },
      update: (proxy, result) => {
        setSubmitting(false)
        refetch()
      }
    })
  }

  const handleDescSave = (value) => {
    updateOrder({
      variables: {
        id: data.order._id,
        input: { desc: value }
      },
      update: (proxy, result) => {
        refetch()
      }
    })
  }

  const handleNotesSave = (value) => {
    updateOrder({
      variables: {
        id: data.order._id,
        input: { notes: value }
      },
      update: (proxy, result) => {
        refetch()
      }
    })
  }
  const handleAdminCommentsSave = (value) => {
    updateOrder({
      variables: {
        id: data.order._id,
        input: { adminComments: value }
      },
      update: (proxy, result) => {
        refetch()
      }
    })
  }

  const handleFloorplanFileAdded = (files) => {
    setFloorplanLoading(true)
    addFloorplans({
      variables: {
        orderId: data.order._id,
        floorplans: files.map((f) => ({ origName: f.name }))
      },
      update: (proxy, result) => {
        const floorplans = result.data.addFloorplans.floorplans
        uploadFiles(floorplans.map((f, i) => ({ ...f, ref: files[i] }))).then(
          (result) => {
            setFloorplanLoading(false)
            refetch()
          }
        )
      }
    })
  }

  const handleMoodboardFileAdded = (files) => {
    setMoodboardFileLoading(true)
    addMoodboards({
      variables: {
        orderId: data.order._id,
        moodboards: files.map((f) => ({ origName: f.name }))
      },
      update: (proxy, result) => {
        const moodboards = result.data.addMoodboards.moodboards
        uploadFiles(moodboards.map((f, i) => ({ ...f, ref: files[i] }))).then(
          (result) => {
            setMoodboardFileLoading(false)
            refetch()
          }
        )
      }
    })
  }

  const hadleMoodboardCreated = (base64) => {
    setMoodboardFileLoading(true)
    addMoodboards({
      variables: {
        orderId: data.order._id,
        moodboards: [{ origName: 'moodboard.png' }]
      },
      update: (proxy, result) => {
        const f = result.data.addMoodboards.moodboards[0]
        uploadFilesWithBase64([{ ...f, raw: base64 }]).then((result) => {
          setMoodboardFileLoading(false)
          refetch()
        })
      }
    })
  }

  const handleFloorplanFileDeleted = (fileId) => {
    deleteFloorplan({
      variables: {
        orderId: data.order._id,
        fileId: fileId
      },
      update: (proxy, result) => {
        refetch()
      }
    })
  }

  const handleMoodboardFileDeleted = (fileId) => {
    deleteMoodboard({
      variables: {
        orderId: data.order._id,
        fileId: fileId
      },
      update: (proxy, result) => {
        refetch()
      }
    })
  }

  /*
  const handleProductsAdded = (added) => {
    if (!added) {
      return
    }
    added = added.filter((e) => e.quantity > 0)
    added.forEach((e) => {
      addProduct({
        variables: {
          orderId: data.order._id,
          productId: e.id,
          count: e.quantity
        },
        update: (proxy, result) => {
          refetch()
        }
      })
    })
  } */

  const handleCompleteOrder = () => {
    setSendingCompleteLoading(true)
    setOrderComplete({
      variables: {
        orderId: data.order._id
      },
      update: (proxy, result) => {
        setSendingCompleteLoading(false)
        props.history.push('/orders/completed')
        window.location.reload()
      }
    })
  }

  const handleSendOrderProposal = () => {
    setSendingProposal(true)
    sendOrderProposal({
      variables: {
        orderId: data.order._id
      },
      update: (proxy, result) => {
        setSendingProposal(false)
      }
    })
  }

  const handleFilesAddedToComment = (files) => {
    const f = [...commentFiles, ...files]
    setCommentFiles(f)
  }

  const handleCommentAdded = (comment) => {
    if (!comment) {
      return
    }
    const files = []
    const fileMap = {}
    let dontPub = false
    if (commentFiles && commentFiles.length > 0) {
      for (let i = 0; i < commentFiles.length; i++) {
        files.push({ origName: commentFiles[i].name })
        fileMap[commentFiles[i].name] = commentFiles[i]
      }
      dontPub = true
    }

    addOrderComment({
      variables: {
        orderId: data.order._id,
        input: {
          comment,
          files: files,
          dontPub: dontPub
        }
      },
      update: async (proxy, result) => {
        const comment = result.data.addOrderComment.comment
        if (comment.files && comment.files.length > 0) {
          await uploadCommentFiles({
            comment,
            fileMap,
            orderId: data.order._id
          })
        }

        setCommentFiles([])
      }
    })
  }

  const handleSendFloorplanRequest = () => {
    setAskFloorplanLoading(true)
    sendAskFloorplan({
      variables: {
        orderId: data.order._id
      },
      update: (proxy, result) => {
        setAskFloorplanLoading(false)
      }
    })
  }
  const handleRequestPictureMutation = () => {
    setRequestPicturesLoading(true)
    sendRequestPictures({
      variables: {
        orderId: data.order._id
      },
      update: (proxy, result) => {
        setRequestPicturesLoading(false)
      }
    })
  }

  /*
  const handleProductRemoved = (id) => {
    if (!id) {
      return
    }
    removeProduct({
      variables: {
        orderId: data.order._id,
        productId: id
      },
      update: (proxy, result) => {
        refetch()
      }
    })
  }
 */
  const getNameHax = (productOption) => {
    if (productOption === 'concept') {
      return '3d Complete'
    }
    return productOption
  }

  return (
    data &&
    data.order && (
      <Flex mr={2} ml={2} mt={2} mb={2} flexDirection={'column'}>
        <MoodboardDialog
          open={openMoodboard}
          onCreate={hadleMoodboardCreated}
          onClose={() => {
            setOpenMoodboard(false)
          }}
        />
        <Snackbar
          open={error ? true : false}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <SnackbarContent
            message={<span>{error}</span>}
            className={classes.errorSnackbarContent}
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                className={classes.close}
                onClick={() => {
                  setError()
                }}>
                <CloseIcon />
              </IconButton>
            ]}
          />
        </Snackbar>
        <Flex
          jusifyContent={'space-between'}
          mb={2}
          flex={1}
          flexDirection={['column', 'column', 'row']}>
          <Flex
            className={classes.idContainer}
            alignItems={'center'}
            flexDirection={['column', 'column', 'row']}>
            <OrderTitle
              textAlign={'center'}
              fontSize={[4, 6]}
              title={data.order._id}
            />
            <Tag
              color={statusColorMapping[data.order.status]}
              className={classes.status}>
              {data.order.status}
            </Tag>
          </Flex>
          <Flex alignItems={'center'} mt={[2, 2, 0]} flexDirection={'column'}>
            <Text>
              Created:{getDateString(new Date(+data.order.createdAt))}
            </Text>
            <Text>
              Updated: {getDateString(new Date(+data.order.updatedAt))}
            </Text>
          </Flex>
        </Flex>

        <Paper className={classes.orderSection}>
          <Flex>
            {data.order && data.order.status !== 'completed' && (
              <ButtonWithLoading
                isLoading={sendingProposal}
                onClick={handleSendOrderProposal}
                color='primary'>
                Send Proposal
              </ButtonWithLoading>
            )}

            {data.order && data.order.status === 'sent' && (
              <ButtonWithLoading
                isLoading={sendingCompleteLoading}
                ml={2}
                onClick={handleCompleteOrder}
                color='primary'>
                Complete order
              </ButtonWithLoading>
            )}

            <ButtonWithLoading
              ml={2}
              isLoading={getUrlLoading}
              onClick={() => {
                setGetUrlLoading(true)
                getPreviewUrl({
                  variables: {
                    orderId: data.order._id
                  },
                  update: (proxy, result) => {
                    if (result.data.getPreviewUrl.success) {
                      window.open(result.data.getPreviewUrl.url, '_blank')
                    }
                    setGetUrlLoading(false)
                  }
                })
              }}
              color='primary'>
              Preview Proposal
            </ButtonWithLoading>

            {isMasterAdmin(currentUser['__typename']) && (
              <Button
                onClick={() => {
                  history.push('/editorder/' + data.order._id)
                }}
                ml={2}>
                Change customerdata
              </Button>
            )}
          </Flex>
        </Paper>

        <Paper className={classes.orderSection}>
          <Flex alignItems={'center'}>
            <Text fontWeight={'bold'} mr={2}>
              Measure the room
            </Text>
            <ButtonWithLoading
              isLoading={askFloorplanLoading}
              onClick={handleSendFloorplanRequest}>
              Send mail
            </ButtonWithLoading>
          </Flex>
        </Paper>
        <Paper className={classes.orderSection}>
          <Flex alignItems={'center'}>
            <Text fontWeight={'bold'} mr={2}>
              Request pictures
            </Text>
            <ButtonWithLoading
              isLoading={requestPicturesLoading}
              onClick={handleRequestPictureMutation}>
              Send mail
            </ButtonWithLoading>
          </Flex>
        </Paper>

        {data.order.customerOrder && (
          <Paper className={classes.orderSection}>
            <PaperTitle
              title={
                data.order.customerOrder.productOption
                  ? 'CUSTOMER ORDER - ' +
                    getNameHax(data.order.customerOrder.productOption)
                  : 'CUSTOMER ORDER'
              }
              mb={2}
            />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  label='Name'
                  value={
                    data.order.customerOrder.name
                      ? data.order.customerOrder.name
                      : 'no name'
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  label='Email'
                  value={
                    data.order.customerOrder.email
                      ? data.order.customerOrder.email
                      : 'no email'
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  fullWidth
                  label='Phone'
                  value={
                    data.order.customerOrder.phone
                      ? data.order.customerOrder.phone
                      : 'No phone'
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  fullWidth
                  label='Instagram'
                  value={
                    data.order.customerOrder.instagram
                      ? data.order.customerOrder.instagram
                      : 'No instagram'
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} md={12}>
                <TextField
                  fullWidth
                  label='Pinterest'
                  value={
                    data.order.customerOrder.pinterest
                      ? data.order.customerOrder.pinterest
                      : 'No pinterest'
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                />
              </Grid>

              <Grid item style={{ marginTop: '10px' }} xs={12} />

              <Grid item xs={6} sm={4} md={3} container direction='column'>
                <Grid item>
                  <InputLabel shrink className={classes.roomLabel}>
                    Rooms
                  </InputLabel>
                </Grid>
                <Grid item container spacing={1} justify={'flex-start'}>
                  {data.order.customerOrder.rooms &&
                    data.order.customerOrder.rooms.map((r) => (
                      <Grid key={`order-room-${r}`} xs={'auto'} item>
                        <Chip label={r} className={classes.chip} />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <TextField
                  fullWidth
                  label={'Budget'}
                  value={data.order.customerOrder.budget}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={4} md={3}>
                <TextField
                  label={'Homevisit'}
                  fullWidth
                  value={data.order.customerOrder.homeVisit ? 'Yes' : 'No'}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={4} md={3}>
                <Grid container direction='column' className={classes.rooms}>
                  <InputLabel shrink className={classes.roomLabel}>
                    Files
                  </InputLabel>
                  <Grid container spacing={1} justify={'flex-start'}>
                    {data.order.customerOrder.files &&
                      data.order.customerOrder.files.map((f, i) => (
                        <Grid
                          style={{ overflow: 'hidden' }}
                          key={`order-link-${i}`}
                          item>
                          <Link href={f.url}>{f.name}</Link>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  fullWidth
                  label='Domain'
                  value={
                    data.order.customerOrder.domain
                      ? data.order.customerOrder.domain
                      : ''
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <TextField
                  fullWidth
                  label='Discount'
                  value={
                    data.order.customerOrder.tags
                      ? data.order.customerOrder.tags.toString()
                      : ''
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label='Klarna id'
                  value={data.order.customerOrder.klarnaId}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item style={{ marginTop: '10px' }} xs={12} />

              <Grid item xs={12} sm={12}>
                <Text>Which pictures appeal to customer the most ?</Text>
                <Flex>
                  {getWhatAppealImgs(data.order.customerOrder.whatAppeal).map(
                    (src, index) => {
                      return (
                        <ImageContain
                          key={index}
                          m={2}
                          src={src}
                          height={'200px'}
                          width={'200px'}
                        />
                      )
                    }
                  )}
                </Flex>
              </Grid>

              {data.order &&
                data.order.customerOrder &&
                data.order.customerOrder.whatStyle && (
                  <Grid item xs={12} sm={12}>
                    <Text>Which style suits customer the best?</Text>
                    <Flex>
                      <ImageContain
                        m={2}
                        src={getWhatStyle(data.order.customerOrder.whatStyle)}
                        height={'200px'}
                        width={'200px'}
                      />
                    </Flex>
                  </Grid>
                )}

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  style={{ width: '100%' }}
                  label='Customer message'
                  multiline
                  rows='10'
                  value={
                    data.order.customerOrder.comment
                      ? data.order.customerOrder.comment
                      : ''
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        )}

        <Paper className={classes.orderSection}>
          <PaperTitle title={'HEMSET COMMENT'} />
          {currentUser && isAdmin(currentUser['__typename']) ? (
            <CommittableTextField
              value={data.order.adminComments}
              placeholder='Enter a comment to the decorator'
              margin='normal'
              fullWidth
              multiline
              onSave={handleAdminCommentsSave}
            />
          ) : (
            <Flex mt={2}>
              <Text>{data.order.adminComments}</Text>
            </Flex>
          )}
        </Paper>
        <Paper className={classes.orderSection}>
          <PaperTitle title={'PERSONAL NOTES'} />
          <CommittableTextField
            value={data.order.notes}
            placeholder='Enter notes about the order here, the customer wont se them'
            margin='normal'
            fullWidth
            multiline
            onSave={handleNotesSave}
          />
        </Paper>
        <Paper className={classes.orderSection}>
          <OrderFiles
            files={data.order && data.order.files}
            working={submitting}
            onAddFiles={handleFilesAdded}
            onFileDelete={handleFileDelete}
          />
        </Paper>

        <Flex width={'100%'} bg={'black'} mt={6} height={'3px'}></Flex>

        <Text fontWeight={'bold'} color={'dark'} fontSize={6} mt={5}>
          CUSTOMER CONCEPT
        </Text>

        <Paper className={classes.orderSection}>
          <PaperTitle title={'DESCRIPTION'} />
          <CommittableTextField
            value={data.order.desc}
            placeholder='Enter description for the customer here '
            margin='normal'
            fullWidth
            multiline
            onSave={handleDescSave}
          />
        </Paper>

        <Paper className={classes.orderSection}>
          <FileBoard
            loading={moodboardFileLoading}
            moodboards={data && data.order && data.order.moodboards}
            onAddFile={handleMoodboardFileAdded}
            onDeleteFile={handleMoodboardFileDeleted}
          />
          <Button
            mt={2}
            onClick={() => {
              setOpenMoodboard(true)
            }}>
            Create moodboard
          </Button>
        </Paper>

        <Paper className={classes.orderSection}>
          <FileBoard
            title={'VISUALISATION'}
            loading={floorplanLoading}
            moodboards={data && data.order && data.order.floorplans}
            onAddFile={handleFloorplanFileAdded}
            onDeleteFile={handleFloorplanFileDeleted}
          />
        </Paper>

        <Paper className={classes.orderSection}>
          {data && (
            <ColorPicker
              orderId={data.order._id}
              initialColors={data.order.wallColors}
            />
          )}
        </Paper>

        <Paper className={classes.orderSection}>
          <OrderProducts
            canEdit
            onDelete={() => {
              refetch()
            }}
            onAdded={() => {
              refetch()
            }}
            products={data && data.order && data.order.manualProdcuts}
            order={data && data.order}
          />
        </Paper>

        <Paper className={classes.orderSection}>
          <OrderDiscussion
            isOpen={
              data.order &&
              (data.order.status === 'completed' ||
                data.order.status === 'archived')
                ? false
                : true
            }
            comments={(data.order && data.order.comments) || []}
            currentUser={currentUser}
            uploading={uploading}
            filesToAd={commentFiles}
            onFilesChanged={handleFilesAddedToComment}
            onCommentAdded={handleCommentAdded}
          />
        </Paper>
      </Flex>
    )
  )
}

const withRequest = (BaseComponent) => (props) => {
  const { data, error, loading, refetch } = useQuery(ORDER, {
    variables: { id: props.orderId },
    fetchPolicy: 'network-only'
  })

  return (
    <BaseComponent
      {...props}
      data={data ? data : {}}
      loading={loading}
      error={error}
      refetch={refetch}
    />
  )
}

const withError = branch(
  ({ error }) => error,
  renderComponent(() => {
    return <ErrorCard message={'Error Loading Order'} />
  })
)

const withLoading = branch(
  ({ loading }) => loading,
  renderComponent(() => {
    const classes = useStyles()
    return (
      <Flex width={'100%'} jusifyContent={'center'} p={4}>
        <CircularProgress className={classes.loadingProgress} />
      </Flex>
    )
  })
)

export default compose(
  withRequest,
  withLoading,
  withError,
  withMobileDialog()
)(Order)
