import React, { useState } from 'react'
import { compose, branch, renderComponent } from 'recompose'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {
  ACCEPT_DECORATOR,
  DECLINE_DECORATOR,
  DECORATOR_APPLICATIONS
} from '../../gql/decorators'
import ErrorCard from '../ErrorCard'
import { Flex, Text } from '../primitives'
import { ButtonWithLoading } from '../sharable/button'
import { isImage } from '../../modules/util'
import { ScondaryButtonWithLoading } from '../sharable/button/ScondaryButtonWithLoading'

const useStyles = makeStyles(() => {
  return {
    loadingProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    noData: {
      textAlign: 'center'
    }
  }
})

const DecoratorApplications = (props) => {
  const [loading, setLoading] = useState(false)
  const { data, refetch } = props
  const [doAcceptDecorator] = useMutation(ACCEPT_DECORATOR)
  const [doDeclineDecorator] = useMutation(DECLINE_DECORATOR)

  return (
    <Flex flexDirection={'column'}>
      {data &&
        data.decoratorApplications &&
        data.decoratorApplications.map((user, index) => (
          <Flex
            key={index}
            flexDirection={'column'}
            bg={'white'}
            mb={2}
            width={'100%'}
            p={2}>
            <Flex flexDirection={['column', 'row']} alignItems={'center'}>
              <Flex flexDirection={'column'} flex={1}>
                <Text
                  fontSize={3}
                  color={'primary'}
                  textAlign={['center', 'left']}
                  fontWeight={'bold'}>
                  {user.name ? user.name : 'No name'}
                </Text>
                <Text textAlign={['center', 'left']}>
                  {user.email ? user.email : 'No email'}
                </Text>
                <Text textAlign={['center', 'left']}>
                  {user.phone ? user.phone : 'No phone'}
                </Text>
              </Flex>
              <Flex flexDirection={'column'} flex={2}>
                <Text textAlign={['center', 'left']}>
                  <b>Years of experience:</b>{' '}
                  {user.yearsOfExp ? user.yearsOfExp : 'No years of experience'}
                </Text>
                <Text textAlign={['center', 'left']}>
                  <b>Education:</b>{' '}
                  {user.education ? user.education : 'No education'}
                </Text>
                <Text textAlign={['center', 'left']}>
                  <b>Website:</b>{' '}
                  {user.website ? (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={user.website}>
                      {user.website}
                    </a>
                  ) : (
                    'No website'
                  )}
                </Text>
              </Flex>

              <Flex ml={4} style={{ justifySelf: 'flex-end' }}>
                <ButtonWithLoading
                  isLoading={loading}
                  mr={2}
                  onClick={() => {
                    setLoading(true)
                    doAcceptDecorator({
                      variables: {
                        id: user._id
                      },
                      update: (proxy, mutationResult) => {
                        setLoading(false)
                        if (
                          mutationResult.data.acceptDecoratorApplication.success
                        ) {
                          refetch()
                        } else {
                          alert(
                            mutationResult.data.acceptDecoratorApplication
                              .message
                          )
                        }
                      }
                    })
                  }}>
                  Acceptera
                </ButtonWithLoading>
                <ScondaryButtonWithLoading
                  onClick={() => {
                    setLoading(true)
                    doDeclineDecorator({
                      variables: {
                        id: user._id
                      },
                      update: (proxy, mutationResult) => {
                        setLoading(false)
                        if (
                          mutationResult.data.declineDecoratorApplication
                            .success
                        ) {
                          refetch()
                        } else {
                          alert(
                            mutationResult.data.declineDecoratorApplication
                              .message
                          )
                        }
                      }
                    })
                  }}>
                  {' '}
                  Decline
                </ScondaryButtonWithLoading>
              </Flex>
            </Flex>

            <Text mt={2} mb={2}>
              Files
            </Text>
            {user &&
              user.files &&
              user.files.map((file) => {
                if (isImage(file.name)) {
                  return (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ marginBottom: '5px' }}
                      href={file.url}
                      key={file.url}>
                      <img
                        style={{
                          height: '100px',
                          width: '100px',
                          objectFit: 'contain'
                        }}
                        src={file.url}
                        alt=''
                      />
                    </a>
                  )
                } else {
                  return (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ marginBottom: '5px' }}
                      href={file.url}
                      key={file.url}>
                      {file.name ? file.name : 'Link'}
                    </a>
                  )
                }
              })}
          </Flex>
        ))}
    </Flex>
  )
}

const withRequest = (BaseComponent) => (props) => {
  const { data, error, loading, refetch } = useQuery(DECORATOR_APPLICATIONS)
  return (
    <BaseComponent
      {...props}
      data={data}
      loading={loading}
      error={error}
      refetch={refetch}
    />
  )
}

const withError = branch(
  ({ error }) => error,
  renderComponent(() => {
    return (
      <ErrorCard
        message={
          'Error Loading Decorator list. You need Admin privilage to view this'
        }
      />
    )
  })
)

const withLoading = branch(
  ({ loading }) => loading,
  renderComponent(() => {
    return null
  })
)

const withEmptyData = branch(
  ({ data }) =>
    data.decoratorApplications && data.decoratorApplications.length === 0,
  renderComponent(() => {
    const classes = useStyles()
    return (
      <Box className={classes.noData}>
        No new Decorator Applications available
      </Box>
    )
  })
)

export default compose(
  withRequest,
  withLoading,
  withEmptyData,
  withError
)(DecoratorApplications)
