import axios from 'axios'

export const getMeta = async (url) => {
  try {
    const response = await axios({
      method: 'get',
      url: `https://metadataparser-ms5ov3nyja-ew.a.run.app/parse?url=${url}`
    })
    console.log(response)
    return response
  } catch (err) {
    console.log(err)
    alert('Could not parse url')
  }
}
