import Alert from 'react-s-alert'

export const showAlert = ({ message }) => {
  Alert.success(message, {
    position: 'top-right',
    effect: 'slide',
    timeout: 10000
  })
}

export const showError = ({ message }) => {
  Alert.error(message, {
    position: 'top-right',
    effect: 'slide',
    timeout: 10000
  })
}
