import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_INVOICES, SET_INVOICE_TO_PAID } from '../../gql/invoices'
import { Flex, Text, Button } from '../../components/primitives'
import {
  Holder,
  PaperTitle,
  ButtonWithLoading
} from '../../components/sharable'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getDateString } from '../../modules/util'
const AdminInvoicesPage = (props) => {
  const [invoiceToPaidLoading, setInvoiceToPaidLoading] = useState(false)

  const { loading: loadingInvoices, data, refetch: refetchInvoices } = useQuery(
    ADMIN_INVOICES,
    {}
  )

  const [setInvoiceToPaid] = useMutation(SET_INVOICE_TO_PAID)

  const InvoicePaid = (id) => {
    setInvoiceToPaidLoading(true)
    setInvoiceToPaid({
      variables: {
        invoiceId: id
      },
      update: (proxy, result) => {
        console.log(result)
        setInvoiceToPaidLoading(false)
        refetchInvoices()
      }
    })
  }

  return (
    <Flex pr={[0, 2]} pl={[0, 2]}>
      <Holder>
        <PaperTitle title={'Invoices'} />
        {loadingInvoices ? (
          <Flex mt={2}>
            <CircularProgress />
          </Flex>
        ) : (
          data.adminInvoices.map((invoice) => {
            return (
              <Flex
                key={invoice._id}
                mt={2}
                mb={2}
                height={'50px'}
                alignItems={'center'}>
                <Text ml={2} mr={4}>
                  {invoice._id.slice(-5)}
                </Text>
                <Text> {getDateString(new Date(+invoice.createdAt))}</Text>

                <Flex flex={1} jusifyContent={'flex-end'}>
                  <Button
                    mr={2}
                    onClick={() => {
                      const win = window.open(invoice.url, '_blank')
                      win.focus()
                    }}>
                    Open
                  </Button>
                  {!invoice.paid && (
                    <ButtonWithLoading
                      onClick={() => {
                        InvoicePaid(invoice._id)
                      }}
                      isLoading={invoiceToPaidLoading}
                      mr={2}>
                      Paid
                    </ButtonWithLoading>
                  )}
                </Flex>
              </Flex>
            )
          })
        )}
      </Holder>
    </Flex>
  )
}

export default AdminInvoicesPage
