import React, { useContext } from 'react'
import { Button, Flex } from '../../components/primitives'

import AuthContext from '../../modules/AuthContext'
import UpdateUserForm from './UpdateUserFrom'
import { isDecorator } from '../../modules/const'
import ChangePasswordForm from './ChangePasswordForm'

const ProfilePage = () => {
  const { currentUser, logout } = useContext(AuthContext)

  return (
    <Flex
      p={2}
      flexDirection={'column'}
      jusifyContent={'center'}
      alignItems={'center'}
      width={'100%'}>
      <Flex>
        {currentUser && isDecorator(currentUser['__typename']) && (
          <UpdateUserForm
            userId={currentUser._id}
            initialValues={{
              email: currentUser.email,
              name: currentUser.name,
              phone: currentUser.phone,
              address: currentUser.address,
              vatNumber: currentUser.vatNumber,
              bankNumber: currentUser.bankNumber
            }}
          />
        )}
      </Flex>

      <Flex mt={2}>
        {currentUser && (
          <ChangePasswordForm userId={currentUser._id} initialValues={{}} />
        )}
      </Flex>
      <Flex>
        <Button mt={4} variant='outlined' onClick={logout}>
          LOG OUT
        </Button>
      </Flex>
    </Flex>
  )
}

export default ProfilePage
