import React, { useState, useContext } from 'react'
import { compose, branch, renderComponent } from 'recompose'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'

import CreateAdminForm from '../../components/users/CreateAdminForm'
import EditAdminForm from '../../components/users/EditAdminForm'
import DeleteItemDialog from '../../components/dialogs/DeleteItemDialog'
import CreateItemDialog from '../../components/dialogs/CreateItemDialog'
import EditItemDialog from '../../components/dialogs/EditItemDialog'
import ErrorCard from '../../components/ErrorCard'
import { UserList } from './components/Userlist'
import {
  CREATE_ADMIN,
  LIST_USERS,
  UPDATE_ADMIN,
  MAKE_ADMIN_MASTER,
  DELETE_USER
} from '../../gql/auth'
import AuthContext from '../../modules/AuthContext'
import { isMasterAdmin } from '../../modules/const'

const queryParams = {
  variables: {
    types: ['MasterAdmin', 'Admin']
  },
  fetchPolicy: 'no-cache'
}

const useStyles = makeStyles((theme) => {
  return {
    addAdminButton: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
    loadingProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    }
  }
})

const AdminPage = function(props) {
  const [createAdmin] = useMutation(CREATE_ADMIN)
  const [updateAdmin] = useMutation(UPDATE_ADMIN)
  const [deleteAdmin] = useMutation(DELETE_USER)
  const [makeAdminMaster] = useMutation(MAKE_ADMIN_MASTER)

  const { currentUser } = useContext(AuthContext)

  const [createAdminSubmitting, setCreateAdminSubmitting] = useState(false)
  const [updateAdminSubmitting, setUpdateAdminSubmitting] = useState(false)
  const [deleteAdminSubmitting, setDeleteAdminSubmitting] = useState(false)

  const [openCreateAdminErrorSnack, setCreateAdminErrorSnack] = useState(false)
  const [openUpdateAdminErrorSnack, setUpdateAdminErrorSnack] = useState(false)
  const [openDeleteAdminErrorSnack, setDeleteAdminErrorSnack] = useState(false)

  const [openCreateAdmin, setOpenCreateAdmin] = useState(false)
  const [openEditAdmin, setOpenEditAdmin] = useState(false)
  const [openDeleteAdmin, setOpenDeleteAdmin] = useState(false)
  const [openMakeMasterAdmin, setOpenMakeMasterAdmin] = useState(false)

  const [editingUser, setCurrentUser] = useState({})

  const { data, refetch } = props

  const classes = useStyles()

  const { fullScreen } = props

  const handleCreateAdminErrorSnackClose = () => setCreateAdminErrorSnack(false)
  const handleUpdateAdminErrorSnackClose = () => setUpdateAdminErrorSnack(false)
  const handleDeleteAdminErrorSnackClose = () => setDeleteAdminErrorSnack(false)

  const handleCreateAdmin = (data) => {
    setCreateAdminSubmitting(true)
    createAdmin({
      variables: { input: { ...data } },
      update: (proxy, mutationResult) => {
        setCreateAdminSubmitting(false)
        if (!mutationResult.data.createAdmin.success) {
          setCreateAdminErrorSnack(true)
        } else {
          refetch(queryParams)
          setOpenCreateAdmin(false)
        }
      }
    })
  }

  const handleUpdateAdmin = (data) => {
    setUpdateAdminSubmitting(true)
    updateAdmin({
      variables: { id: editingUser._id, input: { password: data.password } },
      update: (proxy, mutationResult) => {
        setUpdateAdminSubmitting(false)
        if (!mutationResult.data.updateAdmin.success) {
          setUpdateAdminErrorSnack(true)
        } else {
          refetch(queryParams)
          setOpenEditAdmin(false)
        }
      }
    })
  }

  const handleDeleteAdmin = () => {
    setDeleteAdminSubmitting(true)
    deleteAdmin({
      variables: { id: editingUser._id },
      update: (proxy, mutationResult) => {
        setDeleteAdminSubmitting(false)
        if (!mutationResult.data.deleteUser.success) {
          setDeleteAdminErrorSnack(true)
        } else {
          refetch(queryParams)
          setOpenDeleteAdmin(false)
        }
      }
    })
  }

  const handleOpenCreateAdmin = () => {
    setOpenCreateAdmin(true)
  }

  const handleCloseCreateAdmin = () => {
    setOpenCreateAdmin(false)
  }

  const handleCloseEditAdmin = () => {
    setOpenEditAdmin(false)
  }

  const handleCloseDeleteAdmin = () => {
    setOpenDeleteAdmin(false)
  }

  const handleOpenEditAdmin = (user) => {
    setOpenEditAdmin(true)
    setCurrentUser(user)
  }

  const handleOpenDeleteAdmin = (user) => {
    setOpenDeleteAdmin(true)
    setCurrentUser(user)
  }

  const renderEditAdminModal = () => {
    return (
      <EditItemDialog
        fullScreen={fullScreen}
        onClose={handleCloseEditAdmin}
        disableSubmit={updateAdminSubmitting}
        title='Edit Admin'
        formId='edit-admin-form'
        submitText='Update'
        contentText='Copy the password before closing the dialog'>
        <EditAdminForm
          onEdit={handleUpdateAdmin}
          showErrorSnack={openUpdateAdminErrorSnack}
          onErrorSnackClose={handleUpdateAdminErrorSnackClose}
        />
      </EditItemDialog>
    )
  }

  const renderCreateAdminForm = () => {
    return (
      <CreateItemDialog
        fullScreen={fullScreen}
        onClose={handleCloseCreateAdmin}
        disableSubmit={createAdminSubmitting}
        title='Create Admin'
        formId='create-admin-form'
        submitText='Create'>
        <CreateAdminForm
          onLogin={handleCreateAdmin}
          showErrorSnack={openCreateAdminErrorSnack}
          onErrorSnackClose={handleCreateAdminErrorSnackClose}
        />
      </CreateItemDialog>
    )
  }

  return (
    <Box>
      <UserList
        isMasterAdmin={isMasterAdmin(currentUser['__typename'])}
        onMasterAdmin={(user) => {
          setOpenMakeMasterAdmin(true)
          setCurrentUser(user)
        }}
        users={data && data.users ? data.users : []}
        onDelete={handleOpenDeleteAdmin}
        onEdit={handleOpenEditAdmin}
      />

      {openEditAdmin && renderEditAdminModal()}
      {openDeleteAdmin && (
        <DeleteItemDialog
          fullScreen={fullScreen}
          onClose={handleCloseDeleteAdmin}
          onSubmit={handleDeleteAdmin}
          disableSubmit={deleteAdminSubmitting}
          showSnackbar={openDeleteAdminErrorSnack}
          onSnackbarClose={handleDeleteAdminErrorSnackClose}
          title=' Delete Admin'
          text='Are you sure you want to delete this admin ?'
        />
      )}
      {openCreateAdmin && renderCreateAdminForm()}

      {openMakeMasterAdmin && (
        <DeleteItemDialog
          actionTitle={'Yes'}
          fullScreen={fullScreen}
          onClose={() => {
            setOpenMakeMasterAdmin(false)
          }}
          onSubmit={() => {
            makeAdminMaster({
              variables: { id: editingUser._id },
              update: (proxy, mutationResult) => {
                if (!mutationResult.data.makeAdminMaster.success) {
                  setOpenMakeMasterAdmin(false)
                  alert('Something went wrong')
                } else {
                  refetch(queryParams)
                  setOpenMakeMasterAdmin(false)
                }
              }
            })
          }}
          title='Master Admin'
          text='Are you sure you want to make this admin to master admin ?'
        />
      )}

      <Fab
        color='primary'
        aria-label='Create Admin'
        disabled={currentUser && !isMasterAdmin(currentUser['__typename'])}
        className={classes.addAdminButton}
        onClick={handleOpenCreateAdmin}>
        <AddIcon />
      </Fab>
    </Box>
  )
}

const withRequest = (BaseComponent) => (props) => {
  const { data, error, loading, refetch } = useQuery(LIST_USERS, queryParams)
  return (
    <BaseComponent
      {...props}
      data={data}
      loading={loading}
      error={error}
      refetch={refetch}
    />
  )
}

const withError = branch(
  ({ error }) => error,
  renderComponent(() => {
    return (
      <ErrorCard
        message={
          'Error Loading Admin list. You need Admin privilage to view this'
        }
      />
    )
  })
)

const withLoading = branch(
  ({ loading }) => loading,
  renderComponent(() => {
    const classes = useStyles()
    return <CircularProgress className={classes.loadingProgress} />
  })
)

export default compose(
  withRequest,
  withLoading,
  withError,
  withMobileDialog()
)(AdminPage)
