import React from 'react'
import { Flex, Text } from '../../../components/primitives'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'
import { TextWithClick } from '../../../components/sharable/text/TextWithClick'

export const UserList = ({
  users,
  onDelete,
  onEdit,
  isDecorator,
  isCustomer,
  isMasterAdmin,
  onMasterAdmin
}) => {
  return (
    <Flex width={'100%'} flexDirection={'column'}>
      {users.map((user) => {
        console.log(user)
        return (
          <Flex
            key={user._id}
            mb={2}
            width={'100%'}
            height={['initial', '100px']}
            bg={'white'}
            p={2}
            flexDirection={['column', 'row']}
            jusifyContent={'space-between'}
            alignItems={'center'}>
            <Flex flexDirection={'column'} width={['initial', '350px']}>
              <Text
                fontSize={3}
                color={'primary'}
                textAlign={['center', 'left']}
                fontWeight={'bold'}>
                {user.name ? user.name : 'No name'}
              </Text>
              <Text textAlign={['center', 'left']}>
                {user.email ? user.email : 'No email'}
              </Text>
              <Text textAlign={['center', 'left']}>
                {user.phone ? user.phone : 'No phone'}
              </Text>
            </Flex>

            {isDecorator && (
              <Flex flexDirection={'column'} width={['initial', '150px']}>
                <Text>Bank: {user.bankNumber}</Text>
              </Flex>
            )}
            {isDecorator && (
              <Flex flexDirection={'column'} width={['initial', '150px']}>
                <Text>VAT: {user.vatNumber}</Text>
              </Flex>
            )}

            {isMasterAdmin && (
              <Flex alignItems={'center'}>
                {isMasterAdmin && user.__typename !== 'MasterAdmin' &&  user.__typename !== 'Decorator' && (
                  <TextWithClick
                    onClick={() => {
                      onMasterAdmin(user)
                    }}>
                    make master admin
                  </TextWithClick>
                )}
                <IconButton aria-label='Delete' onClick={() => onDelete(user)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton aria-label='Edit' onClick={() => onEdit(user)}>
                  <EditIcon />
                </IconButton>
              </Flex>
            )}
          </Flex>
        )
      })}
    </Flex>
  )
}
