export const moodboardOne = [
  {
    x: 0,
    y: 0,
    width: 0.175,
    height: 0.25,
    position: 1
  },
  {
    x: 0.175,
    y: 0,
    width: 0.175,
    height: 0.2,
    position: 2
  },
  {
    x: 0.35,
    y: 0,
    width: 0.275,
    height: 0.25,
    position: 3
  },
  {
    x: 0.625,
    y: 0,
    width: 0.375,
    height: 0.25,
    position: 4
  },
  {
    x: 0,
    y: 0.25,
    width: 0.175,
    height: 0.25,
    position: 5
  },
  {
    x: 0.175,
    y: 0.2,
    width: 0.175,
    height: 0.3,
    position: 6
  },
  {
    x: 0.35,
    y: 0.25,
    width: 0.2,
    height: 0.25,
    position: 7
  },
  {
    x: 0.55,
    y: 0.25,
    width: 0.2,
    height: 0.25,
    position: 8
  },
  {
    x: 0.75,
    y: 0.25,
    width: 0.25,
    height: 0.25,
    position: 9
  },
  {
    x: 0,
    y: 0.5,
    width: 0.175,
    height: 0.25,
    position: 10
  },
  {
    x: 0.175,
    y: 0.5,
    width: 0.175,
    height: 0.25,
    position: 11
  },
  {
    x: 0.35,
    y: 0.5,
    width: 0.25,
    height: 0.25,
    position: 12
  },
  {
    x: 0.6,
    y: 0.5,
    width: 0.15,
    height: 0.25,
    position: 13
  },
  {
    x: 0.75,
    y: 0.5,
    width: 0.25,
    height: 0.25,
    position: 14
  },
  {
    x: 0,
    y: 0.75,
    width: 0.35,
    height: 0.25,
    position: 15
  },
  {
    x: 0.35,
    y: 0.75,
    width: 0.275,
    height: 0.25,
    position: 16
  },
  {
    x: 0.625,
    y: 0.75,
    width: 0.1875,
    height: 0.25,
    position: 17
  },
  {
    x: 0.8125,
    y: 0.75,
    width: 0.1875,
    height: 0.25,
    position: 17
  }
]
