import React from 'react'
import { Flex, Text } from '../../../components/primitives'
import { OrderIdTitle } from '../../../components/sharable'
export const OrderTitle = ({ title, ...props }) => {
  return (
    <Flex>
      <Text mr={1} fontWeight={'bold'} color={'dark'} fontSize={6} {...props}>
        ORDER
      </Text>
      <OrderIdTitle
        fontWeight={'bold'}
        color={'dark'}
        title={title}
        {...props}></OrderIdTitle>
    </Flex>
  )
}
