import React, { useState } from 'react'

import Dropdown from 'react-dropdown'
import './react-dropdown.css'

export const HookDropdown = ({ firstValue, options, onSelect }) => {
  const [value, setValue] = useState(firstValue)

  return (
    <Dropdown
      style={{ borderRadius: '5px', backgroundColor: 'black' }}
      options={options}
      onChange={(val) => {
        setValue(val)
        console.log(val)
        onSelect(val.value)
      }}
      value={value}
      placeholder=''
    />
  )
}
