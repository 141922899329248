import React, { useState } from 'react'
import { Flex, Text } from '../../../components/primitives'
import { FlexWithHover, OrderIdTitle } from '../../../components/sharable'
import { getDateString } from '../../../modules/date'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ASSIGN_DECORATOR, ARCHIVE_ORDER, ORDERS } from '../../../gql/orders'
import { LIST_USERS } from '../../../gql/auth'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import UserMenu from '../../../components/UserMenu'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'

const AssignList = ({ status, searchId, history, isMasterAdmin }) => {
  const { loading, refetch, data } = useQuery(ORDERS, {
    variables: { status, searchId }
  })

  const orders = data ? data.orders : []
  const { data: dataUsers } = useQuery(LIST_USERS, {
    variables: { types: ['Decorator'] },
    fetchPolicy: 'no-cache'
  })

  console.log(dataUsers)

  const [showUserMenu, setShowUserMenu] = useState(false)

  const [assignDecorator] = useMutation(ASSIGN_DECORATOR)
  const [archiveOrder] = useMutation(ARCHIVE_ORDER)

  const handleUserSelected = (user) => {
    const orderId = showUserMenu && showUserMenu.dataset.order
    setShowUserMenu()
    if (user && showUserMenu && showUserMenu.dataset.order) {
      assignDecorator({
        variables: {
          orderId,
          decoratorId: user._id
        }
      }).then((result) => {
        if (
          result &&
          result.data &&
          result.data.assignDecorator &&
          result.data.assignDecorator.success
        ) {
          refetch()
        }
      })
    }
  }

  if (loading) {
    return <CircularProgress />
  }

  const handleClickAssignDecorator = (e) => {
    e.stopPropagation()
    setShowUserMenu(e.currentTarget)
  }

  return (
    <Flex width={'100%'} flexDirection={'column'} mt={2}>
      {orders &&
        orders.map((order) => {
          const hasDecorators =
            dataUsers && dataUsers.users && dataUsers.users.length > 0
          const decorator =
            hasDecorators &&
            dataUsers &&
            dataUsers.users.find((u) => u._id === order.decoratorId)
          return (
            <FlexWithHover
              hoverColor={'#ededed'}
              key={order._id}
              mb={2}
              width={'100%'}
              height={['initial', '100px']}
              bg={'white'}
              p={2}
              flexDirection={['column', 'row']}
              jusifyContent={'space-between'}
              alignItems={'center'}>
              <Flex
                onClick={() => {
                  history.push(`/order/${order._id}`)
                }}
                flex={1}
                ml={2}
                flexDirection={'column'}>
                <OrderIdTitle
                  fontSize={3}
                  color={'primary'}
                  textAlign={['center', 'left']}
                  fontWeight={'bold'}
                  title={order._id}></OrderIdTitle>
                {decorator && <Text>{decorator.name}</Text>}
                <Text textAlign={['center', 'left']}>
                  {getDateString(new Date(+order.updatedAt))}
                </Text>
              </Flex>
              <Flex>
                <Tooltip title='Assign decorator' aria-label='Assign decorator'>
                  <div>
                    <IconButton
                      data-order={order._id}
                      disabled={!hasDecorators}
                      edge='end'
                      onClick={handleClickAssignDecorator}>
                      <PersonAddIcon />
                    </IconButton>
                  </div>
                </Tooltip>

                {isMasterAdmin && (
                  <IconButton
                    aria-label='Delete'
                    onClick={() => {
                      const r = window.confirm('Are you sure?')
                      if (r === true) {
                        archiveOrder({
                          variables: { id: order._id },
                          update: (proxy, result) => {
                            refetch()
                          }
                        })
                      }
                    }}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Flex>
            </FlexWithHover>
          )
        })}
      <UserMenu
        open={Boolean(showUserMenu)}
        anchorEl={showUserMenu}
        users={dataUsers && dataUsers.users}
        onSelect={handleUserSelected}
      />
    </Flex>
  )
}

export default withRouter(AssignList)
