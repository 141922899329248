import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { SecondaryButton } from '../../components/sharable/button'
import { Flex } from '../../components/primitives'
import { Terms } from './Terms'

function TermsDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>
        Hemset's Terms & conditions
      </DialogTitle>
      <DialogContent>
        <Flex>{Terms()}</Flex>
      </DialogContent>
      <DialogActions>
        <SecondaryButton mr={2} onClick={onClose} color='primary'>
          Cancel
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  )
}

export default TermsDialog
