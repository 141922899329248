import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles'
import { useDebouncedCallback } from 'use-debounce'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputBase from '@material-ui/core/InputBase'
import {
  ButtonWithLoading,
  SecondaryButton
} from '../../../components/sharable'
import { Flex, Textfield } from '../../../components/primitives'
import { getMeta } from '../../../modules/getMetaData'
import useForm from '../../../hooks/useForm'
import { ADD_MANUAL_PRODUCT_TO_ORDER } from '../../../gql/orders'

const useStyles = makeStyles((theme) => {
  return {
    header: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    searchInputWrapper: {
      backgroundColor: '#e7e7e7',
      borderRadius: '3px',
      fontSize: '16px',
      display: 'flex',
      padding: theme.spacing(0, 1)
    },
    searchInput: {},
    productTable: {
      height: '300px'
    },
    productList: {
      height: '300px'
    },
    productItem: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    selectedTitle: {
      marginTop: theme.spacing(2)
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      width: '500px'
    },
    loginButton: {
      color: '#ffffff'
    },
    card: {
      padding: theme.spacing(4)
    },
    loginButtonWrapper: {
      marginTop: theme.spacing(4)
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      marginRight: 10
    },
    logoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    logo: {
      height: 48
    },
    errorSnackbar: {
      position: 'relative',
      marginTop: theme.spacing(4)
    },
    errorSnackbarContent: {
      backgroundColor: theme.palette.error.dark
    },
    succesSnackbarContent: {
      backgroundColor: '#3BA35E'
    }
  }
})

const validate = (values) => {
  let errors = {}
  if (!values.title) {
    errors.title = 'title is required'
  }
  if (!values.vendor) {
    errors.vendor = 'vendor is required'
  }
  if (!values.price) {
    errors.price = 'price is required'
  }
  if (!values.quantity) {
    errors.quantity = 'quantity is required'
  }
  if (!values.imgUrl) {
    errors.imgUrl = 'image url is required'
  }
  if (!values.url) {
    errors.url = 'Product url is required'
  }

  return errors
}

function AddProductToOrderDialog({
  open,
  fullScreen,
  onClose,
  onAdd,
  orderId
}) {
  const classes = useStyles()
  const [addManualProduct] = useMutation(ADD_MANUAL_PRODUCT_TO_ORDER)

  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [debouncedSearchChange] = useDebouncedCallback((searchText) => {
    setLoading(true)
    getMeta(searchText.toLowerCase())
      .then((response) => {
        if (response && response.data) {
          setInitialValues({
            title: response.data.title,
            imgUrl: response.data.imageUrl,
            url: searchText.toLowerCase()
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, 500)

  const { values, errors, handleChange, handleSubmitTwo } = useForm(
    (vals, er) => {
      if (Object.keys(er).length === 0) {
        console.log(vals)
        submit(vals)
      }
    },
    validate,
    initialValues
  )

  const submit = (values) => {
    if (values) {
      setLoading(true)
      console.log(orderId)
      addManualProduct({
        variables: {
          orderId: orderId,
          title: values.title,
          price: parseInt(values.price),
          imgSrc: values.imgUrl,
          url: values.url,
          vendor: values.vendor,
          quantity: values.quantity,
          room: values.room
        },
        update: (proxy, mutationResult) => {
          setLoading(false)
          if (!mutationResult.data.addManualProduct.success) {
            alert('Could not add product')
          } else {
            console.log('saved!!')
            if (onClose) {
              onAdd()
              setInitialValues({})
              onClose()
            }
          }
        }
      })
    }
  }

  const handleClose = (e) => {
    if (onClose) {
      setInitialValues({})
      onClose()
    }
  }

  return (
    <Dialog maxWidth={'lg'} fullWidth fullScreen={fullScreen} open={open}>
      <DialogTitle>Add product</DialogTitle>
      <DialogContent>
        <div className={classes.searchInputWrapper}>
          <InputBase
            className={classes.searchInput}
            fullWidth
            placeholder='Paste product url here'
            inputProps={{ 'aria-label': 'Search Products' }}
            onChange={(e) => debouncedSearchChange(e.target.value)}
          />
        </div>
        {loading && <div>loading...</div>}
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <form
            onSubmit={handleSubmitTwo}
            className={classes.container}
            noValidate
            autoComplete='off'>
            <Textfield
              required
              error={errors.hasOwnProperty('title')}
              helperText={errors.title || ''}
              label='title'
              margin='normal'
              name='title'
              value={values.title || ''}
              onChange={handleChange}
            />

            <Textfield
              required
              error={errors.hasOwnProperty('vendor')}
              helperText={errors.vendor || ''}
              label='Vendor'
              margin='normal'
              name='vendor'
              value={values.vendor || ''}
              onChange={handleChange}
            />

            <Textfield
              required
              error={errors.hasOwnProperty('price')}
              helperText={errors.price || ''}
              label='Price'
              margin='normal'
              name='price'
              value={values.price || ''}
              onChange={handleChange}
            />

            <Textfield
              required
              error={errors.hasOwnProperty('quantity')}
              helperText={errors.quantity || ''}
              label='Quantity'
              margin='normal'
              name='quantity'
              value={values.quantity || ''}
              onChange={handleChange}
            />

            <Textfield
              error={errors.hasOwnProperty('room')}
              label='Room'
              margin='normal'
              name='room'
              value={values.room || ''}
              onChange={handleChange}
            />

            <Textfield
              required
              error={errors.hasOwnProperty('imgUrl')}
              helperText={errors.imgUrl || ''}
              label='Image url'
              margin='normal'
              name='imgUrl'
              value={values.imgUrl || ''}
              onChange={handleChange}
            />

            <Textfield
              required
              error={errors.hasOwnProperty('url')}
              helperText={errors.url || ''}
              label='Product url'
              margin='normal'
              name='url'
              value={values.url || ''}
              onChange={handleChange}
            />

            <ButtonWithLoading
              style={{ alignSelf: 'center' }}
              isLoading={loading}
              mt={4}>
              Add Product
            </ButtonWithLoading>
          </form>
        </div>

        <Flex
          flexDirection={'row'}
          jusifyContent={'flex-end'}
          style={{ marginBottom: '20px' }}>
          <SecondaryButton mr={2} color='primary' onClick={handleClose}>
            Cancel
          </SecondaryButton>
        </Flex>
      </DialogContent>
    </Dialog>
  )
}

export default AddProductToOrderDialog
