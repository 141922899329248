import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'

import { LOGOUT, CURRENT_USER } from '../gql/auth'
import { useMutation, useQuery } from '@apollo/react-hooks'

const AuthContext = React.createContext()

function AuthProvider(props) {
  const [currentUser, setCurrentUser] = useState()
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['dd.sid'])
  const { data, error, loading } = useQuery(CURRENT_USER, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!error && !loading && data && data.currentUser) {
      setCurrentUser(data.currentUser)
    }
  }, [data, error, loading])

  const [logout] = useMutation(LOGOUT, {
    update: (proxy, mutationResult) => {
      if (mutationResult.data.logout) {
        removeCookie('dd.sid', { path: '/' })
        setCurrentUser(undefined)
      }
    }
  })

  const login = (user) => {
    setCurrentUser(user)
  }
  return (
    <AuthContext.Provider
      value={{
        currentUser: currentUser,
        login: login,
        logout: logout
      }}>
      {props.children}
    </AuthContext.Provider>
  )
}

const AuthConsumer = AuthContext.Consumer
export default AuthContext
export { AuthProvider, AuthConsumer }
