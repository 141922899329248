import { gql } from 'apollo-boost'

const LOGINANDGETORDERWITHTOKEN = gql`
  mutation($token: String!) {
    loginAndGetOrderWithToken(token: $token) {
      success
      message
      user {
        _id
        name
        address
        phone
      }
      order {
        _id
        title
        description
        status
        createdAt
        updatedAt
        completedAt
        assignedAt
        startedAt
        completedAt
        title
        description
        customerId
        decoratorId
        status
        notes
        desc
        files {
          _id
          origName
          name
          url
          uploadUrl
        }
        customerOrder {
          budget
          homeVisit
          rooms
          files {
            _id
            origName
            name
            url
          }
          comment
          domain
          stripeChargeId
          discountCode
        }
        wallColors {
          description
          title
          imgSrc
          url
          price
        }
        reachProducts {
          productId
          title
          imgSrc
          url
          vendor
          quantity
          attributes {
            size
            color
          }
          inStock
          price
          deliveryTime
        }
        manualProducts {
          productId
          title
          imgSrc
          url
          quantity
          vendor
          price
          room
        }
        products {
          productId
          product {
            _id
            name
            url
            price {
              value
              currency
            }
            creatorId
            files {
              origName
              name
              url
            }
          }
          count
        }
        moodboards {
          _id
          origName
          name
          url
          uploadUrl
        }
        floorplans {
          _id
          origName
          name
          url
          uploadUrl
        }
        comments {
          _id
          createdBy
          from
          to
          createdAt
          updatedAt
          text
          emailDelivery {
            status
            timestamp
          }
          readBy
          files {
            _id
            name
            url
          }
        }
      }
    }
  }
`

export { LOGINANDGETORDERWITHTOKEN }
