import React, { Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => {
  return {
    errorContainer: {
      maxWidth: 400,
      marginTop: theme.spacing(3),
      textAlign: 'center',
      margin: '0 auto',
      padding: 10
    }
  }
})

const ErrorCard = function (props) {
  const classes = useStyles()
  return (
    <Fragment>
      <Paper className={classes.errorContainer}>
        <Grid container>
          <Grid item>
            <ErrorIcon style={{ fontSize: 50 }} color='error' />
          </Grid>
          <Grid item sm container>
            <Grid item container direction='column'>
              <Grid item>
                <Typography variant='h6' component='h6'>
                  { props.message || 'Error Occured'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Pleae try again..
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  )
}

export default ErrorCard
