import React, { useState } from 'react'
import { Flex, Text } from '../../components/primitives'
import { BlogList } from './BlogList'
import { ButtonWithLoading } from '../../components/sharable/button'
import axios from 'axios'
import { useQuery } from '@apollo/react-hooks'
import { BLOGSADMIN } from '../../gql/blog'
import { BlogForm } from './BlogForm'

const getUrl = () => {
  if (window.location.origin === 'https://adminstage.hemset.se') {
    return 'https://api.netlify.com/build_hooks/5da35d3904b5b3582f2e0ba0'
  }

  if (process.env.NODE_ENV !== 'production') {
    return 'https://'
  }

  return 'https://api.netlify.com/build_hooks/5da35d76850f2b5c85d8f401'
}

const BlogPage = () => {
  const { refetch } = useQuery(BLOGSADMIN)

  const [loading, setLoading] = useState(false)
  const [isEditMode, seIsEditMode] = useState(null)
  const [buildProcessText, setBuildProcessText] = useState(false)
  return (
    <Flex
      p={2}
      flexDirection={'column'}
      jusifyContent={'center'}
      alignItems={'center'}
      width={'100%'}>
      <Text mt={4} mb={4} fontWeight={'bold'} fontSize={8}>
        {isEditMode ? 'Edit blog' : 'Add blog'}
      </Text>

      {buildProcessText ? (
        <Text fontSize={3} mt={2} mb={2}>
          Build started! need to wait a few minutes to finish!{' '}
        </Text>
      ) : (
        <ButtonWithLoading
          mt={2}
          mb={2}
          isLoading={loading}
          onClick={async () => {
            setLoading(true)
            await axios.post(getUrl())
            setLoading(false)
            setBuildProcessText(true)
          }}>
          Start new build process
        </ButtonWithLoading>
      )}
      <BlogForm
        isEdit={isEditMode}
        added={() => {
          refetch()
        }}
      />

      <Text mt={4} fontWeight={'bold'} fontSize={8}>
        Blog
      </Text>

      <BlogList
        edit={(blog) => {
          seIsEditMode(blog)
          window.scrollTo(0, 0)
        }}
      />
    </Flex>
  )
}

export default BlogPage
