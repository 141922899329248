import { gql } from 'apollo-boost'

export const VENDORS = gql`
  query {
    vendors
  }
`

export const WALL_COLORS = gql`
  query($category: String, $freetext: String, $page: Int) {
    wallColors(category: $category, freetext: $freetext, page: $page) {
      pageSize
      page
      categories
      total
      products {
        id
        _id
        artno
        updateAt
        locales {
          title
          description
          images
          variants {
            artno
            urls
            price
            stock
            attributes {
              size
              color
            }
          }
        }
      }
    }
  }
`

export const VENDOR = gql`
  query($vendor: String!) {
    vendor(vendor: $vendor) {
      slug
      name
      locales {
        country
        currency
        url
        defaultShippingCost
        freeShippingAbove
        minShippingOrder
        estimatedDelivery
      }
    }
  }
`

export const VENDER_PRODUCTS = gql`
  query($vendor: String!, $freetext: String, $page: Int) {
    vendorProducts(vendor: $vendor, freetext: $freetext, page: $page) {
      pageSize
      page
      total
      products {
        id
        _id
        artno
        updateAt
        locales {
          title
          description
          images
          variants {
            artno
            urls
            price
            stock
            attributes {
              size
              color
            }
          }
        }
      }
    }
  }
`

export const CREATE_CHECKOUT = gql`
  mutation($orderId: String!, $productIds: [String!]) {
    createCheckout(orderId: $orderId, productIds: $productIds) {
      success
      message
      checkoutUrl
    }
  }
`
