import { Text as ReText } from 'rebass'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const Text = styled(ReText)``

Text.defaultProps = {
  fontFamily: 'main',
  color: 'dark'
}

Text.propTypes = {
  fontWeight: PropTypes.any,
  textAlign: PropTypes.any
}
