import request from 'superagent'

export const uploadFiles = (files) => {
  let ulPromises = []
  files.forEach((f) => {
    if (f.uploadUrl) {
      console.log(f.ref)
      ulPromises.push(
        new Promise((resolve, reject) => {
          request
            .put(f.uploadUrl)
            .send(f.ref)
            .retry()
            .on('progress', (event) => {
              console.log(`Uploading file ${f.origName}`, event)
            })
            .end((err, res) => {
              if (err) {
                reject(err)
              } else {
                resolve()
              }
            })
        })
      )
    }
  })
  return Promise.all(ulPromises).catch((err) => {
    console.log('Failed to upload files', err)
  })
}

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export const uploadFilesWithBase64 = (files) => {
  let ulPromises = []
  files.forEach((f) => {
    if (f.uploadUrl) {
      ulPromises.push(
        new Promise((resolve, reject) => {
          request
            .put(f.uploadUrl)
            .send(dataURLtoFile(f.raw, 'moodboard.png'))
            .retry()
            .on('progress', (event) => {
              console.log(`Uploading file ${f.origName}`, event)
            })
            .end((err, res) => {
              if (err) {
                reject(err)
              } else {
                resolve()
              }
            })
        })
      )
    }
  })
  return Promise.all(ulPromises).catch((err) => {
    console.log('Failed to upload files', err)
  })
}
