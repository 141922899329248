import React, { useState } from 'react'
import { Flex, Text } from '../../components/primitives'
import { ButtonWithLoading } from '../../components/sharable/button'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Input } from '../../components/sharable'
import { ADD_DISCOUNT, DISCOUNTS, DELETE_DISCOUNT } from '../../gql/discount'
import { showAlert, showError } from '../../modules/Alert'

const DiscountPage = () => {
  const { refetch, data } = useQuery(DISCOUNTS)
  const [addDiscount] = useMutation(ADD_DISCOUNT)
  const [deleteDiscount] = useMutation(DELETE_DISCOUNT)

  const [loading, setLoading] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)

  const [name, setName] = useState('')
  const [discount, setDiscount] = useState('')
  const [statusText, setStatusText] = useState('')

  return (
    <Flex
      p={2}
      flexDirection={'column'}
      jusifyContent={'center'}
      alignItems={'center'}
      width={'100%'}>
      <Text mt={4} mb={4} fontWeight={'bold'} fontSize={8}>
        Add discount item
      </Text>

      <Text>{statusText}</Text>

      <Flex flexDirection={'column'}>
        <Text>Discount name</Text>
        <Input
          value={name}
          onChange={(event) => {
            setName(event.target.value)
          }}
        />

        <Text mt={2}>Discount value ( in procent, write 1-100)</Text>
        <Input
          value={discount}
          onChange={(event) => {
            setDiscount(event.target.value)
          }}
        />

        <ButtonWithLoading
          isLoading={loading}
          mt={2}
          onClick={async () => {
            setStatusText('')
            if (name && discount) {
              const d = parseInt(discount)
              if (d > 0 && d < 101) {
                setLoading(true)

                addDiscount({
                  variables: { name: name, discount: parseInt(discount) },
                  update: (proxy, mutationResult) => {
                    if (!mutationResult.data.addDiscount.success) {
                      showError({
                        message: 'Something went wrong, cant add discount.'
                      })
                      setLoading(false)
                    } else {
                      showAlert({ message: 'Discount saved!' })
                      refetch().finally(() => {
                        setLoading(false)
                        setName('')
                        setDiscount('')
                      })
                    }
                  }
                })
              }
            }
          }}>
          Add
        </ButtonWithLoading>
      </Flex>

      <Text mt={4} mb={4} fontWeight={'bold'} fontSize={8}>
        Discounts
      </Text>

      {data &&
        data.discounts &&
        data.discounts.map((discount, index) => {
          return (
            <Flex
              key={index}
              maxWidth={'500px'}
              width={'100%'}
              jusifyContent={'space-between'}
              height={'60px'}
              p={2}
              alignItems={'center'}>
              <Text>Name:{discount.name}</Text>
              <Text>Value: {discount.value}%</Text>
              <ButtonWithLoading
                isLoading={removeLoading}
                onClick={async () => {
                  setRemoveLoading(true)
                  deleteDiscount({
                    variables: { id: discount._id },
                    update: (proxy, mutationResult) => {
                      if (!mutationResult.data.deleteDiscount.success) {
                        showError({
                          message: 'Could not remove discount'
                        })
                        setRemoveLoading(false)
                      } else {
                        refetch().finally(() => {
                          setRemoveLoading(false)
                        })
                      }
                    }
                  })
                }}>
                Remove
              </ButtonWithLoading>
            </Flex>
          )
        })}
    </Flex>
  )
}

export default DiscountPage
