import React, { useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

import CommentStatus from './CommentStatus'
import { Flex } from '../../../components/primitives'
import { isImage } from '../../../modules/util'
import { colors } from '../../../modules/colors'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      borderRadius: '5px',
      backgroundColor: ({ myComment }) =>
        myComment ? 'rgba(0, 0, 0, 0.05)' : colors.primaryLight,
      padding: theme.spacing(2),
      marginTop: "8px",
      display: 'flex',
      flexDirection: 'column'
    },
    textHolder: {
      display: 'flex',
      width: '100%'
    },
    text: {
      border: 'none',
      background: 'none',
      resize: 'none',
      fontSize: theme.typography.pxToRem(12),
      flex: 1
    },
    createdAt: {
      alignSelf: 'flex-end',
      color: 'rgba(0, 0, 0, 0.3)'
    },
    annotations: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    img: {
      height: '100px',
      width: '200px',
      objectFit: 'contain'
    }
  }
})

const getDateString = (date) => {
  return date.toLocaleDateString('sv-SE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  })
}

function Comment({ comment, currentUser }) {
  const checkIfProposal = (text) => {
    if (text.includes('###proposal_sent$$$')) {
      const url = text.split('$$$')
      return (
        <Flex flex={1}>
          <a
            rel='noopener noreferrer'
            style={{ color: '#646552' }}
            href={url[1]}>
            Link to proposal
          </a>
        </Flex>
      )
    } else {
      return (
        <textarea
          style={{ fontSize: '0.875rem' }}
          ref={textRef}
          readOnly
          disabled
          wrap='soft'
          className={classes.text}
          value={comment.text}
        />
      )
    }
  }

  const myComment =
    currentUser &&
    (comment.createdBy === currentUser._id ||
      comment.from.includes(currentUser.email) ||
      comment.from.includes(currentUser.name))
  const classes = useStyles({ myComment })
  const textRef = useRef()

  useEffect(() => {
    if (comment && textRef.current) {
      textRef.current.style.height = `${textRef.current.scrollHeight}px`
    }
  }, [comment, textRef])

  return (
    <Flex className={classes.root} mb={2}>
      <Flex className={classes.textHolder} flexDirection={['column', 'row']}>
        {comment && checkIfProposal(comment.text)}
        <div className={classes.annotations}>
          <CommentStatus
            status={comment.emailDelivery && comment.emailDelivery.status}
          />
          <span className={classes.createdAt}>
            {getDateString(new Date(+comment.createdAt))}
          </span>
          <span className={classes.createdAt}>
            {myComment ? 'You' : comment.from}
          </span>
        </div>
      </Flex>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {comment &&
          comment.files &&
          comment.files.map((file) => {
            if (isImage(file.name)) {
              return (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ marginRight: '10px' }}
                  href={file.url}
                  key={file.url}>
                  <img className={classes.img} src={file.url} alt='' />
                </a>
              )
            } else {
              return (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ marginRight: '10px' }}
                  href={file.url}
                  key={file.url}>
                  {file.name ? file.name : 'Link'}
                </a>
              )
            }
          })}
      </div>
    </Flex>
  )
}

export default Comment
