import { gql } from 'apollo-boost'

export const ADD_INSTAGRAM_POST = gql`
  mutation($igId: String!) {
    addInstagramPost(igId: $igId) {
      success
      message
    }
  }
`

export const DELETE_INSTAGRAM_POST = gql`
  mutation($id: ID!) {
    deleteInstagramPost(id: $id) {
      success
      message
    }
  }
`

export const INSTAGRAMS = gql`
  query {
    instagrams {
      _id
      createdAt
      updatedAt
      igId
    }
  }
`
