import React, { useState } from 'react'
import Pagination from 'material-ui-flat-pagination'

export const Pages = ({ total, limit, onChangePage }) => {
  console.log(total)
  console.log(limit)

  const [offset, setOffset] = useState(0)
  return (
    <Pagination
      limit={limit}
      offset={offset}
      total={total}
      onClick={(e, offset,page) => {
        setOffset(offset)
        onChangePage(page)
      }}
    />
  )
}
