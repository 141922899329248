import React from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import GenerateIcon from '@material-ui/icons/Cached'
import generator from 'generate-password'

import useForm from '../../hooks/useForm'

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '400px',
    padding: '0 5px'
  },
  loginButton: {
    color: '#ffffff'
  },
  loginButtonWrapper: {
    flex: 1,
    marginTop: theme.spacing(4)
  },
  passwordInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    marginRight: 10
  },
  errorSnackbar: {
    position: 'relative',
    marginTop: theme.spacing(4)
  },
  errorSnackbarContent: {
    backgroundColor: theme.palette.error.dark
  },
  iconSmall: {
    fontSize: 20
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}))

const generatePassword = () => {
  return generator.generate({
    length: 8,
    numbers: true
  })
}

const validate = (values) => {
  let errors = {}
  if (!values.email) {
    errors.email = 'Email address is required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid'
  }
  if (!values.password) {
    errors.password = 'Password is required'
  } else if (values.password.length < 8) {
    errors.password = 'Password cannot be empty or less than 8 characters'
  }
  if (!values.name) {
    errors.name = 'Name is required'
  }
  if (!values.address) {
    errors.address = 'Address is required'
  }
  if (!values.phone) {
    errors.phone = 'Phone is required'
  }
  if (!values.vatNumber) {
    errors.vatNumber = 'VAT is required'
  }
  if (!values.bankNumber) {
    errors.bankNumber = 'Bank is required'
  }
  return errors
}

function CreateDecoratorForm(props) {
  const classes = useStyles(props)
  const { onLogin, showErrorSnack, onErrorSnackClose } = props
  const initialValues = {
    password: generatePassword()
  }
  const { values, errors, handleChange, handleSubmit } = useForm(
    onLogin,
    validate,
    initialValues
  )

  return (
    <Box className={classes.container}>
      <form
        id='create-decorator-form'
        onSubmit={handleSubmit}
        noValidate
        autoComplete='off'>
        <TextField
          fullWidth
          required
          error={errors.hasOwnProperty('name')}
          helperText={errors.name || ''}
          label='Name'
          type='name'
          name='name'
          autoComplete='name'
          margin='normal'
          value={values.name || ''}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          required
          error={errors.hasOwnProperty('email')}
          helperText={errors.email || ''}
          label='Email'
          type='email'
          name='email'
          autoComplete='email'
          margin='normal'
          value={values.email || ''}
          onChange={handleChange}
        />
        <Box className={classes.passwordInputWrapper}>
          <TextField
            fullWidth
            required
            error={errors.hasOwnProperty('password')}
            helperText={errors.password || ''}
            label='Password'
            type='text'
            autoComplete='current-password'
            margin='normal'
            name='password'
            value={values.password}
            onChange={handleChange}
          />
          <IconButton className={classes.button} aria-label='Generate' disabled>
            <GenerateIcon
              className={clsx(classes.leftIcon, classes.iconSmall)}
            />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          required
          error={errors.hasOwnProperty('address')}
          helperText={errors.address || ''}
          label='Address'
          type='address'
          name='address'
          autoComplete='address'
          margin='normal'
          value={values.address || ''}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          required
          error={errors.hasOwnProperty('phone')}
          helperText={errors.phone || ''}
          label='Phone'
          type='phone'
          name='phone'
          autoComplete='phone'
          margin='normal'
          value={values.phone || ''}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          required
          error={errors.hasOwnProperty('vatNumber')}
          helperText={errors.vatNumber || ''}
          label='VAT'
          type='name'
          name='vatNumber'
          margin='normal'
          value={values.vatNumber || ''}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          required
          error={errors.hasOwnProperty('bankNumber')}
          helperText={errors.bankNumber || ''}
          label='Bank / account number'
          type='name'
          name='bankNumber'
          margin='normal'
          value={values.bankNumber || ''}
          onChange={handleChange}
        />
      </form>
      <Snackbar
        open={showErrorSnack}
        className={classes.errorSnackbar}
        autoHideDuration={2000}
        onClose={onErrorSnackClose}>
        <SnackbarContent
          className={classes.errorSnackbarContent}
          message={<span>Create failed</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={onErrorSnackClose}>
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    </Box>
  )
}

export default CreateDecoratorForm
