import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from './DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { SecondaryButton } from '../sharable/button/'
import { Flex } from '../primitives/'
import { ButtonWithLoading } from '../sharable/'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => {
  return {
    errorSnackbar: {
      position: 'relative',
      marginTop: theme.spacing(4)
    },
    errorSnackbarContent: {
      backgroundColor: theme.palette.error.dark
    }
  }
})

export default function DeleteItemDialog({
  fullScreen,
  onClose,
  onSubmit,
  disableSubmit,
  showSnackbar,
  onSnackbarClose,
  title,
  text,
  actionTitle = 'Delete'
}) {
  const classes = useStyles()

  return (
    <Dialog open fullScreen={fullScreen} onClose={onClose}>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <SecondaryButton mr={2} mb={2} onClick={onClose} color='primary'>
          Cancel
        </SecondaryButton>
        <Flex mb={2} mr={2}>
          <ButtonWithLoading
            onClick={onSubmit}
            color='primary'
            autoFocus
            isLoading={disableSubmit}>
            {actionTitle}
          </ButtonWithLoading>
        </Flex>
      </DialogActions>
      <Snackbar
        open={showSnackbar}
        className={classes.errorSnackbar}
        autoHideDuration={2000}
        onClose={onSnackbarClose}>
        <SnackbarContent
          className={classes.errorSnackbarContent}
          message={<span>Delete admin failed</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={onSnackbarClose}>
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    </Dialog>
  )
}
