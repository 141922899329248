import React, { useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Flex, Text } from '../../../components/primitives'
import AddFilesButton from '../../../components/AddFilesButton'
import AddIcon from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress'
import { TextInput } from '../../../components/sharable'
import { colors } from '../../../modules/colors'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      borderRadius: '5px',
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column'
    },
    inputHolder: {
      display: 'flex'
    },
    input: {
      flex: '1'
    }
  }
})

function AddComment({
  onCommentAdded,
  onFilesChanged,
  filesToAd,
  submitting,
  placeholder,
  buttonTitle
}) {
  const [comment, setComment] = useState()
  const classes = useStyles()

  const handleCommentChange = (e) => {
    setComment(e.target.value)
  }

  const handleSubmit = () => {
    if (comment) {
      setComment('')
      onCommentAdded && onCommentAdded(comment)
    }
  }
  return (
    <div className={classes.root + ' dont-show-in-pdf'}>
      <Flex flexDirection={'column'}>
        <TextInput
          InputProps={{ disableUnderline: true }}
          style={{ padding: '20px', paddingBottom: '19px', fontSize: '14px' }}
          value={comment}
          placeholder={placeholder}
          multiline
          onChange={handleCommentChange}
        />

        <Flex
          width={'100%'}
          jusifyContent={'space-between'}
          mt={1}
          className={'dont-show-in-pdf'}>
          {submitting ? (
            <CircularProgress />
          ) : (
            <Fragment>
              <Flex alignItems={'center'} color={'primary'}>
                <AddFilesButton
                  style={{ color: colors.primary }}
                  size='small'
                  multiple
                  onAddFiles={onFilesChanged}>
                  <AddIcon />
                </AddFilesButton>

                <Text ml={2} color={'primary'}>
                  Attach files
                </Text>
              </Flex>
              <Flex
                flexDirection={'column'}
                alignItems={'center'}
                jusifyContent={'center'}>
                <Button
                  height={'40px'}
                  disabled={!comment}
                  onClick={handleSubmit}
                  color='primary'>
                  {buttonTitle}
                </Button>
              </Flex>
            </Fragment>
          )}
        </Flex>
      </Flex>
      {filesToAd &&
        filesToAd.map((file) => {
          return <p key={file.name}> {file.name}</p>
        })}
    </div>
  )
}

export default AddComment
