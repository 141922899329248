import { Flex } from '../../primitives'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import { SecondaryButton } from './SecondaryButton'

export const ScondaryButtonWithLoading = ({ isLoading, ...props }) => {
  if (isLoading) {
    return (
      <Flex width={'40px'} height={'40px'} {...props}>
        <CircularProgress />
      </Flex>
    )
  } else {
    return <SecondaryButton {...props} />
  }
}
