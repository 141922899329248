import React, { useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Flex } from '../components/primitives'

import AuthContext from '../modules/AuthContext'
import { isAdmin, isCustomer } from '../modules/const'
import AdminDashboard from '../components/dashboard/AdminDashboard'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  button: {
    width: 200,
    color: theme.palette.primary.dark
  },
  title: {
    color: theme.palette.primary.dark,
    fontWeight: 600
  },
  fixedHeight: {
    height: 240
  },
  gridItem: {
    height: 400,
    overflowX: 'auto'
  }
})

const Landing = (props) => {
  const { currentUser, logout } = useContext(AuthContext)

  const { classes } = props

  if (!currentUser) {
    return <Flex />
  }
  return (
    <div className={classes.root}>
      {currentUser && isCustomer(currentUser['__typename']) && (
        <Button variant='outlined' onClick={logout} className={classes.button}>
          LOG OUT
        </Button>
      )}
      {currentUser && isAdmin(currentUser['__typename']) && <AdminDashboard />}
    </div>
  )
}

export default withStyles(styles)(Landing)
