import styled from 'styled-components'
import { space, fontWeight, width, fontSize, color } from 'styled-system'
import { colors } from '../../modules/colors'

export const Alink = styled.a`
 ${space}
 ${width}
 ${fontSize}
 ${color}
 ${fontWeight}
 text-decoration:none;
 &:hover {
    color: ${(props) => props.dark};
    text-decoration: underline;
  }
`

Alink.defaultProps = {
  color: colors.dark
}
