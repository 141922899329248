import hmHome from '../assets/images/hm_home.png'
import jotun from '../assets/images/Jotun_std_110_RGB_72dpi.jpg'
import svenssons from '../assets/images/svenssons_logo transparent.png'
import konstlagret from '../assets/images/konst.png'
import jysk from '../assets/images/jysk-logo-headder_1.png'
import pine from '../assets/images/23pine logo.png'
import sam from '../assets/images/logga-png-pos.png'
import ikea from '../assets/images/ikea-1-logo-png-transparent.png'
import reforma from '../assets/images/reforma.png'
import prettypegs from '../assets/images/Prettygpegs.jpg'
import ellosLogo from '../assets/images/logo-ellos.png'
import calixter from '../assets/images/calixter.png'
import jotex from '../assets/images/jotex.png'
import nordicNest from '../assets/images/nordicnest_logo.jpg'
import granit from '../assets/images/Granit-logo.jpg'
import placeholder from '../assets/images/placeholder.png'

export const getVendor = (vendor) => {
  console.log(vendor)
  if (vendor === 'hm_home') {
    return {
      name: 'H&M Home',
      img: hmHome,
      url: 'https://www2.hm.com/sv_se/home.html'
    }
  } else if (vendor === 'jotun') {
    return { name: 'Jotun', img: jotun, url: 'https://www.jotun.com/se/se/b2c' }
  } else if (vendor === 'svenssons') {
    return {
      name: 'Svenssons',
      img: svenssons,
      url: 'https://www.svenssons.se/'
    }
  } else if (vendor === 'konstlagret') {
    return {
      name: 'Konstlagret',
      img: konstlagret,
      url: 'https://konstlagret.se/'
    }
  } else if (vendor === 'jysk') {
    return {
      name: 'Jysk',
      img: jysk,
      url: 'https://jysk.se/'
    }
  } else if (vendor === '23pine') {
    return {
      name: '23Pine',
      img: pine,
      url: 'https://23pine.com/'
    }
  } else if (vendor === 'dearsam') {
    return {
      name: 'Dear Sam',
      img: sam,
      url: 'https://dearsam.com/se'
    }
  } else if (vendor === 'ikea') {
    return {
      name: 'Ikea',
      img: ikea,
      url: 'https://www.ikea.com'
    }
  } else if (vendor === 'reforma') {
    return {
      name: 'Reforma',
      img: reforma,
      url: 'https://www.reformasthlm.se/'
    }
  } else if (vendor === 'prettypegs') {
    return {
      name: 'Prettypegs',
      img: prettypegs,
      url: 'https://www.prettypegs.com'
    }
  } else if (vendor === 'ellos') {
    return {
      name: 'Ellos',
      img: ellosLogo,
      url: 'https://www.ellos.se'
    }
  } else if (vendor === 'granit') {
    return {
      name: 'Granit',
      img: granit,
      url: 'https://www.granit.com/'
    }
  } else if (vendor === 'calixter') {
    return {
      name: 'Calixter',
      img: calixter,
      url: 'https://calixter.se/'
    }
  } else if (vendor === 'jotex') {
    return {
      name: 'Jotex',
      img: jotex,
      url: 'https://www.jotex.se/'
    }
  } else if (vendor === 'nordic-nest') {
    return {
      name: 'Nordic Nest',
      img: nordicNest,
      url: 'https://www.nordicnest.se/'
    }
  } else {
    return {
      name: vendor,
      img: placeholder,
      url: 'https://hemset.se/not-implemented'
    }
  }
}
