import React from 'react'
import { Flex, Text } from '../../../components/primitives'
import { FlexWithHover, OrderIdTitle } from '../../../components/sharable'
import { getDateString } from '../../../modules/date'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ORDERS } from '../../../gql/orders'
import { LIST_USERS } from '../../../gql/auth'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { ARCHIVE_ORDER } from '../../../gql/orders'

const OrderList = ({ status, searchId, history, isMasterAdmin }) => {
  const { loading, refetch, data } = useQuery(ORDERS, {
    variables: { status, searchId }
  })

  const { data: dataUsers } = useQuery(LIST_USERS, {
    variables: { types: ['Decorator'] },
    fetchPolicy: 'no-cache'
  })

  console.log(dataUsers)

  const [archiveOrder] = useMutation(ARCHIVE_ORDER)

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Flex width={'100%'} flexDirection={'column'} mt={2}>
      {data.orders &&
        data.orders.map((order) => {
          const hasDecorators =
            dataUsers && dataUsers.users && dataUsers.users.length > 0
          const decorator =
            hasDecorators &&
            dataUsers &&
            dataUsers.users.find((u) => u._id === order.decoratorId)
          return (
            <FlexWithHover
              hoverColor={'#ededed'}
              key={order._id}
              mb={2}
              width={'100%'}
              height={['initial', '100px']}
              bg={'white'}
              p={2}
              flexDirection={['column', 'row']}
              jusifyContent={'space-between'}
              alignItems={'center'}>
              <Flex
                flex={1}
                ml={2}
                flexDirection={'column'}
                onClick={() => {
                  history.push(`/order/${order._id}`)
                }}>
                <OrderIdTitle
                  fontSize={3}
                  color={'primary'}
                  textAlign={['center', 'left']}
                  fontWeight={'bold'}
                  title={order._id}
                />
                <Text textAlign={['center', 'left']}>
                  {order.customerOrder && order.customerOrder.name}
                </Text>
                {decorator && (
                  <Text textAlign={['center', 'left']}>
                    Decorator: {decorator.name}
                  </Text>
                )}
                <Text textAlign={['center', 'left']}>
                  {getDateString(new Date(+order.updatedAt))}
                </Text>
              </Flex>
              <Flex>
                {/* <Text textAlign={['center', 'left']}>{order.status}</Text>*/}
                {order.status === 'completed' && (
                  <Text mr={2}>Compensation: 800 kr (1000 kr inc VAT)</Text>
                )}
                {isMasterAdmin && (
                  <IconButton
                    aria-label='Delete'
                    onClick={() => {
                      const r = window.confirm('Are you sure?')
                      if (r === true) {
                        archiveOrder({
                          variables: { id: order._id },
                          update: (proxy, result) => {
                            refetch()
                          }
                        })
                      }
                    }}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Flex>
            </FlexWithHover>
          )
        })}
    </Flex>
  )
}

export default withRouter(OrderList)
