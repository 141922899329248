import fileExtension from 'file-extension'
import imageExtensions from 'image-extensions'

const isUnauthenticatedError = (error) => {
  const errors = Array.isArray(error) ? error : error.graphQLErrors
  return (
    errors &&
    errors.some(
      (element) =>
        element.extensions && element.extensions.code === 'UNAUTHENTICATED'
    )
  )
}

const getUserInitials = (user) => {
  if (!user) {
    return ''
  }
  let words
  if (user.name) {
    words = user.name.toUpperCase().split(' ')
  } else if (user.email) {
    words = user.email
      .toUpperCase()
      .split('@')[0]
      .split('.')
  }
  if (!words) {
    return ''
  }
  let result = ''
  let i = 0
  for (let w of words) {
    if (w) {
      result += w.charAt(0)
      if (i++ > 1) {
        break
      }
    }
  }
  return result
}

const userHasRole = (user, role) => {
  if (!user && !user.roles) {
    return false
  }
  return user.roles.includes(role)
}

const isImage = (filepath) => {
  const ext = fileExtension(filepath)
  const lowerCaseExt = ext.toLowerCase()
  return imageExtensions.some((ext) => ext === lowerCaseExt)
}

const getDateString = (date) => {
  return date.toLocaleDateString('sv-SE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  })
}

const getCanvasSizeAndScale = (
  defaultHeight,
  defaultWidth,
  productWidthPX,
  productHeightPX
) => {
  let scaleFactor = defaultWidth / productWidthPX
  let canvasSize = {
    width: productWidthPX * scaleFactor,
    height: productHeightPX * scaleFactor
  }

  if (canvasSize.height > defaultHeight) {
    scaleFactor = defaultHeight / productHeightPX
    canvasSize = {
      width: productWidthPX * scaleFactor,
      height: productHeightPX * scaleFactor
    }
  }

  return { scale: scaleFactor, canvasSize: canvasSize }
}

const cmToPx = (cm, dip = 200) => {
  return (cm * dip) / 2.54
}

const getAwinStoreId = (url) => {
  if (url.includes('homeroom')) {
    return 12846
  } else if (url.includes('nordic')) {
    return 7916
  } else if (url.includes('hemtex')) {
    return 12788
  } else if (url.includes('jotex')) {
    return 9961
  } else if (url.includes('newport')) {
    return 12721
  } else if (url.includes('reforma')) {
    return 18533
  } else {
    return null
  }
}

const getAffiliateUrl = (url) => {
  if (url.includes('calixter')) {
    // IF calixter we want to use diffrent affiliate
    const urlEncode = encodeURI(`${url}`)
    return `https://click.adrecord.com?c=28978&p=964&url=${urlEncode}`
  } else {
    return createAwinUrl(url)
  }
}

const createAwinUrl = (url) => {
  const storeId = getAwinStoreId(url)
  if (!storeId) {
    return url
  }
  const urlEncode = encodeURI(`[[${url}]]`)
  const awinUrl = `https://www.awin1.com/cread.php?awinmid=${storeId}&awinaffid=434281&clickid=&p=${urlEncode}`
  return awinUrl
}

const ccyFormat = (num) => {
  if (num) {
    return `${num.toFixed(0)}`
  }
  return num
}

export {
  cmToPx,
  createAwinUrl,
  isUnauthenticatedError,
  getUserInitials,
  getCanvasSizeAndScale,
  userHasRole,
  ccyFormat,
  getAffiliateUrl,
  isImage,
  getDateString
}
