import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import { Button } from './primitives/Button'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'relative'
    },
    indicator: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: theme.spacing(0.25),
      borderRadius: '5px',
      backgroundColor: 'lightgrey',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(10)
    }
  }
})

const CommittableTextField = ({ value, onChange, onSave, ...otherProps }) => {
  const classes = useStyles()
  const [_value, setValue] = useState(value || '')

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleBlur = (e) => {
    /* if (value !== _value && onChange) {
      onChange(_value || '')
    }*/
  }

  const handleSave = () => {
    if (_value) {
      onSave(_value)
    }
  }

  return (
    <div className={classes.root}>
      <TextField
        value={_value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...otherProps}
      />
      {value !== _value && _value && (
        <div className={classes.indicator}>
          <Button onClick={handleSave} className={classes.indicator}>
            Save
          </Button>
        </div>
      )}
    </div>
  )
}

export default CommittableTextField
