import { gql } from 'apollo-boost'

const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      user {
        _id
        name
        email
        address
        phone
      }
    }
  }
`

const LOGOUT = gql`
  mutation {
    logout
  }
`
const CURRENT_USER = gql`
  query {
    currentUser {
      email
      name
      __typename
      _id
      ... on Decorator {
        vatNumber
        address
        phone
        bankNumber
      }
    }
  }
`

const CREATE_ADMIN = gql`
  mutation($input: CreateAdminInput!) {
    createAdmin(input: $input) {
      success
      message
    }
  }
`

const LIST_USERS = gql`
  query($types: [UserType!]) {
    users(include: $types) {
      __typename
      _id
      createdAt
      email
      name
      phone
      ... on Decorator {
        vatNumber
        address
        bankNumber
      }
      ... on Customer {
        pinterest
        instagram
      }
    }
  }
`

const UPDATE_ADMIN = gql`
  mutation($id: ID!, $input: UpdateAdminInput!) {
    updateAdmin(id: $id, input: $input) {
      success
    }
  }
`

const DELETE_USER = gql`
  mutation($id: ID!) {
    deleteUser(id: $id) {
      success
      message
    }
  }
`

const CHANGE_PASSWORD = gql`
  mutation($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      success
      message
    }
  }
`
const CUSTOMER = gql`
  query($id: ID!) {
    customer: user(id: $id) {
      ... on Customer {
        _id
        createdAt
        updatedAt
        email
        name
        address
        phone
        instagram
        pinterest
      }
    }
  }
`

const MAKE_ADMIN_MASTER = gql`
  mutation($id: ID!) {
    makeAdminMaster(id: $id) {
      success
      message
    }
  }
`

export {
  LOGIN,
  LOGOUT,
  CURRENT_USER,
  CREATE_ADMIN,
  LIST_USERS,
  UPDATE_ADMIN,
  DELETE_USER,
  CHANGE_PASSWORD,
  CUSTOMER,
  MAKE_ADMIN_MASTER
}
