import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import LinearProgress from '@material-ui/core/LinearProgress'
import { SecondaryButton } from '../../../components/sharable/button/'
import { Button } from '../../../components/primitives/'
import ProductDialogContent from './ProductDialogContent'

function ProductDialog({ open, onClose, submitting, onSubmit }) {
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [currency, setCurrency] = useState('SEK')
  const [url, setUrl] = useState('')
  const [files, setFiles] = useState([])
  const [errors, setErrors] = useState({})

  const handleNameChanged = (e) => {
    e.stopPropagation()
    setName(e.target.value)
    if (!e.target.value) {
      setErrors({ ...errors, name: 'Name is required' })
    } else if (errors.name) {
      delete errors.name
    }
  }

  const handleNameBlur = (e) => {
    if (!e.target.value) {
      setErrors({ ...errors, name: 'Name is required' })
    }
  }

  const handlePriceChanged = (e) => {
    e.stopPropagation()
    setPrice(parseFloat(e.target.value))
    if (!e.target.value) {
      setErrors({ ...errors, price: 'Price is required' })
    } else if (errors.price) {
      delete errors.price
    }
  }

  const handlePriceBlur = (e) => {
    if (!e.target.value) {
      setErrors({ ...errors, price: 'Price is required' })
    }
  }

  const handleCurrencyChanged = (e) => {
    e.stopPropagation()
    setCurrency(e.target.value)
    if (!e.target.value) {
      setErrors({ ...errors, currency: 'Currency is required' })
    } else if (errors.currency) {
      delete errors.currency
    }
  }

  const handleCurrencyBlur = (e) => {
    if (!e.target.value) {
      setErrors({ ...errors, currency: 'Currency is required' })
    }
  }

  const handleUrlChanged = (e) => {
    e.stopPropagation()
    setUrl(e.target.value)
  }

  const handleFilesChanged = (value) => {
    setFiles(value)
  }

  const handleFileDeleted = (value) => {
    setFiles(files.filter((f) => f.name !== value))
  }

  const canSubmit = () =>
    !submitting && name && price && currency && Object.keys(errors).length === 0

  const handleSubmit = (e) => {
    e.stopPropagation()
    if (onSubmit) {
      const product = { name, price: { value: price, currency }, url }
      const fileMap = {}
      if (files && files.length > 0) {
        product.files = []
        for (let i = 0; i < files.length; i++) {
          product.files.push({ origName: files[i].name })
          fileMap[files[i].name] = files[i]
        }
      }
      onSubmit(product, fileMap)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Add product</DialogTitle>
      <DialogContent>
        <ProductDialogContent
          name={name}
          price={price}
          currency={currency}
          url={url}
          files={files}
          onNameChanged={handleNameChanged}
          onPriceChanged={handlePriceChanged}
          onCurrencyChanged={handleCurrencyChanged}
          onUrlChanged={handleUrlChanged}
          onFilesChanged={handleFilesChanged}
          onFileDeleted={handleFileDeleted}
          onBlurName={handleNameBlur}
          onBlurPrice={handlePriceBlur}
          onBlurCurrency={handleCurrencyBlur}
          errors={errors}
        />
        {submitting && <LinearProgress />}
      </DialogContent>
      <DialogActions>
        <SecondaryButton mr={2} onClick={onClose} color='primary'>
          Cancel
        </SecondaryButton>
        <Button disabled={!canSubmit()} onClick={handleSubmit} color='primary'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProductDialog
