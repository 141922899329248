import React, { useState } from 'react'
import useForm from '../../hooks/useForm'
import { Textfield } from '../../components/primitives'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { makeStyles } from '@material-ui/styles'
import { ButtonWithLoading } from '../../components/sharable/button'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_ORDER_ADMIN } from '../../gql/orders'
import { TextareaAutosize } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: '500px'
  },
  loginButton: {
    color: '#ffffff'
  },
  card: {
    padding: theme.spacing(4)
  },
  loginButtonWrapper: {
    marginTop: theme.spacing(4)
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    marginRight: 10
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    height: 48
  },
  errorSnackbar: {
    position: 'relative',
    marginTop: theme.spacing(4)
  },
  errorSnackbarContent: {
    backgroundColor: theme.palette.error.dark
  },
  succesSnackbarContent: {
    backgroundColor: '#3BA35E'
  }
}))

const validate = (values) => {
  let errors = {}
  if (!values.name) {
    errors.name = 'name is required'
  }
  if (!values.email) {
    errors.email = 'Email address is required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid'
  }
  return errors
}

function CreateOrderForm({ initialValues, userId }) {
  const [createOrder] = useMutation(CREATE_ORDER_ADMIN)
  const [errorSnack, setSetErrorSnack] = useState(false)
  const [successSnack, setSuccessSnack] = useState(false)

  const { values, errors, handleChange, handleSubmitTwo } = useForm(
    (vals, er) => {
      if (Object.keys(er).length === 0) {
        console.log(vals)
        submit(vals)
      }
    },
    validate,
    initialValues
  )
  const classes = useStyles()
  const [submiting, setSubmiting] = useState(false)

  const submit = (values) => {
    setSubmiting(true)
    createOrder({
      variables: { id: userId, input: { ...values } },
      update: (proxy, mutationResult) => {
        setSubmiting(false)
        if (!mutationResult.data.createOrderAdmin.success) {
          setSetErrorSnack(true)
        } else {
          setSuccessSnack(true)
        }
      }
    })
  }

  return (
    <div>
      <h3 style={{ margin: '20px' }}>Create order</h3>
      <Card className={classes.card}>
        <CardContent>
          <form
            onSubmit={handleSubmitTwo}
            className={classes.container}
            noValidate
            autoComplete='off'>
            <Textfield
              required
              error={errors.hasOwnProperty('name')}
              helperText={errors.name || ''}
              label='Name'
              type='text'
              margin='normal'
              name='name'
              value={values.name || ''}
              onChange={handleChange}
            />

            <Textfield
              required
              error={errors.hasOwnProperty('email')}
              helperText={errors.email || ''}
              label='Email'
              type='text'
              margin='normal'
              name='email'
              value={values.email || ''}
              onChange={handleChange}
            />
            <Textfield
              error={errors.hasOwnProperty('phone')}
              helperText={errors.phone || ''}
              label='Phone'
              type='text'
              margin='normal'
              name='phone'
              value={values.phone || ''}
              onChange={handleChange}
            />

            <div style={{ height: '30px' }} />
            <TextareaAutosize
              style={{ minHeight: '300px' }}
              name='comment'
              placeholder='Message to decorator'
              value={values.comment || ''}
              onChange={handleChange}
            />

            <ButtonWithLoading
              style={{ alignSelf: 'center' }}
              isLoading={submiting}
              mt={4}>
              Save
            </ButtonWithLoading>
          </form>
        </CardContent>
        <Snackbar
          open={errorSnack}
          className={classes.errorSnackbar}
          autoHideDuration={2000}
          onClose={() => {
            setSetErrorSnack(false)
          }}>
          <SnackbarContent
            className={classes.errorSnackbarContent}
            message={<span>Failed :(</span>}
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                className={classes.close}
                onClick={() => {
                  setSetErrorSnack(false)
                }}>
                <CloseIcon />
              </IconButton>
            ]}
          />
        </Snackbar>
        <Snackbar
          open={successSnack}
          className={classes.errorSnackbar}
          autoHideDuration={2000}
          onClose={() => {
            setSuccessSnack(false)
          }}>
          <SnackbarContent
            className={classes.succesSnackbarContent}
            message={<span>Saved!</span>}
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                className={classes.close}
                onClick={() => {
                  setSuccessSnack(false)
                }}>
                <CloseIcon />
              </IconButton>
            ]}
          />
        </Snackbar>
      </Card>
    </div>
  )
}

export default CreateOrderForm
