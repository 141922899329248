import { useState, useEffect } from 'react'

const useForm = (callback, validate, initialValues) => {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setValues(initialValues)
  }, [initialValues])

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isSubmitting, values])

  const handleSubmit = (event) => {
    if (event) event.preventDefault()
    setErrors(validate(values))
    setIsSubmitting(true)
  }

  // hax, guess i was tired
  const handleSubmitTwo = (event) => {
    if (event) event.preventDefault()
    setErrors(validate(values))
    callback(values, validate(values))
  }

  const handleChange = (event) => {
    event.persist()
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value
    }))
  }

  const handleChangeByName = (name, value) => {
    setValues((values) => ({ ...values, [name]: value }))
  }

  return {
    handleChange,
    handleChangeByName,
    handleSubmit,
    handleSubmitTwo,
    isSubmitting,
    values,
    errors
  }
}
export default useForm
