import { gql } from 'apollo-boost'

const ORDER = gql`
  query($id: ID!) {
    order: order(id: $id) {
      _id
      title
      description
      status
      createdAt
      updatedAt
      completedAt
      assignedAt
      startedAt
      completedAt
      title
      description
      customerId
      decoratorId
      status
      notes
      adminComments
      desc
      files {
        _id
        origName
        name
        url
        uploadUrl
      }
      customerOrder {
        budget
        tags
        budgetType
        homeVisit
        name
        email
        whatStyle
        whatAppeal
        rooms
        files {
          _id
          origName
          name
          url
        }
        comment
        pinterest
        instagram
        domain
        klarnaId
        discountCode
        phone
        productOption
      }
      wallColors {
        description
        title
        imgSrc
        url
        price
      }
      reachProducts {
        productId
        title
        imgSrc
        url
        quantity
        attributes {
          size
          color
        }
        inStock
        price
        deliveryTime
      }
      manualProducts {
        productId
        title
        imgSrc
        url
        quantity
        vendor
        price
        room
      }
      products {
        productId
        product {
          _id
          name
          url
          price {
            value
            currency
          }
          creatorId
          files {
            origName
            name
            url
          }
        }
        count
      }
      moodboards {
        _id
        origName
        name
        url
        uploadUrl
      }
      floorplans {
        _id
        origName
        name
        url
        uploadUrl
      }
      comments {
        _id
        createdBy
        from
        to
        createdAt
        updatedAt
        text
        emailDelivery {
          status
          timestamp
        }
        readBy
        files {
          _id
          name
          url
        }
      }
      checkout {
        url
        items {
          url
          botUrl
          title
          price
          imgSrc
          inStock
          quantity
          vendor
          deliveryTime
        }
      }
    }
  }
`

const ORDERS = gql`
  query($status: OrderStatus, $searchId: String) {
    orders: orders(status: $status, searchId: $searchId) {
      _id
      title
      description
      status
      createdAt
      updatedAt
      completedAt
      decoratorId
      hasInvoice
      customerOrder {
        name
      }
    }
  }
`
const ORDERS_COUNT = gql`
  query($status: OrderStatus) {
    ordersCount(status: $status) {
      count
    }
  }
`

const FILES_FROM_COMMENT = gql`
  query($orderId: ID!, $commentId: ID!) {
    filesFromComment(orderId: $orderId, commentId: $commentId) {
      _id
      origName
      name
      url
    }
  }
`

const ADD_FILES_TO_ORDER = gql`
  mutation($orderId: ID!, $files: [FileInput!]) {
    addFilesToOrder(orderId: $orderId, files: $files) {
      success
      message
      files {
        origName
        url
        uploadUrl
      }
    }
  }
`

const UPDATE_ORDER = gql`
  mutation($id: ID!, $input: UpdateOrderInput!) {
    updateOrder(id: $id, input: $input) {
      success
      message
    }
  }
`

const ASSIGN_DECORATOR = gql`
  mutation($orderId: ID!, $decoratorId: ID!) {
    assignDecorator(orderId: $orderId, decoratorId: $decoratorId) {
      success
      message
    }
  }
`

const DELETE_FILE_FROM_ORDER = gql`
  mutation($orderId: ID!, $fileId: ID!) {
    deleteFileFromOrder(orderId: $orderId, fileId: $fileId) {
      success
      message
    }
  }
`

const ADD_MOODBOARD = gql`
  mutation($orderId: ID!, $moodboards: [FileInput!]) {
    addMoodboards(orderId: $orderId, moodboards: $moodboards) {
      success
      message
      moodboards {
        _id
        origName
        name
        url
        uploadUrl
      }
    }
  }
`

const DELETE_MOODBOARD = gql`
  mutation($orderId: ID!, $fileId: ID!) {
    deleteMoodboard(orderId: $orderId, fileId: $fileId) {
      success
      message
    }
  }
`
const DELETE_FLOORPLAN = gql`
  mutation($orderId: ID!, $fileId: ID!) {
    deleteFloorplan(orderId: $orderId, fileId: $fileId) {
      success
      message
    }
  }
`

const ADD_FLOORPLANS = gql`
  mutation($orderId: ID!, $floorplans: [FileInput!]) {
    addFloorplans(orderId: $orderId, floorplans: $floorplans) {
      success
      message
      floorplans {
        _id
        origName
        name
        url
        uploadUrl
      }
    }
  }
`

const ADD_PRODUCT_TO_ORDER = gql`
  mutation($orderId: ID!, $productId: ID!, $count: Int!) {
    addProduct(orderId: $orderId, productId: $productId, count: $count) {
      success
      message
    }
  }
`
const ADD_MANUAL_PRODUCT_TO_ORDER = gql`
  mutation(
    $orderId: ID!
    $title: String!
    $price: Int!
    $imgSrc: String!
    $url: String!
    $vendor: String!
    $quantity: String!
    $room: String
  ) {
    addManualProduct(
      orderId: $orderId
      title: $title
      price: $price
      imgSrc: $imgSrc
      url: $url
      vendor: $vendor
      quantity: $quantity
      room: $room
    ) {
      success
      message
    }
  }
`

const ADD_REACH_PRODUCTS_TO_ORDER = gql`
  mutation($orderId: ID!, $products: [ReachProductInput!]) {
    addReachProducts(orderId: $orderId, products: $products) {
      success
      message
    }
  }
`

const ADD_WALL_COLORS_TO_ORDER = gql`
  mutation($orderId: ID!, $wallColors: [WallColorInput!]) {
    addWallColors(orderId: $orderId, wallColors: $wallColors) {
      success
      message
    }
  }
`

const REMOVE_PRODUCT_FROM_ORDER = gql`
  mutation($orderId: ID!, $productId: ID!) {
    removeProduct(orderId: $orderId, productId: $productId) {
      success
      message
    }
  }
`
const REMOVE_REACH_PRODUCT_FROM_ORDER = gql`
  mutation($orderId: ID!, $productId: ID!) {
    removeReachProduct(orderId: $orderId, productId: $productId) {
      success
      message
    }
  }
`

const REMOVE_MANUAL_PRODUCT_FROM_ORDER = gql`
  mutation($orderId: ID!, $productId: ID!) {
    removeManualProduct(orderId: $orderId, productId: $productId) {
      success
      message
    }
  }
`

const ADD_ORDER_COMMENT = gql`
  mutation($orderId: ID!, $input: CreateOrderCommentInput!) {
    addOrderComment(orderId: $orderId, input: $input) {
      success
      message
      comment {
        _id
        createdBy
        from
        to
        createdAt
        updatedAt
        text
        emailDelivery {
          status
          timestamp
        }
        readBy
        files {
          _id
          origName
          name
          url
          uploadUrl
        }
      }
    }
  }
`

const NOTIFY_USER_ON_COMPLETE_FILES_ON_COMMENT = gql`
  mutation($orderId: ID!) {
    notifyUserOnCompleteFilesOnComment(orderId: $orderId) {
      success
      message
    }
  }
`

const SEND_ORDER_PROPOSAL = gql`
  mutation($orderId: ID!) {
    sendOrderProposal(orderId: $orderId) {
      success
      message
    }
  }
`

const SEND_ASK_FLOORPLAN = gql`
  mutation($orderId: ID!) {
    sendAskFloorplan(orderId: $orderId) {
      success
      message
    }
  }
`
const SEND_REQUEST_PICTURES = gql`
  mutation($orderId: ID!) {
    sendAskRequestPictures(orderId: $orderId) {
      success
      message
    }
  }
`
const SET_ORDER_COMPLETE = gql`
  mutation($orderId: ID!) {
    setOrderComplete(orderId: $orderId) {
      success
      message
    }
  }
`
const CREATE_INVOICE = gql`
  mutation($orderIds: [ID]!, $invoiceNumber: String!) {
    createInvoice(orderIds: $orderIds, invoiceNumber: $invoiceNumber) {
      success
      message
    }
  }
`

const COMMENTS_CHANGED = gql`
  subscription onCommentsChanged($orderId: ID!) {
    commentsChanged(orderId: $orderId) {
      _id
      createdBy
      from
      to
      createdAt
      updatedAt
      text
      emailDelivery {
        status
        timestamp
      }
      readBy
      files {
        _id
        name
        url
      }
    }
  }
`

const ARCHIVE_ORDER = gql`
  mutation($id: ID!) {
    archiveOrder(id: $id) {
      success
      message
    }
  }
`

const GET_PREVIEW_URL = gql`
  mutation($orderId: String!) {
    getPreviewUrl(orderId: $orderId) {
      success
      message
      url
    }
  }
`

const UPDATE_CUSTOMER_ORDER = gql`
  mutation($orderId: String!, $email: String!, $phone: String) {
    updateCustomerOrder(orderId: $orderId, email: $email, phone: $phone) {
      success
      message
    }
  }
`
const CREATE_ORDER_ADMIN = gql`
  mutation($input: OrderInputAdmin!) {
    createOrderAdmin(input: $input) {
      success
    }
  }
`

export {
  ORDER,
  ORDERS_COUNT,
  ORDERS,
  FILES_FROM_COMMENT,
  UPDATE_ORDER,
  ARCHIVE_ORDER,
  ASSIGN_DECORATOR,
  DELETE_MOODBOARD,
  ADD_FILES_TO_ORDER,
  DELETE_FILE_FROM_ORDER,
  ADD_MOODBOARD,
  ADD_PRODUCT_TO_ORDER,
  ADD_REACH_PRODUCTS_TO_ORDER,
  REMOVE_PRODUCT_FROM_ORDER,
  ADD_ORDER_COMMENT,
  REMOVE_REACH_PRODUCT_FROM_ORDER,
  COMMENTS_CHANGED,
  SEND_ORDER_PROPOSAL,
  ADD_FLOORPLANS,
  DELETE_FLOORPLAN,
  SET_ORDER_COMPLETE,
  SEND_ASK_FLOORPLAN,
  ADD_WALL_COLORS_TO_ORDER,
  NOTIFY_USER_ON_COMPLETE_FILES_ON_COMMENT,
  CREATE_INVOICE,
  GET_PREVIEW_URL,
  UPDATE_CUSTOMER_ORDER,
  ADD_MANUAL_PRODUCT_TO_ORDER,
  REMOVE_MANUAL_PRODUCT_FROM_ORDER,
  SEND_REQUEST_PICTURES,
  CREATE_ORDER_ADMIN
}
