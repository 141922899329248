const userType = {
  ADMIN: 'Admin',
  MASTER_ADMIN: 'MasterAdmin',
  DECORATOR: 'Decorator',
  CUSTOMER: 'Customer'
}

const isAdmin = (type) =>
  type === userType.ADMIN || type === userType.MASTER_ADMIN
const isMasterAdmin = (type) => type === userType.MASTER_ADMIN
const isDecorator = (type) => type === userType.DECORATOR
const isCustomer = (type) => type === userType.CUSTOMER

export { userType, isAdmin, isMasterAdmin, isDecorator, isCustomer }
