import React from 'react'
import { makeStyles } from '@material-ui/styles'
import FileContainer from '../../../components/FileContainer'
import AddFilesButton from '../../../components/AddFilesButton'
import { PaperTitle } from '../../../components/sharable/PaperTitle'
import { Flex } from '../../../components/primitives'

const useStyles = makeStyles((theme) => {
  return {
    header: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  }
})

function OrderFiles({
  files,
  working,
  onAddFiles,
  onFileDelete,
  ...otherProps
}) {
  const classes = useStyles()
  return (
    <div>
      <Flex mb={4} className={classes.header}>
        <PaperTitle title={'FILES'} />
        <AddFilesButton onAddFiles={onAddFiles} />
      </Flex>
      <FileContainer
        busy={working}
        multiple
        files={files || []}
        onAddFiles={onAddFiles}
        onDeleteFile={onFileDelete}
      />
    </div>
  )
}

export default OrderFiles
