import React, { useState, useRef } from 'react'
import { Button, Flex, Text } from '../../../components/primitives'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { WALL_COLORS } from '../../../gql/reach'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PaperTitle } from '../../../components/sharable'
import { TextWithClick } from '../../../components/sharable/text/TextWithClick'
import { ADD_WALL_COLORS_TO_ORDER } from '../../../gql/orders'
import { ButtonWithLoading } from '../../../components/sharable/button'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { useDebouncedCallback } from 'use-debounce'
import { Pages } from '../../../components/sharable/pagination/Pagination'
import { ColorCard } from '../../customerPage/components/ColorCard'

export const ColorPicker = ({ orderId, initialColors = [] }) => {
  const categoriesRef = useRef()
  const [page, setPage] = useState(1)
  const [debouncedSearchChange] = useDebouncedCallback((searchText) => {
    setPage(1)
    setSearchText(searchText.toLowerCase())
  }, 500)
  const [searchText, setSearchText] = useState()

  const [addWallColors] = useMutation(ADD_WALL_COLORS_TO_ORDER)
  const [selectedColors, setSelectedColors] = useState(initialColors)
  const [selected, setSelected] = useState()
  const [category, setCategory] = useState(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const { data, loading } = useQuery(WALL_COLORS, {
    variables: { page: page, freetext: searchText, category: category }
  })
  const colors = data && data.wallColors ? data.wallColors.products : []
  if (!categoriesRef.current && data && data.wallColors) {
    categoriesRef.current = data.wallColors.categories
  }
  const categories = categoriesRef.current ? categoriesRef.current : []

  const sortedColors = colors //sortColors(colors)

  return (
    <Flex flexDirection={'column'}>
      <PaperTitle title={'Wall colors'} />

      <Flex maxWidth={'300px'}>
        <InputBase
          fullWidth
          placeholder='Search colors'
          inputProps={{ 'aria-label': 'Search colors' }}
          onChange={(e) => debouncedSearchChange(e.target.value)}
        />
        <IconButton aria-label='Search'>
          <SearchIcon />
        </IconButton>
      </Flex>

      <TextWithClick
        fontSize={3}
        mb={2}
        mt={2}
        onClick={() => {
          setCategory(null)
        }}>
        Categories (Show all)
      </TextWithClick>

      <Flex flexWrap={'wrap'}>
        {categories.map((c, index) => {
          return (
            <TextWithClick
              key={index}
              onClick={() => {
                setCategory(c)
              }}
              mb={2}
              mr={2}>
              {c}
            </TextWithClick>
          )
        })}

        <Flex width={'100%'} jusifyContent={'center'}>
          {data && sortedColors.length > 0 && (
            <Pages
              onChangePage={(page) => {
                setPage(page)
              }}
              limit={Number(data.wallColors.pageSize)}
              total={Number(data.wallColors.total)}
            />
          )}
        </Flex>
      </Flex>

      {selected && (
        <Flex>
          <Flex
            style={{ cursor: 'pointer' }}
            height={'60px'}
            width={'60px'}
            bg={selected.description}
          />
          <Flex m={2} flexDirection={'column'}>
            <Text>{selected.title}</Text>
            <TextWithClick
              onClick={() => {
                window.open(selected.variants[0].urls[0], '_blank')
              }}>
              More info
            </TextWithClick>
          </Flex>
          <Flex height={'fit-content'}>
            <Button
              onClick={() => {
                if (selectedColors && selectedColors.length) {
                  setSelectedColors([...selectedColors, selected])
                } else {
                  setSelectedColors([selected])
                }

                setSelected(null)
              }}>
              Select
            </Button>
          </Flex>
        </Flex>
      )}

      {loading && (
        <Flex width={'40px'} height={'40px'}>
          <CircularProgress />
        </Flex>
      )}
      {!loading && (
        <Flex mt={2} flexWrap={'wrap'}>
          {sortedColors &&
            sortedColors.map((color) => {
              if (color.locales) {
                return color.locales.map((locale) => {
                  return (
                    locale.variants &&
                    locale.variants.map((variant, index) => {
                      return (
                        <Flex
                          onClick={() => {
                            console.log(color)
                            setSelected({
                              url: variant.urls[0],
                              imgSrc: locale.images[0],
                              title: locale.title,
                              price: variant.price,
                              description: locale.description
                            })
                          }}
                          style={{ cursor: 'pointer' }}
                          key={index}
                          height={'30px'}
                          width={'30px'}
                          bg={locale.description}
                        />
                      )
                    })
                  )
                })
              }
              return null
            })}
        </Flex>
      )}
      <Text mt={2}>Selected colors</Text>
      <Flex mt={2} flexWrap={'wrap'}>
        {selectedColors &&
          selectedColors.map((locale, index) => {
            return (
              <ColorCard
                onRemove={() => {
                  const filter = selectedColors.filter(
                    (c) => c.description !== locale.description
                  )
                  setSelectedColors(filter)
                }}
                key={index}
                title={locale.title}
                color={locale.description}
                url={locale.url}
              />
            )
          })}
      </Flex>
      {selectedColors && (
        <Flex mt={2}>
          <ButtonWithLoading
            isLoading={saveLoading}
            onClick={() => {
              console.log(selectedColors)
              setSaveLoading(true)
              addWallColors({
                variables: {
                  orderId: orderId,
                  wallColors: selectedColors.map((color) => {
                    return {
                      url: color.url,
                      imgSrc: color.imgSrc,
                      title: color.title,
                      price: color.price,
                      description: color.description
                    }
                  })
                },
                update: (proxy, mutationResult) => {
                  console.log(mutationResult)
                  if (!mutationResult.data.addWallColors.success) {
                    alert('Something went wrrong')
                  }

                  setSaveLoading(false)
                }
              })
            }}>
            Save
          </ButtonWithLoading>
        </Flex>
      )}
    </Flex>
  )
}
