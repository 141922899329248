import React from 'react'
import { Flex } from '../../../components/primitives'
import { FlexWithCorner } from '../../../components/sharable/flex/FlexWithCorner'
import CheckCircle from '@material-ui/icons/CheckCircle'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'

export const ProductHolder = ({
  children,
  selected,
  onClick,
  width = '185px',
  height = '338px',
  p = 4,
  showAdd = true
}) => {
  return (
    <FlexWithCorner
      className={'flexWithCorner print-remove-box-shadow '}
      onClick={onClick}
      selected={selected}
      alignItems={'center'}
      width={width}
      height={height}
      bg={'white'}
      p={p}
      m={2}
      flexDirection={'column'}
      style={{
        boxShadow: '0 2px 5px 2px rgba(192,191,191,0.5)',
        borderRadius: '4px',
        cursor: 'pointer'
      }}>
      {children}
      {selected ? (
        <Flex
          data-html2canvas-ignore='true'
          color={'white'}
          style={{
            right: '5px',
            bottom: '8px',
            position: 'absolute',
            zIndex: 12
          }}
          width={'100%'}
          jusifyContent={'flex-end'}>
          <CheckCircle style={{ fontSize: '20px' }} />
        </Flex>
      ) : (
        showAdd && (
          <Flex
            data-html2canvas-ignore='true'
            color={'lightGrey'}
            style={{
              right: '5px',
              bottom: '8px',
              position: 'absolute',
              zIndex: 12
            }}
            width={'100%'}
            jusifyContent={'flex-end'}>
            <AddCircleOutline style={{ fontSize: '20px' }} />
          </Flex>
        )
      )}
    </FlexWithCorner>
  )
}
