import { gql } from 'apollo-boost'

const DECORATOR_APPLICATIONS = gql`
  query {
    decoratorApplications: decoratorApplications {
      _id
      createdAt
      name
      email
      phone
      website
      yearsOfExp
      education
      files {
        origName
        name
        url
        uploadUrl
      }
    }
  }
`

const CREATE_DECORATOR_APPLICATION = gql`
  mutation($input: CreateDecoratorApplicationInput!) {
    createDecoratorApplication(input: $input) {
      success
      message
      application {
        _id
        createdAt
        name
        email
        phone
        website
        yearsOfExp
        education
        files {
          origName
          name
          url
          uploadUrl
        }
      }
    }
  }
`

const CREATE_DECORATOR = gql`
  mutation($input: CreateDecoratorInput!) {
    createDecorator(input: $input) {
      success
      message
    }
  }
`

const UPDATE_DECORATOR = gql`
  mutation($id: ID!, $input: UpdateDecoratorInput!) {
    updateDecorator(id: $id, input: $input) {
      success
      message
    }
  }
`
const ACCEPT_DECORATOR = gql`
  mutation($id: String!) {
    acceptDecoratorApplication(id: $id) {
      success
      message
    }
  }
`
const DECLINE_DECORATOR = gql`
  mutation($id: String!) {
    declineDecoratorApplication(id: $id) {
      success
      message
    }
  }
`

export {
  ACCEPT_DECORATOR,
  DECLINE_DECORATOR,
  DECORATOR_APPLICATIONS,
  CREATE_DECORATOR_APPLICATION,
  CREATE_DECORATOR,
  UPDATE_DECORATOR
}
