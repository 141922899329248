import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useQuery } from '@apollo/react-hooks'
import { LIST_USERS } from '../../gql/auth'
import Box from '@material-ui/core/Box'
import { branch, compose, renderComponent } from 'recompose'
import ErrorCard from '../../components/ErrorCard'
import CircularProgress from '@material-ui/core/CircularProgress'
import withMobileDialog from '@material-ui/core/withMobileDialog/withMobileDialog'
import { UserList } from './components/Userlist'

const useStyles = makeStyles((theme) => {
  return {
    addAdminButton: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
    loadingProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    errorContainer: {
      maxWidth: 400,
      marginTop: theme.spacing(3),
      textAlign: 'center',
      margin: '0 auto',
      padding: 10
    }
  }
})

const queryParams = {
  variables: {
    types: ['Customer']
  },  fetchPolicy: 'no-cache'

}

const CustomerListPage = ({ data }) => {
  return (
    <Box>
      <UserList
        users={data && data.users ? data.users : []}
        isCustomer={true}
        onEdit={() => {}}
        onDelete={() => {}}
      />
    </Box>
  )
}

const withRequest = (BaseComponent) => (props) => {
  const { data, error, loading, refetch } = useQuery(LIST_USERS, queryParams)
  return (
    <BaseComponent
      {...props}
      data={data}
      loading={loading}
      error={error}
      refetch={refetch}
    />
  )
}

const withError = branch(
  ({ error }) => error,
  renderComponent(() => {
    return <ErrorCard message={'Error Loading Decorator list'} />
  })
)

const withLoading = branch(
  ({ loading }) => loading,
  renderComponent(() => {
    const classes = useStyles()
    return <CircularProgress className={classes.loadingProgress} />
  })
)

export default compose(
  withRequest,
  withLoading,
  withError,
  withMobileDialog()
)(CustomerListPage)
