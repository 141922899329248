import styled from 'styled-components'
import paper from '@material-ui/core/Paper'
import { space, height, width, color } from 'styled-system'

export const Holder = styled(paper)`
 ${space}
 ${width}
 ${height}
 ${color}
 box-shadow:none
`

Holder.defaultProps = {
  bg: 'white',
  p: 2,
  mt: 2,
  width: '100%'
}
