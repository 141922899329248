import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Switch, Redirect, Link, Route } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { Flex } from '../../components/primitives'
import AdminPage from './AdminPage'
import DecoratorPage from './DecoratorPage'
import CustomerListPage from './CustomerListPage'

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    backgroundColor: theme.palette.primary.dark
  }
}))(Tabs)

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: `1px solid ${theme.palette.primary.dark}`,
      opacity: 1
    },
    '&$selected': {
      color: theme.palette.primary.dark,
      fontWeight: 'bold'
    },
    '&:focus': {
      color: `1px solid ${theme.palette.primary.dark}`
    }
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />)

const getInitialTabIndex = (route = '') => {
  const paths = route.split('/')
  let matchedRoute
  if (paths.length > 2) {
    matchedRoute = paths[2]
  }
  if (matchedRoute === 'decorators') return 1
  if (matchedRoute === 'customers') return 2
  return 0
}

function UserPage(props) {
  const [value, setValue] = React.useState(
    getInitialTabIndex(props.location.pathname) || 0
  )

  function handleChange(event, newValue) {
    setValue(newValue)
  }

  return (
    <Container style={{ padding: '0px' }}>
      <Flex bg={'white'} mt={2} mb={2}>
        <AntTabs
          style={{ width: '100%' }}
          value={value}
          onChange={handleChange}>
          <AntTab label='Admins' component={Link} to='/users/admins' />
          <AntTab label='Decorators' component={Link} to='/users/decorators' />
          <AntTab label='Customers' component={Link} to='/users/customers' />
        </AntTabs>
      </Flex>
      <Switch>
        <Route path='/users/admins' component={AdminPage} />
        <Route path='/users/decorators' component={DecoratorPage} />
        <Route path='/users/customers' component={CustomerListPage} />
        <Redirect from='/' to='/users/admins' />
      </Switch>
    </Container>
  )
}

export default UserPage
