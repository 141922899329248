import { Image as ReImage } from 'rebass'
import styled from 'styled-components'

export const ImageCover = styled(ReImage)`
  object-fit: cover;
  height: ${(props) => props.height};
`

export const ImageContain = styled(ReImage)`
  object-fit: contain;
  height: ${(props) => props.height};
`
