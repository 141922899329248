import { useQuery } from '@apollo/react-hooks'
import { ORDERS_COUNT } from '../gql/orders'

export const useGetOrderCount = () => {
  const { data: created } = useQuery(ORDERS_COUNT, {
    variables: { status: 'created' }
  })
  const { data: assigned } = useQuery(ORDERS_COUNT, {
    variables: { status: 'assigned' }
  })
  const { data: sent } = useQuery(ORDERS_COUNT, {
    variables: { status: 'sent' }
  })
  const { data: completed } = useQuery(ORDERS_COUNT, {
    variables: { status: 'completed' }
  })

  return {
    createdCount:
      created && created.ordersCount ? created.ordersCount.count : 0,
    assignedCount:
      assigned && assigned.ordersCount ? assigned.ordersCount.count : 0,
    sendCount: sent && sent.ordersCount ? sent.ordersCount.count : 0,
    completedCount:
      completed && completed.ordersCount ? completed.ordersCount.count : 0
  }
}
