import styled from 'styled-components'
import { Button as REButton } from 'rebass'
import { colors } from '../../modules/colors'
import { lighten } from 'polished'

const Button = styled(REButton)`
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  color: ${colors.white}
  bottom: ${(props) => props.bottom};
  border-radius: 5px;
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  cursor: pointer;
  &:hover {
    background-color: ${lighten(0.05, colors.primary)};
  }
  &:focus {
    outline: none;
  }
`

Button.defaultProps = {
  bg: 'primary'
}

export { Button }
