import React from 'react'
import { createAwinUrl } from '../../../modules/util'
import { Flex, Text } from '../../../components/primitives'
import { ImageContain } from '../../../components/primitives/ImageCover'

function CustomerProducts({ products, budget }) {
  function ccyFormat(num) {
    return `${num.toFixed(0)}`
  }

  function priceRow(qty, unit) {
    return qty * unit
  }

  function createRow(name, qty, unit, id, fileUrl, url) {
    const price = priceRow(qty, unit)
    return { name, qty, unit, price, id, fileUrl, url }
  }

  function total(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0)
  }

  function createRows() {
    if (!products) {
      return []
    }
    return products.map((p) =>
      createRow(
        p.product.name,
        p.count,
        p.product.price.value,
        p.productId,
        p.product.files ? p.product.files[0].url : '',
        p.product.url
      )
    )
  }

  const rows = createRows()
  return (
    <Flex flexDirection={'column'}>
      <Flex
        style={{ flexWrap: 'wrap' }}
        jusifyContent={'center'}
        width={'100%'}
        pr={2}
        pl={2}>
        {rows.length > 0 &&
          rows.map((product) => (
            <Flex
              m={2}
              style={{
                boxShadow: '0 2px 5px 2px rgba(192,191,191,0.5)',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
              key={product.id}
              width={'185px'}
              height={'338px'}
              bg={'white'}
              p={4}
              onClick={() => {
                window.open(createAwinUrl(product.url), '_blank')
              }}
              flexDirection={'column'}>
              <Flex flex={1} flexDirection={'column'}>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={product.fileUrl ? product.fileUrl : ''}>
                  <ImageContain
                    height={'140px'}
                    width={'100%'}
                    mb={4}
                    src={product.fileUrl ? product.fileUrl : ''}
                  />
                </a>
                <Flex
                  flexDirection={'column'}
                  jusifyContent={'space-between'}
                  flex={1}>
                  <Text
                    fontSize={3}
                    color={'primary'}
                    textAlign={'center'}
                    fontWeight={'bold'}>
                    {product.name}
                  </Text>

                  <Text textAlign={'center'}>
                    {product.qty} x {ccyFormat(product.unit)}
                  </Text>

                  <Flex height={'2px'} bg={'secondary'} width={'100%'}></Flex>

                  <Text fontWeight={'bold'} textAlign={'center'}>
                    Total: {ccyFormat(product.price)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          ))}
      </Flex>
      <Text
        mt={4}
        textAlign={'center'}
        fontWeight={'bold'}
        fontSize={'1.375rem'}>
        Total: {ccyFormat(total(rows))}
      </Text>
      <Text mt={2} textAlign={'center'} fontSize={'1.375rem'}>
        Budget: {ccyFormat(budget)}
      </Text>
    </Flex>
  )
}

export default CustomerProducts
