import React from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import GenerateIcon from '@material-ui/icons/Cached'
import generator from 'generate-password'

import useForm from '../../hooks/useForm'

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: '400px'
  },
  passwordInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  errorSnackbar: {
    position: 'relative',
    marginTop: theme.spacing(4)
  },
  errorSnackbarContent: {
    backgroundColor: theme.palette.error.dark
  },
  iconSmall: {
    fontSize: 20
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}))

const generatePassword = () => {
  return generator.generate({
    length: 8,
    numbers: true
  })
}

const validate = values => {
  let errors = {}
  if (!values.password) {
    errors.password = 'Password is required'
  } else if (values.password.length < 8) {
    errors.password = 'Password cannot be empty or less than 8 characters'
  }
  return errors
}

function EditAdminForm (props) {
  const classes = useStyles(props)
  const { onEdit, showErrorSnack, onErrorSnackClose } = props
  const initialValues = {
    password: generatePassword()
  }
  const { values, errors, handleChange, handleSubmit } = useForm(
    onEdit,
    validate,
    initialValues
  )

  return (
    <Box className={classes.container}>
      <form id='edit-admin-form' onSubmit={handleSubmit}>
        <Box className={classes.passwordInputWrapper}>
          <TextField
            fullWidth
            required
            error={errors.hasOwnProperty('password')}
            helperText={errors.password || ''}
            label='Password'
            type='text'
            autoComplete='current-password'
            margin='normal'
            name='password'
            value={values.password}
            onChange={handleChange}
          />
          <IconButton className={classes.button} aria-label='Generate' disabled>
            <GenerateIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
          </IconButton>
        </Box>

        <Snackbar
          open={showErrorSnack}
          className={classes.errorSnackbar}
          autoHideDuration={2000}
          onClose={onErrorSnackClose}
        >
          <SnackbarContent
            className={classes.errorSnackbarContent}
            message={<span>Update failed</span>}
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                className={classes.close}
                onClick={onErrorSnackClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </Snackbar>
      </form>
    </Box>
  )
}

export default EditAdminForm
