import React, { useRef } from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import grey from '@material-ui/core/colors/grey'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import NoteAddIcon from '@material-ui/icons/NoteAddOutlined'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => {
  return {
    header: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    progress: {
      width: '100%',
      marginTop: theme.spacing(1)
    },
    dropIcon: {
      width: '2.5rem',
      height: '2.5rem'
    },
    image: ({ multiple }) =>
      multiple
        ? {
            height: 200,
            objectFit: 'contain'
          }
        : {
            height: '50vh',
            width: '100%',
            objectFit: 'contain'
          },
    fileItem: {
      position: 'relative',
      '&:hover': {
        '& $deleteIcon': {
          display: 'inherit',
          backgroundColor: 'white'
        }
      }
    },
    deleteIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
      color: theme.palette.primary.dark,
      backgroundColor: 'white',
      display: 'none'
    }
  }
})

function FileContainer({
  files,
  busy,
  multiple,
  onAddFiles,
  onDeleteFile,
  ...otherProps
}) {
  const classes = useStyles({ multiple })
  const theme = useTheme()
  const fileInputRef = useRef()

  const handleClickDelete = (e) => {
    onDeleteFile && onDeleteFile(e.currentTarget.dataset.file)
  }

  const addFiles = (toAdd) => {
    if (toAdd && toAdd.length > 0) {
      const newFiles = []
      for (let i = 0; i < toAdd.length; i++) {
        newFiles.push(toAdd[i])
      }
      onAddFiles && onAddFiles(newFiles)
    }
  }

  const handleAddFiles = (e) => {
    e.stopPropagation()
    addFiles(e.target.files)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'copy'
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'copy'
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    addFiles(e.dataTransfer.files)
  }

  const handleClick = () => {
    fileInputRef.current.click()
  }

  const getFileElement = (f) => {
    const imageFormats = ['jpg', 'jpeg', 'png']
    const extension = f.name.split('.').pop()
    const url = f.url ? f.url : window.URL.createObjectURL(f)
    if (imageFormats.includes(extension)) {
      return (
        <img
          src={url}
          alt={f.origName || f.name}
          className={classes.image}
          onLoad={!f.url ? () => window.URL.revokeObjectURL(url) : undefined}
        />
      )
    }
    return f.origName || f.name
  }

  const hasFiles = files && files.length > 0

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'>
      <Grid container spacing={1}>
        {hasFiles &&
          files.map((f, index) => (
            <Grid item key={index} className={classes.fileItem}>
              <a href={f.url} target='_blank' rel='noopener noreferrer'>
                {getFileElement(f)}
              </a>
              <IconButton
                data-file={f._id || f.name}
                size='small'
                className={classes.deleteIcon}
                onClick={handleClickDelete}>
                <DeleteForeverIcon />
              </IconButton>
            </Grid>
          ))}
      </Grid>
      {!hasFiles && (
        <Box
          padding={1}
          display='flex'
          justifyContent='center'
          alignItems='center'
          color={grey[500]}
          bgcolor={grey[200]}
          borderRadius={theme.spacing(1)}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDrop={handleDrop}
          onClick={handleClick}>
          <NoteAddIcon className={classes.dropIcon} />
          <Typography variant='caption'>{`Drop ${
            multiple ? 'files' : 'a file'
          } here or click the button`}</Typography>
        </Box>
      )}
      {busy && <LinearProgress className={classes.progress} />}
      <input
        ref={fileInputRef}
        type='file'
        name='files'
        multiple={multiple ? true : undefined}
        style={{ display: 'none' }}
        onChange={handleAddFiles}
      />
    </Box>
  )
}

export default FileContainer
