import React, { useState } from 'react'
import { Flex, Textfield, Text, Button } from '../../components/primitives'
import {
  Holder,
  PaperTitle,
  FlexWithHover,
  ButtonWithLoading,
  OrderIdTitle
} from '../../components/sharable'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { CREATE_INVOICE, ORDERS } from '../../gql/orders'
import { INVOICES, SEND_INVOICE } from '../../gql/invoices'
import { getDateString } from '../../modules/util'
import CircularProgress from '@material-ui/core/CircularProgress'

const InvoicePage = (props) => {
  const [selectedOrders, setSelectedOrders] = useState([])
  const [createInvoiceLoading, setCreateInvoiceLoading] = useState(false)
  const [sendInvoiceLoading, setSendInvoiceLoading] = useState(false)
  const [invoiceNumber, setInvoiceNumber] = useState(false)

  const {
    loading: loadingOrders,
    data: dataOrders,
    refetch: refetchOrders
  } = useQuery(ORDERS, { variables: { status: 'completed' } })
  const orders = dataOrders ? dataOrders.orders : []
  const {
    loading: loadingInvoices,
    data: invoicesData,
    refetch: refetchInvoices
  } = useQuery(INVOICES, {})
  const invoices = invoicesData ? invoicesData.orders : []

  const [createInvoice] = useMutation(CREATE_INVOICE)
  const [sendInvoice] = useMutation(SEND_INVOICE)

  const addOrder = (order) => {
    const exist = selectedOrders.find((o) => o._id === order._id)
    if (!exist) {
      const b = selectedOrders
      b.push(order)
      setSelectedOrders([...b])
    }
  }

  const removeOrder = (order) => {
    const remaning = selectedOrders.filter((o) => o._id !== order._id)
    setSelectedOrders([...remaning])
  }

  const createInvoiceFromOrders = async () => {
    if (selectedOrders && selectedOrders.length > 0 && invoiceNumber) {
      setCreateInvoiceLoading(true)

      const ids = selectedOrders.map((o) => o._id)

      createInvoice({
        variables: {
          orderIds: ids,
          invoiceNumber: invoiceNumber
        },
        update: (proxy, result) => {
          if (result.data.createInvoice.success) {
            setSelectedOrders([])
            refetchOrders()
            refetchInvoices()
            setCreateInvoiceLoading(false)
            setInvoiceNumber('')
          } else {
            alert('Could not create invoice')
          }
        }
      })
    }
  }

  const sendInvoiceWithId = (id) => {
    setSendInvoiceLoading(true)

    sendInvoice({
      variables: {
        invoiceId: id
      },
      update: (proxy, result) => {
        console.log(result)
        setSendInvoiceLoading(false)
        refetchInvoices()
      }
    })
  }

  return (
    <Flex pr={[0, 2]} pl={[0, 2]} flexDirection={'column'}>
      <Holder>
        <PaperTitle title={'Create invoice'} />
        <Text fontSize={3}>
          Choose all the orders you want on the invoice and click create
        </Text>
      </Holder>
      <Holder>
        <PaperTitle title={'Orders'} />
        {loadingOrders ? (
          <Flex mt={2}>
            <CircularProgress />
          </Flex>
        ) : (
          orders &&
          orders.map((order) => {
            if (!order.hasInvoice) {
              return (
                <FlexWithHover
                  key={order._id}
                  onClick={() => {
                    addOrder(order)
                  }}
                  mt={2}
                  mb={2}
                  height={'50px'}
                  alignItems={'center'}>
                  <OrderIdTitle title={order._id} ml={2} mr={4}></OrderIdTitle>
                  <Text> {getDateString(new Date(+order.createdAt))}</Text>
                </FlexWithHover>
              )
            } else {
              return null
            }
          })
        )}
      </Holder>

      <Holder>
        <PaperTitle title={'Selected orders'} />
        {selectedOrders.map((order) => {
          return (
            <FlexWithHover
              key={order._id}
              onClick={() => {
                removeOrder(order)
              }}
              mt={2}
              mb={2}
              height={'50px'}
              alignItems={'center'}>
              <Text ml={2} mr={4}>
                {order._id.slice(-5)}
              </Text>
              <Text> {getDateString(new Date(+order.createdAt))}</Text>
            </FlexWithHover>
          )
        })}
        <Flex flex={1} jusifyContent={'flex-end'}>
          <Text mr={2}>Invoice number</Text>{' '}
          <Textfield
            value={invoiceNumber || ''}
            onChange={(event) => {
              event.persist()
              setInvoiceNumber(event.target.value)
            }}
            style={{ width: '75px' }}
          />
          <ButtonWithLoading
            ml={2}
            onClick={createInvoiceFromOrders}
            isLoading={createInvoiceLoading}>
            Create
          </ButtonWithLoading>
        </Flex>
      </Holder>
      <Holder>
        <PaperTitle title={'Invoices'} />
        {loadingInvoices ? (
          <Flex mt={2}>
            <CircularProgress />
          </Flex>
        ) : (
          invoices &&
          invoices.map((invoice) => {
            return (
              <Flex
                key={invoice._id}
                mt={2}
                mb={2}
                height={'50px'}
                alignItems={'center'}>
                <Text ml={2} mr={4}>
                  {invoice._id.slice(-5)}
                </Text>
                <Text> {getDateString(new Date(+invoice.createdAt))}</Text>

                <Flex flex={1} jusifyContent={'flex-end'}>
                  <Button
                    mr={2}
                    onClick={() => {
                      const win = window.open(invoice.url, '_blank')
                      win.focus()
                    }}>
                    Open
                  </Button>
                  {!invoice.sent && (
                    <ButtonWithLoading
                      onClick={() => {
                        sendInvoiceWithId(invoice._id)
                      }}
                      isLoading={sendInvoiceLoading}
                      mr={2}>
                      Send
                    </ButtonWithLoading>
                  )}
                </Flex>
              </Flex>
            )
          })
        )}
      </Holder>
    </Flex>
  )
}

export default InvoicePage
