import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Tooltip from '@material-ui/core/Tooltip'
import EmailIcon from '@material-ui/icons/Email'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles(theme => {
  return {
    root: {
      position: 'relative'
    },
    email: {
      color: 'rgba(0,0,0,0.3)'
    },
    error: {
      color: 'red',
      width: '15px',
      height: '15px'
    },
    pending: {
      color: 'lightgrey',
      width: '15px',
      height: '15px'
    },
    opened: {
      color: 'green',
      width: '15px',
      height: '15px'
    },
    statusIcon: {
      borderRadius: '50%',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '17px',
      height: '17px',
      position: 'absolute',
      bottom: '0',
      right: '0',
      transform: 'translate(20%, -20%)'
    }
  }
})

function CommentStatus ({ status }) {
  const classes = useStyles()

  const getStatusIcon = () => {
    if (['open', 'clicked'].includes(status)) {
      return <CheckCircleIcon className={classes.opened} />
    }
    if (['bounce', 'dropped'].includes(status)) {
      return <ErrorIcon className={classes.error} />
    }
    return <CheckCircleIcon className={classes.pending} />
  }

  return <Tooltip title={status || 'pending'} aria-label='Comment status'>
    <div className={classes.root}>
      <EmailIcon className={classes.email} />
      <div className={classes.statusIcon}>
        { getStatusIcon() }
      </div>
    </div>
  </Tooltip>
}

export default CommentStatus
