import React, { useState } from 'react'
import useForm from '../../hooks/useForm'
import { Flex, Text, Textfield } from '../../components/primitives'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { makeStyles } from '@material-ui/styles'
import logo from '../../assets/images/hemset-horizontal.png'
import { ButtonWithLoading } from '../../components/sharable/button'
import AddFilesButton from '../../components/AddFilesButton'
import FileContainer from '../../components/FileContainer'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: '100%'
  },
  loginButton: {
    color: '#ffffff'
  },
  card: {
    padding: theme.spacing(4)
  },
  loginButtonWrapper: {
    marginTop: theme.spacing(4)
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    marginRight: 10
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    height: 48
  },
  errorSnackbar: {
    position: 'relative',
    marginTop: theme.spacing(4)
  },
  errorSnackbarContent: {
    backgroundColor: theme.palette.error.dark
  }
}))

const validate = (values) => {
  let errors = {}
  if (!values.name) {
    errors.name = 'name is required'
  }
  if (!values.phone) {
    errors.phone = 'Phone is required'
  }
  if (!values.yearsOfExp) {
    errors.yearsOfExp = 'Years of experience is required'
  }
  if (!values.education) {
    errors.education = 'Relevant education of experience is required'
  }
  if (!values.email) {
    errors.email = 'Email address is required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid'
  }
  if (!values.password) {
    errors.password = 'Password is required'
  } else if (values.password.length < 1) {
    errors.password = 'Password cannot be empty'
  }
  return errors
}

function Logo(props) {
  const classes = useStyles()
  return (
    <div className={classes.logoWrapper}>
      <img src={logo} className={classes.logo} alt='Logo' />
      <Text mt={2}>Apply to be a decorator</Text>
    </div>
  )
}

const FormHeader = Logo

function ApplyForm({
  onSubmit,
  success,
  formSubmitting,
  showErrorSnack,
  onErrorSnackClose,
  termsClick
}) {
  const [files, setFiles] = useState([])

  const { values, errors, handleChange, handleSubmitTwo } = useForm(
    (vals, er) => {
      if (Object.keys(er).length === 0) {
        console.log(vals)
        if (files.length === 0 && !vals.website) {
          alert('Must fill in website or add files')
        } else {
          onSubmit({ values: vals, files: files })
        }
      }
    },
    validate
  )
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardHeader component={FormHeader} />
      <CardContent>
        {success ? (
          <Flex>
            <Text>
              Tack för ditt intresse och ansökan om att bli inredare hos Hemset!
              Vi återkommer till dig så snart som vi tittat på det.
              <br />
              <br />
              Vänliga hälsningar
              <br />
              <br />
              Hemset
            </Text>
          </Flex>
        ) : (
          <Flex width={['100%', '500px']}>
            <form
              onSubmit={handleSubmitTwo}
              className={classes.container}
              noValidate
              autoComplete='off'>
              <Textfield
                required
                error={errors.hasOwnProperty('name')}
                helperText={errors.name || ''}
                label='Name'
                type='text'
                margin='normal'
                name='name'
                value={values ? values.name : ''}
                onChange={handleChange}
              />

              <Textfield
                required
                error={errors.hasOwnProperty('email')}
                helperText={errors.email || ''}
                label='Email'
                type='text'
                margin='normal'
                name='email'
                value={values ? values.email : ''}
                onChange={handleChange}
              />
              <Textfield
                required
                error={errors.hasOwnProperty('phone')}
                helperText={errors.phone || ''}
                label='Phone'
                type='text'
                margin='normal'
                name='phone'
                value={values ? values.phone : ''}
                onChange={handleChange}
              />
              <Textfield
                required
                error={errors.hasOwnProperty('yearsOfExp')}
                helperText={errors.yearsOfExp || ''}
                label='Years of experience'
                type='text'
                margin='normal'
                name='yearsOfExp'
                value={values ? values.yearsOfExp : ''}
                onChange={handleChange}
              />
              <Textfield
                required
                error={errors.hasOwnProperty('education')}
                helperText={errors.education || ''}
                label='Relevant education'
                type='text'
                margin='normal'
                name='education'
                value={values ? values.education : ''}
                onChange={handleChange}
              />

              <Textfield
                error={errors.hasOwnProperty('website')}
                helperText={errors.website || ''}
                label='Portfolio Website'
                type='text'
                margin='normal'
                name='website'
                value={values ? values.website : ''}
                onChange={handleChange}
              />
              <Textfield
                error={errors.hasOwnProperty('password')}
                helperText={errors.password || ''}
                label='Password'
                type='password'
                margin='normal'
                name='password'
                value={values ? values.password : ''}
                onChange={handleChange}
              />

              <Flex
                mt={2}
                mb={2}
                jusifyContent={'space-between'}
                alignItems={'center'}>
                <Text>Add Portfolio files</Text>
                <AddFilesButton
                  onAddFiles={(newFiles) => {
                    setFiles([...files, ...newFiles])
                  }}
                />
              </Flex>
              <FileContainer
                multiple
                files={files}
                onAddFiles={(newFiles) => {
                  console.log(newFiles)
                  setFiles([...files, ...newFiles])
                }}
                onDeleteFile={(name) => {
                  const filtered = files.filter((f) => f.name !== name)
                  setFiles(filtered)
                }}
              />

              <ButtonWithLoading
                style={{ alignSelf: 'center' }}
                isLoading={formSubmitting}
                mt={4}>
                Apply
              </ButtonWithLoading>
              <Flex mt={2} alignItems={'center'} flexDirection={'column'}>
                <Text>By clicking Apply you agree to</Text>
                <Text
                  onClick={termsClick}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  Hemset's Terms & conditions{' '}
                </Text>
              </Flex>
            </form>
          </Flex>
        )}
      </CardContent>
      <Snackbar
        open={showErrorSnack}
        className={classes.errorSnackbar}
        autoHideDuration={2000}
        onClose={onErrorSnackClose}>
        <SnackbarContent
          className={classes.errorSnackbarContent}
          message={<span>Failed :(</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={onErrorSnackClose}>
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    </Card>
  )
}

export default ApplyForm
