import React from 'react'
import { Text } from '../../components/primitives'

export const Terms = () => {
  return (
    <Text>
      <b>Terms and conditions for Interior designer</b> <br />
      These terms and conditions apply to interior designers who wish to offer
      their services to Hemset AB (“Hemset”). Please be sure to read these terms
      and conditions carefully before you proceed in using Hemsets service (the
      “Service”).
      <br />
      <br />
      By registering as a user of the Service it is understood that you enter
      into an agreement with Hemset, which allows you to use the Service.
      <br />
      <br />
      It is understood that these terms and conditions, which may be modified
      from time to time, constitute legally binding terms and conditions and
      apply to your usage of the Service. By agreeing to offer your interior via
      the Service, you agree to be bound by the following terms and conditions.
      <br />
      <br />
      <b>The Service</b> <br />
      Hemset provides a service by which you can offer your interior design
      service(s). The individuals who are identified as interior designers are
      either contacted by Hemset or has applied them self to use the Service to
      offer their interior design service to the general public. Hemset makes
      the selection of Interior designer. This process is to secure the
      integrity and high quality of the interior design services sold by Hemset.
      This means that the Service is only available to interior designers whose
      interior design has been selected by Hemset.
      <br />
      <br />
      Hemset reserves the right to, at any time and without cause, remove an
      interior designer previously accepted to use the Service. Hemset also
      reserves the right not to offer the Interior design concept to a customer
      if the quality of the Interior design concept does not meet the
      requirements for the Service.
      <br />
      <br />
      The customers can buy Interior design concepts available at Hemset in
      different size and extent. You will be compensated for each interior
      concept made according to these terms and conditions.
      <br />
      <br />
      The interior designer cannot apply to offer its interior design services
      to any other online services outside of Hemset. All interior design-
      related work online need to go through Hemset. All interior design
      service(s) and consultancy online shall go through Hemset. This applies to
      6 month after the contract is terminated.
      <br />
      <br />
      <b>Registration</b> <br />
      To offer your interior design service(s) to our customer you have to
      register for the Service. You are to provide all necessary personal data
      as well as information necessary to execute payment of any remuneration
      arising from the sale of your interior design service(s). You are solely
      responsible for the data and information provided being accurate. You
      cannot hold Hemset responsible for any loss incurred by you due to you
      having provided inaccurate data and/or information.
      <br />
      <br />
      Hemset cannot be held liable for any direct, indirect, consequential,
      exemplary, incidental or punitive damages, including but not limited to,
      lost profits, lost repute or goodwill, damages due to business
      interruption and damages due to loss of data or information, arising out
      of, or in connection with, your use or inability to use the Service.
      Hemset shall not be held responsible for any infringement in third party
      rights caused by the Interior design concepts offered for sale via the
      Service.
      <br />
      <br />
      Hemset is also not liable for and may not be held liable for any damages
      or viruses on your computer equipment or other property resulting from
      access to, or use of, the Service.
      <br />
      <br />
      <b>Interior design concept(s)</b> <br />
      The interior design concepts created for Hemset shall contain;
      <br />
      <br />
      Professional interior design service for one room (up to 40 m2) A mood
      board adapted to the customer and style; 2D layout of the room; A product
      list based on the needs and preferences of the customer; Direct contact
      with you as an interior designer via phone, Skype, e-mail or other; Open
      discussions about the room including how the customer intend to use the
      space, how to incorporate existing furniture and any special requirements;
      If the customer is not 100% happy with the design, you as interior
      designer will provide one updated proposal according to the customer’s
      requirements.
      <br />
      <br />
      You should be advised that Hemset will not accept Interior design concept
      that may be considered offensive, defamatory or unlawful. You may not
      create Interior design concept which cannot be legally reproduced,
      displayed and/or distributed without resulting in infringement of any
      third parties’ copyright(s) or for any other legal reason cannot be
      accepted.
      <br />
      <br />
      As examples, but not limited to, you undertake not to create Interior
      design concept:
      <br />
      <br />
      Which defame, discredits, abuse, harass or threaten other people. Which
      infringe any third parties’ rights, including intellectual property
      rights; Which contain politically controversial, inappropriate,
      defamatory, infringing, obscene, pornographic, racist, indecent or
      unlawful topics, names, materials or information; Which depict explicit
      violence or torture; Which contain viruses or any other activity that
      might harm Hemset in any way; Which have been falsified or otherwise
      manipulated with regard to author attributions, legal or other proper
      notices or proprietary designations or labels of the origin or other
      material contained in the Interior design concept.
      <br />
      <br />
      <b>Intellectual property</b> <br />
      By agreeing to offer your interior design service(s) for sale via the
      Service you assign an exclusive world-wide license to Hemset for a period
      of 12 months from the date you have agreed to offer the interior design
      services for sale. This license gives Hemset the right to;
      <br />
      <br />
      (1) sell and distribute the Interior design concept(s), and
      <br />
      <br />
      (2) to display the Interior design concept(s) publicly.
      <br />
      <br />
      An individual license is granted for each interior concept you create. The
      duration of each license is as stated above 12 months. During the period
      the license is in force you cannot terminate the license. However the
      license is exclusive so you are not free to sell the Interior design
      concept(s) to third parties.
      <br />
      <br />
      Hemset retains the right, without territorial or media limitations, to use
      the Interior design concept(s) in its advertising and marketing of the
      Service during the duration of the license. Hemset may manipulate and crop
      the Interior design concepts for the purpose of the marketing activity.
      Hemset also has the right to assign the right to reproduce and use the
      photograph to third parties for its marketing purposes.
      <br />
      <br />
      <b>Indemnification </b> <br /> By accepting these terms and conditions you
      agree to indemnify, defend and hold harmless Hemset from, and against, all
      demands, claims, actions or causes of action, assessments, losses,
      damages, liabilities, costs and expenses, by reason of, or resulting from,
      a breach of your representation and warranty contained in these terms and
      conditions.
      <br />
      <br />
      <b>Remuneration</b> <br /> You are entitled to remuneration for each sale
      of your Interior design concepts by Hemset in an amount equivalent to 1560
      excl. VAT. Additional services like transportation, order or other will
      not be considered in the calculation of the remuneration for the Interior
      design concept and cannot be demanded by you. The prices stated on
      Hemset’s website for the Interior design concept(s) constitute the sales
      prices on which the remuneration is calculated. Hemset has sole discretion
      in setting the prices for the Interior design services for sale via the
      Service.
      <br />
      <br />
      Payment of the remuneration will only be made using invoices and to use
      the Service you have to have signed up with a VAT number or equal service
      that are legal for VAT and provide correct account information to Hemset.
      You are solely responsible for accuracy of the account information you
      provide and cannot hold Hemset liable for any loss due to inaccurate
      information.
      <br />
      <br />
      Payment of your share of the accumulated sales for one month will be made
      to the account you have stated on the first day of the following month.
      <br />
      <br />
      You are solely responsible for any taxes and/or government fees arising as
      a consequence of the sale of your photograph(s).
      <br />
      <br />
      <b>Personal data policy</b> <br /> Personal data' means any information
      relating to an identified or identifiable natural person ("data subject");
      an identifiable natural person is one who can be identified, directly or
      indirectly, in particular by reference to an identifier such as a name, an
      identification number, location data, an online identifier or to one or
      more factors specific to the physical, physiological, genetic, mental,
      economic, cultural or social identity of that natural person.
      <br />
      <br />
      Hemset with [address] is the data controller with regard to any and all
      personal data generated from the Service.
      <br />
      <br />
      The legal ground for Hemset’s processing of your data is to administer and
      fulfill this agreement (GDPR article 6.1 b).
      <br />
      <br />
      Hemset will use personal data provided by you or acquired through other
      sources or the purpose of administering the agreement with you concerning
      the sale of your Interior design service(s). Hemset will also use your
      data to send information relevant to you as a interior designer relating
      to your use of the Service and the Interior design concept(s) you have
      created. This information will be provided solely via e-mail and thus you
      accept this mode of communication for such information.
      <br />
      <br />
      Hemset may share your Personal data, if necessary for the purpose of this
      agreement, with a third party that is acting on our behalf to provide
      services and to enhance the experience of our Service and our products or
      services including such providers outside the European Economic Area (EU +
      Norway, Iceland and Liechtenstein). These third party service providers
      are in such instances contractually obliged to treat the information in
      the strictest confidence. They may not use the data in any other way than
      stipulated. With regard to service providers outside the EEA Hemset has
      secured that all instruments and measures stipulated in the GDPR and by
      the EU-Commission have been implemented correctly.
      <br />
      <br />
      Hemset may also use your Personal data if necessary to protect perceived
      rights, safety and property of Hemset, our partners and users of the
      Service or where necessary due to legal and regulatory requirements.
      <br />
      <br />
      <b>Retaining personal data</b>
      <br />
      <br />
      Your personal data will only be kept so long as it is necessary to
      administer this agreement and to fulfill Hemset’s legal and financial
      responsibilities to you arising from the Service. So if, and to the extent
      Hemset is required to do so by law, or if necessary in order to establish,
      exercise or defend Hemset’s legal rights, Hemset will retain the
      information for longer.
      <br />
      <br />
      Security measures with regard to the personal data Hemset is committed to
      keeping your personal data secure against unauthorized access or user
      alteration, unlawful or accidental destruction and accidental loss. Only
      authorized employees, agents and contractors (who have agreed to keep
      information secure and confidential) have access to your Personal data.
      However, you should be aware that there is always some risk involved in
      transmitting information over the internet.
      <br />
      <br />
      <b>Your rights</b>
      <br />
      <br />
      You have the right to request information about the Personal data that
      Hemset holds on you, its origin and recipients as well as the purpose for
      which it is being stored. Information about stored data can be obtained by
      contacting Hemset at info@hemset.com.
      <br />
      <br />
      If your Personal data is incorrect, incomplete or irrelevant, you can ask
      to have such Personal data information corrected or removed. Hemset will
      notify you within 30 days of receiving the request as to whether and, if
      so, to what extent your request will be complied with. If for any reason
      Hemset does not comply with your request, the reasons therefor will be
      provided.
      <br />
      <br />
      You are aware that your data must be retained for the duration of the
      license(s) you have granted Hemset to fulfill the terms of and conditions
      of this agreement. Thus deletion cannot be accepted before the license(s)
      period(s) have expired and all of Hemset’s legal obligations have been
      fulfilled.
      <br />
      <br />
      You have the right lodge a complaint with the competent authority in your
      country.
      <br />
      <br />
      <b>Amendments to this policy</b>
      <br />
      <br />
      Hemset has pledged to adhere to the fundamental principles of Data
      Protection and consider the Privacy Policy as being binding in nature.
      Hemset therefore regularly review its Privacy Policy in order to ensure
      that it is free or errors, clearly visible on Hemset’s website, contains
      all the required information, is implemented in full, and is compliant
      with the Data Protection principles. This Privacy Policy may be changed
      from time to time in order to keep pace with new developments and
      opportunities relating to the Internet and to stay in line with prevailing
      EU legislation. Significant changes to the policy will be publicized on
      Hemset’s website along with an updated version of the Privacy Policy.
      <br />
      <br />
      <b>Privacy contact information</b>
      <br />
      <br />
      If you do not understand any of the foregoing terms and conditions or if
      you have any questions, concerns or comments about this Privacy Policy you
      may contact Hemset at this address: info@hemset.com.
      <br />
      <br />
      <b>Termination</b> <br /> The Agreement with the interior designer has a
      minimum duration of 12 months for each Interior design concept the
      interior designer has created via the Service. The Agreement is thus
      terminated upon the expiration of the 12 month period from the date on
      which the last Interior design concept was created by the interior
      designer.
      <br />
      <br />
      Hemset has the right to immediately terminate its agreement with you in
      the event you are discovered to be in breach of these terms and
      conditions. The accumulated remuneration not yet disbursed will be
      forfeited. Hemset reserves the right to consider and bring legal action
      against you depending on the nature of your violation. If the violation is
      of a less severe nature, and where it might be corrected by you, Hemset
      will take this into consideration in its decision whether to terminate the
      agreement or not.
      <br />
      <br />
      You have the right to immediately terminate the agreement and the license
      it gives Hemset in the event of Hemset being in grave breach of this
      agreement and also refusing to bring its actions into accordance with this
      agreement after you having made Hemset aware of said breach and demanded
      such adjustments.
      <br />
      <br />
      <b>Disputes and applicable law </b> <br />
      These terms and conditions, and the agreements entered into between Hemset
      and the interior designer(s), shall be governed by, and construed in
      accordance with, the laws of Sweden.
      <br />
      <br />
      Any dispute, controversy or claim arising out of, or in connection with,
      these terms and conditions, or the breach, termination or invalidity
      thereof, shall be finally settled in the District Court in Stockholm.
      <br />
      <br />
      If any provision of these Terms is held invalid this shall not affect the
      remaining provisions of these Terms.
    </Text>
  )
}
