import React, { useState, useContext } from 'react'
import { compose, branch, renderComponent } from 'recompose'
import { makeStyles } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

import AuthContext from '../../modules/AuthContext'
import { isMasterAdmin } from '../../modules/const'
import ErrorCard from '../../components/ErrorCard'
import CreateDecoratorForm from '../../components/users/CreateDecoratorForm'
import CreateItemDialog from '../../components/dialogs/CreateItemDialog'
import DeleteItemDialog from '../../components/dialogs/DeleteItemDialog'
import EditItemDialog from '../../components/dialogs/EditItemDialog'
import EditDecoratorForm from '../../components/users/EditDecoratorForm'
import { UserList } from './components/Userlist'

import { LIST_USERS, DELETE_USER } from '../../gql/auth'
import { CREATE_DECORATOR, UPDATE_DECORATOR } from '../../gql/decorators'

const queryParams = {
  variables: {
    types: ['Decorator']
  },
  fetchPolicy: 'no-cache'
}

const useStyles = makeStyles((theme) => {
  return {
    addAdminButton: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
    loadingProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    errorContainer: {
      maxWidth: 400,
      marginTop: theme.spacing(3),
      textAlign: 'center',
      margin: '0 auto',
      padding: 10
    }
  }
})

const DecoratorPage = function(props) {
  const classes = useStyles()
  const { currentUser } = useContext(AuthContext)

  const { data, refetch, fullScreen } = props

  const [createDecorator] = useMutation(CREATE_DECORATOR)
  const [deleteDecorator] = useMutation(DELETE_USER)
  const [updateDecorator] = useMutation(UPDATE_DECORATOR)

  const [createDecoratorSubmitting, setCreateDecoratorSubmitting] = useState(
    false
  )
  const [deleteDecoratorSubmitting, setDeleteDecoratorSubmitting] = useState(
    false
  )
  const [updateDecoratorSubmitting, setUpdateDecoratorSubmitting] = useState(
    false
  )

  const [
    openCreateDecoratorErrorSnack,
    setCreateDecoratorErrorSnack
  ] = useState(false)
  const [
    openDeleteDecoratorErrorSnack,
    setDeleteDecoratorErrorSnack
  ] = useState(false)
  const [
    openUpdateDecoratorErrorSnack,
    setUpdateDecoratorErrorSnack
  ] = useState(false)

  const [openCreateDecorator, setOpenCreateDecorator] = useState(false)
  const [openDeleteDecorator, setOpenDeleteDecorator] = useState(false)
  const [openEditDecorator, setOpenEditDecorator] = useState(false)

  const [editingUser, setCurrentUser] = useState({})

  const handleOpenEditDecorator = (user) => {
    setOpenEditDecorator(true)
    setCurrentUser(user)
  }

  const handleOpenDeleteDecorator = (user) => {
    setOpenDeleteDecorator(true)
    setCurrentUser(user)
  }

  const handleOpenCreateDecorator = () => {
    setOpenCreateDecorator(true)
  }

  const handleCloseCreateDecorator = () => {
    setOpenCreateDecorator(false)
  }

  const handleCloseDeleteDecorator = () => {
    setOpenDeleteDecorator(false)
  }

  const handleCloseEditDecorator = () => {
    setOpenEditDecorator(false)
  }

  const handleCreateDecoratorErrorSnackClose = () =>
    setCreateDecoratorErrorSnack(false)
  const handleDeleteDecoraatorErrorSnackClose = () =>
    setDeleteDecoratorErrorSnack(false)
  const handleUpdateDecoratorErrorSnackClose = () =>
    setUpdateDecoratorErrorSnack(false)

  const handleCreateDecorator = (data) => {
    setCreateDecoratorSubmitting(true)
    createDecorator({
      variables: { input: { ...data } },
      update: (proxy, mutationResult) => {
        setCreateDecoratorSubmitting(false)
        if (!mutationResult.data.createDecorator.success) {
          setCreateDecoratorErrorSnack(true)
        } else {
          refetch(queryParams)
          setOpenCreateDecorator(false)
        }
      }
    })
  }

  const handleDeleteDecorator = () => {
    setDeleteDecoratorSubmitting(true)
    deleteDecorator({
      variables: { id: editingUser._id },
      update: (proxy, mutationResult) => {
        setDeleteDecoratorSubmitting(false)
        if (!mutationResult.data.deleteUser.success) {
          setDeleteDecoratorErrorSnack(true)
        } else {
          refetch(queryParams)
          setOpenDeleteDecorator(false)
        }
      }
    })
  }

  const handleUpdateDecorator = (data) => {
    setUpdateDecoratorSubmitting(true)
    updateDecorator({
      variables: { id: editingUser._id, input: { ...data } },
      update: (proxy, mutationResult) => {
        setUpdateDecoratorSubmitting(false)
        if (!mutationResult.data.updateDecorator.success) {
          setUpdateDecoratorErrorSnack(true)
        } else {
          refetch(queryParams)
          setOpenEditDecorator(false)
        }
      }
    })
  }

  const renderCreateDecoratorForm = () => {
    return (
      <CreateItemDialog
        fullScreen={fullScreen}
        onClose={handleCloseCreateDecorator}
        disableSubmit={createDecoratorSubmitting}
        title='Create Decorator'
        formId='create-decorator-form'
        submitText='Create'>
        <CreateDecoratorForm
          onLogin={handleCreateDecorator}
          showErrorSnack={openCreateDecoratorErrorSnack}
          onErrorSnackClose={handleCreateDecoratorErrorSnackClose}
        />
      </CreateItemDialog>
    )
  }

  const renderEditDecoratorModal = () => {
    return (
      <EditItemDialog
        fullScreen={fullScreen}
        onClose={handleCloseEditDecorator}
        disableSubmit={updateDecoratorSubmitting}
        title='Edit Decorator'
        formId='edit-decorator-form'
        submitText='Update'>
        <EditDecoratorForm
          data={editingUser}
          onEdit={handleUpdateDecorator}
          showErrorSnack={openUpdateDecoratorErrorSnack}
          onErrorSnackClose={handleUpdateDecoratorErrorSnackClose}
        />
      </EditItemDialog>
    )
  }

  console.log(data)

  return (
    <Box>
      <UserList
        isMasterAdmin={isMasterAdmin(currentUser['__typename'])}
        users={data && data.users ? data.users : []}
        onEdit={handleOpenEditDecorator}
        onDelete={handleOpenDeleteDecorator}
        isDecorator={true}
      />

      {openEditDecorator && renderEditDecoratorModal()}
      {openDeleteDecorator && (
        <DeleteItemDialog
          fullScreen={fullScreen}
          onClose={handleCloseDeleteDecorator}
          onSubmit={handleDeleteDecorator}
          disableSubmit={deleteDecoratorSubmitting}
          showSnackbar={openDeleteDecoratorErrorSnack}
          onSnackbarClose={handleDeleteDecoraatorErrorSnackClose}
          title=' Delete Decorator'
          text='Are you sure you want to delete this decorator?'
        />
      )}
      {openCreateDecorator && renderCreateDecoratorForm()}
      <Fab
        color='primary'
        aria-label='Create Decorator'
        disabled={currentUser && !isMasterAdmin(currentUser['__typename'])}
        className={classes.addAdminButton}
        onClick={handleOpenCreateDecorator}>
        <AddIcon />
      </Fab>
    </Box>
  )
}

const withRequest = (BaseComponent) => (props) => {
  const { data, error, loading, refetch } = useQuery(LIST_USERS, queryParams)
  console.log(data)
  return (
    <BaseComponent
      {...props}
      data={data}
      loading={loading}
      error={error}
      refetch={refetch}
    />
  )
}

const withError = branch(
  ({ error }) => error,
  renderComponent(() => {
    return <ErrorCard message={'Error Loading Decorator list'} />
  })
)

const withLoading = branch(
  ({ loading }) => loading,
  renderComponent(() => {
    const classes = useStyles()
    return <CircularProgress className={classes.loadingProgress} />
  })
)

export default compose(
  withRequest,
  withLoading,
  withError,
  withMobileDialog()
)(DecoratorPage)
