import React, { Component } from 'react'
import Alert from 'react-s-alert'

import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'
import { ApolloProvider } from 'react-apollo'
import ApolloClient from 'apollo-client'
import { CookiesProvider, Cookies } from 'react-cookie'
import { ThemeProvider as ThemeProviderMUI } from '@material-ui/styles'
import { AuthProvider } from './modules/AuthContext'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink, split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { ThemeProvider } from 'styled-components'
import { theme as themeRebass } from './theme'
import { StylesProvider } from '@material-ui/styles'

import theme from './modules/theme'
import App from './App'

import CustomBrowserRouter from './modules/CustomBrowserRouter'

let wsUrl = 'wss://admin.hemset.se/api/graphql'
let apiUrl = 'https://admin.hemset.se/api/graphql'

if (window.location.origin === 'https://adminstage.hemset.se') {
  wsUrl = 'wss://adminstage.hemset.se/api/graphql'
  apiUrl = 'https://adminstage.hemset.se/api/graphql'
}

if (process.env.NODE_ENV !== 'production') {
  wsUrl = 'ws://localhost:4000/graphql'
  apiUrl = 'http://localhost:4000/graphql'
}

const wsLink = new WebSocketLink({
  uri: wsUrl,
  options: {
    reconnect: true,
    lazy: true,
    // Needs to be a function so a new token is obtained when reconnecting
    connectionParams: () => ({
      token: new Cookies().get('dd.sid')
    })
  }
})

const httpLink = new HttpLink({
  uri: apiUrl,
  credentials: 'include'
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      }
      if (networkError) {
        console.log(`[Network error]: ${JSON.stringify(networkError)}`)
        // see https://github.com/apollographql/subscriptions-transport-ws/issues/171
        if (networkError.message === 'ws-auth-error') {
          const token = new Cookies().get('dd.sid')
          if (token) {
            // Reset the WS connection for it to carry the new JWT.
            wsLink.subscriptionClient.close(false, false)
          }
        }
      }
    }),
    link
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => object._id
  })
})

export default class MainRouter extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <CookiesProvider>
          <CustomBrowserRouter>
            <AuthProvider>
              <StylesProvider injectFirst>
                <ThemeProvider theme={themeRebass}>
                  <ThemeProviderMUI theme={theme}>
                    <Alert stack={{ limit: 3 }} />
                    <App />
                  </ThemeProviderMUI>
                </ThemeProvider>
              </StylesProvider>
            </AuthProvider>
          </CustomBrowserRouter>
        </CookiesProvider>
      </ApolloProvider>
    )
  }
}
