import React, { useState, useContext } from 'react'
import request from 'superagent'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import AuthContext from '../../modules/AuthContext'
import { isAdmin, isDecorator } from '../../modules/const'
import {
  LIST_PRODUCTS,
  CREATE_PRODUCT,
  ARCHIVE_PRODUCT
} from '../../gql/products'
import ProductDialog from './components/ProductDialog'
import { ProductListTwo } from './components/ProductListTwo'
import DeleteItemDialog from '../../components/dialogs/DeleteItemDialog'
import { Flex } from '../../components/primitives'

const useStyles = makeStyles((theme) => {
  return {
    addButton: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
    createAdminForm: {
      padding: theme.spacing(4)
    },
    createAdminFormTitle: {
      margin: 0,
      padding: theme.spacing(2)
    },
    createAdminFormCloseButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    loadingProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    errorSnackbarContent: {
      backgroundColor: theme.palette.error.dark
    }
  }
})

const ProductsPage = function(props) {
  const { currentUser } = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState(false)
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false)
  const [deleteProduct, setDeleteProduct] = useState(null)
  const [deleteProductLoaing, setDeleteProductLoaing] = useState(false)

  const userId = currentUser ? currentUser._id : ''
  let admin = false
  if (currentUser && isAdmin(currentUser['__typename'])) {
    admin = true
  }

  const [createProduct] = useMutation(CREATE_PRODUCT)
  const [archiveProduct] = useMutation(ARCHIVE_PRODUCT)

  const classes = useStyles()
  const {
    data,
    error: listProductsError,
    loading,
    refetch
  } = useQuery(LIST_PRODUCTS)

  const products = data ? data.products : []

  const handleSubmit = (product, fileMap) => {
    setSubmitting(true)
    createProduct({
      variables: {
        input: product
      },
      update: (proxy, result) => {
        setSubmitting(false)
        const product = result.data.createProduct.product
        if (product.files && product.files.length > 0) {
          uploadFiles(product, fileMap)
        } else {
          setOpen(false)
        }
      }
    })
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = (e, reason) => {
    setOpen(false)
  }

  const uploadFiles = (product, fileMap) => {
    setUploading(true)
    let ulPromises = []
    product.files.forEach((f) => {
      const file = fileMap[f.origName]
      if (f.uploadUrl && file) {
        console.log(file)
        ulPromises.push(
          new Promise((resolve, reject) => {
            request
              .put(f.uploadUrl)
              .send(file)
              .retry()
              .on('progress', (event) => {
                console.log(`Uploading file ${f.origName}`, event)
              })
              .end((err, res) => {
                if (err) {
                  reject(err)
                } else {
                  resolve()
                }
              })
          })
        )
      }
    })
    Promise.all(ulPromises)
      .catch((err) => {
        setError('There was a problem uploading the product files')
        console.log('Failed to upload files', err)
      })
      .finally(() => {
        setUploading(false)
        setOpen(false)
        refetch()
      })
  }

  const handleDeleteProduct = (id) => {
    setDeleteProductLoaing(true)
    archiveProduct({
      variables: { id },
      update: (proxy, result) => {
        setDeleteProductLoaing(false)
        setOpenDeleteProduct(false)
        refetch()
      }
    })
  }

  const handleCloseError = (e) => {
    setError()
  }

  if (loading) {
    return <CircularProgress className={classes.loadingProgress} />
  }

  return (
    <Flex pr={[0, 2]} pl={[0, 2]} flexDirection={'column'}>
      <ProductListTwo
        userId={userId}
        isAdmin={admin}
        onClick={(url) => {
          window.open(url, '_blank')
        }}
        products={products}
        onDelete={(product) => {
          setDeleteProduct(product)
          setOpenDeleteProduct(true)
        }}
      />

      {openDeleteProduct && (
        <DeleteItemDialog
          fullScreen={false}
          onClose={() => {
            setOpenDeleteProduct(false)
          }}
          onSubmit={() => {
            handleDeleteProduct(deleteProduct)
          }}
          disableSubmit={deleteProductLoaing}
          showSnackbar={false}
          onSnackbarClose={() => {}}
          title='Delete product'
          text='Are you sure you want to delete this product?'
        />
      )}

      <ProductDialog
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitting={submitting || uploading}
      />
      <Fab
        color='primary'
        aria-label='Create Product'
        disabled={
          currentUser &&
          !(
            isAdmin(currentUser['__typename']) ||
            isDecorator(currentUser['__typename'])
          )
        }
        className={classes.addButton}
        onClick={handleOpen}>
        <AddIcon />
      </Fab>
      <Snackbar
        open={error}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <SnackbarContent
          message={<span>{error}</span>}
          className={classes.errorSnackbarContent}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={handleCloseError}>
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
      <Snackbar
        open={listProductsError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <SnackbarContent
          message={<span>There was a problem loading the products</span>}
          className={classes.errorSnackbarContent}
        />
      </Snackbar>
    </Flex>
  )
}

export default ProductsPage
