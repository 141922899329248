import React from 'react'
import { Flex, Text } from '../../../components/primitives'
import { TextWithClick } from '../../../components/sharable/text/TextWithClick'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'

export const ColorCard = ({ color, title, url, onRemove }) => {
  return (
    <Flex
      p={2}
      mb={2}
      mr={2}
      style={{
        borderRadius: '5px',
        position: 'relative',
        border: '1px solid lightGrey'
      }}
      width={'200px'}
      height={'150px'}
      flexDirection={'column'}>
      {onRemove && (
        <Flex
          style={{ color: 'white', position: 'absolute', top: 5, right: 5 }}>
          <IconButton
            style={{ color: 'white' }}
            aria-label='Delete'
            onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
        </Flex>
      )}

      <Flex alignItems={'flex-end'} bg={color} width={'100%'} height={'140px'}>
        <Text p={2} color={'white'}>
          {title}
        </Text>
      </Flex>
      <TextWithClick
        mt={2}
        onClick={() => {
          window.open(url, '_blank')
        }}>
        Visa kulörinformation
      </TextWithClick>
    </Flex>
  )
}
