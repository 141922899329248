import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0.125rem 0.25rem',
    borderRadius: '0.25rem',
    fontWeight: 700,
    color: 'white',
    backgroundColor: ({ color }) => (color || 'black')
  }
}))

function Tag ({ value, color, children, className, ...otherProps }) {
  const classes = useStyles({ color })
  return <Typography variant='caption' className={clsx(
    classes.root,
    className
  )} {...otherProps}>
    {children}
  </Typography>
}

export default Tag
