import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'

import AuthContext from '../../modules/AuthContext'
import { LoginForm } from './LoginForm'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      width: '400px',
      minHeight: '300px',
      margin: '0 auto',
      marginTop: theme.spacing(8),
      flexDirection: 'column'
    }
  }
})

function LoginPage(props) {
  const { login } = useContext(AuthContext)
  const classes = useStyles()

  const renderForm = () => (
    <LoginForm
      loggedIn={(user) => {
        console.log(user)
        login(user)
        props.history.push('/')
      }}
    />
  )
  return <div className={classes.root}>{renderForm()}</div>
}

export default withRouter(LoginPage)
