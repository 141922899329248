import { gql } from 'apollo-boost'

export const BLOGSADMIN = gql`
  query {
    blogsAdmin {
      _id
      createdAt
      updatedAt
      title
      description
      url
      metaTitle
      category
      metaDesc
      live
      html
      files {
        origName
        name
        url
        uploadUrl
      }
    }
  }
`

export const ADD_BLOG_ITEM = gql`
  mutation(
    $title: String!
    $description: String!
    $html: String!
    $category: String!
    $url: String!
    $metaTitle: String!
    $metaDesc: String!
    $files: [FileInput!]
  ) {
    addBlogItem(
      title: $title
      description: $description
      html: $html
      metaTitle: $metaTitle
      category: $category
      metaDesc: $metaDesc
      url: $url
      files: $files
    ) {
      success
      message
      blog {
        _id
        title
        url
        description
        html
        category
        metaTitle
        metaDesc
        live
        files {
          origName
          name
          url
          uploadUrl
        }
      }
    }
  }
`

export const EDIT_BLOG_ITEM = gql`
  mutation(
    $id: ID!
    $title: String!
    $isNewFile: Boolean!
    $description: String!
    $category: String!
    $html: String!
    $url: String!
    $metaTitle: String!
    $metaDesc: String!
    $files: [FileInput!]
  ) {
    editBlogItem(
      id: $id
      isNewFile: $isNewFile
      title: $title
      category: $category
      description: $description
      html: $html
      metaTitle: $metaTitle
      metaDesc: $metaDesc
      url: $url
      files: $files
    ) {
      success
      message
      blog {
        _id
        title
        url
        description
        html
        category
        metaTitle
        metaDesc
        live
        files {
          origName
          name
          url
          uploadUrl
        }
      }
    }
  }
`

export const SET_BLOG_ITEM_LIVE = gql`
  mutation($id: ID!, $live: Boolean!) {
    setBlogItemLive(id: $id, live: $live) {
      success
      message
    }
  }
`
