import React from 'react'
import styled from 'styled-components'
import { Alink, Image, Flex, Text, Button } from '../primitives'
import logo from '../../assets/images/hemset-horizontal.png'
import fb from '../../assets/images/fblogo.png'
import ig from '../../assets/images/instalogo.png'
import { ImageContain } from '../primitives/ImageCover'

const SCFooter = styled.footer``

export const Footer = () => {
  return (
    <SCFooter className={'dont-show-in-pdf'}>
      <Flex
        p={2}
        pt={5}
        minHeight={'288px'}
        maxWidth={'1920'}
        jusifyContent={'center'}
        alignItems={'center'}
        bg={'primaryLight'}
        flexDirection={'column'}>
        <Flex flexDirection={['column', 'row']}>
          <Flex flexDirection={'column'} width={[1, '50%']}>
            <Flex>
              <ImageContain height={'50px'} width={'150px'} src={logo} />
            </Flex>
            <Text mt={2} width={'70%'}>
              Få ett trivsamt hem med Hemset. Vi hjälper dig att inreda ditt hem
              med fri rådgivning eller hela koncept mot en prisvärd avgift.
            </Text>

            <Text mt={2}>Email: info@hemset.se </Text>
          </Flex>
          <Flex width={[1, '25%']} pt={2}>
            <Flex flexDirection={'column'}>
              <Alink href='/'>Hem</Alink>

              <Alink className='hemset-wizzard' href='https://www.hemset.se/#!'>
                Lägg beställning
              </Alink>
              <Alink href='https://www.hemset.se/projects'>
                Tidigare inredning
              </Alink>
              <Alink href='https://www.hemset.se/inspiration'>
                Inspiration
              </Alink>
              <Alink href='https://www.hemset.se//about'>Om oss</Alink>
              <Alink href='https://www.hemset.se//conditions'>
                Allmänna villkor
              </Alink>
              <Alink href='https://www.hemset.se//conditions/privacy'>
                Integritetspolicy
              </Alink>
              <Alink href='https://www.hemset.se//conditions/cookiepolicy'>
                Cookiepolicy
              </Alink>
            </Flex>
          </Flex>
          <Flex width={[1, '25%']}>
            <Flex pt={2}>
              <a href='https://www.facebook.com/DeDecco-AB-384641451898907/'>
                <Image height={'36px'} width={'36px'} src={fb} />
              </a>
              <a href='https://www.instagram.com/hemsetcom/'>
                <Image ml={2} height={'36px'} width={'36px'} src={ig} />
              </a>
            </Flex>
          </Flex>
        </Flex>
        <Flex flex={1} alignItems={'flex-end'} jusifyContent={'center'}>
          <Text fontSize={0}>© 2012 Copyright Hemset AB</Text>
        </Flex>
      </Flex>
    </SCFooter>
  )
}
