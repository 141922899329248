export const clipTo = function(ctx) {
  if (this.canvas) {
    this.setCoords()
    const clipRect = this.clipRect
    const scaleXTo1 = 1 / this.scaleX
    const scaleYTo1 = 1 / this.scaleY
    ctx.save()

    const ctxLeft = -(this.width / 2)
    const ctxTop = -(this.height / 2)

    ctx.translate(ctxLeft, ctxTop)
    ctx.rotate((this.angle * -1 * Math.PI) / 180)
    ctx.scale(scaleXTo1, scaleYTo1)

    const x = this.canvas.viewportTransform

    ctx.scale(1 / x[0], 1 / x[3])
    ctx.translate(x[4], x[5])

    ctx.beginPath()

    ctx.rect(
      x[0] * clipRect.x - this.oCoords.tl.x,
      x[3] * clipRect.y - this.oCoords.tl.y,
      x[0] * clipRect.w,
      x[3] * clipRect.h
    )
    ctx.closePath()
    ctx.restore()
  }
}
