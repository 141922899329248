import React from 'react'
import Comment from './Comment'
import AddComment from './AddComment'
import { PaperTitle } from '../../../components/sharable/PaperTitle'
import { Flex } from '../../../components/primitives'

function OrderDiscussion({
  comments,
  uploading,
  currentUser,
  onCommentAdded,
  filesToAd,
  onFilesChanged,
  isOpen = true,
  title = 'DISCUSSION',
  placeholder = 'Enter comment',
  buttonTitle = 'Send'
}) {
  const handleCommentAdded = (comment) => {
    onCommentAdded && onCommentAdded(comment)
  }

  return (
    <Flex flexDirection={'column'} width={'100%'}>
      <Flex mb={2}>
        <PaperTitle title={title} />
      </Flex>
      {isOpen && (
        <AddComment
          placeholder={placeholder}
          buttonTitle={buttonTitle}
          submitting={uploading}
          onCommentAdded={handleCommentAdded}
          filesToAd={filesToAd}
          onFilesChanged={onFilesChanged}
        />
      )}
      {comments &&
        comments
          .slice(0)
          .reverse()
          .map((c) => {
            return <Comment key={c._id} comment={c} currentUser={currentUser} />
          })}
    </Flex>
  )
}

export default OrderDiscussion
