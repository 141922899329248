import React from 'react'
import { withCookies } from 'react-cookie'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, publicComponent: PublicComponent, cookies, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      cookies.get('dd.sid', { path: '/' }) ? (
        <Component {...props} />
      ) : PublicComponent ? <PublicComponent {...props} /> : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
)

export default withCookies(PrivateRoute)
