import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import { colors } from '../../modules/colors'

export const TextInput = styled(TextField)`
  padding: 10px 10px 10px 10px;
  border: 1px solid #bebebe;
  width: 100%;

  border-radius: 5px;
  &:focus {
    border: 1px solid ${colors.primary};
  }
`

TextInput.defaultProps = {
  fontFamily: 'main',
  color: 'dark'
}
