import React, { useState } from 'react'
import { Flex, Text } from '../../../components/primitives'
import { ImageContain } from '../../../components/primitives/ImageCover'
import { ProductHolder } from './ProductHolder'
import { getVendor } from '../../../modules/getVendor'
import { getAffiliateUrl } from '../../../modules/util'

export const NotAcceptedProducts = ({ initialProducts, onChangeTotal }) => {
  const [products, setProducts] = useState(
    initialProducts.map((p) => {
      return { ...p, checked: true }
    })
  )
  function ccyFormat(num) {
    return `${parseInt(num).toFixed(0)}`
  }

  function total(items) {
    let products = items.filter((p) => p.checked)
    const t = products
      .map(({ price, quantity }) => price * quantity)
      .reduce((sum, i) => sum + i, 0)
    onChangeTotal(t)
    return t
  }

  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection={'column'} alignItems={'center'} mb={2}>
        <Text fontWeight={'bold'} color={'dark'} fontSize={6} mb={2}></Text>
        <Text style={{ maxWidth: '500px', textAlign: 'center' }} color={'dark'}>
          Klicka på produkterna för att lägga beställning hos respektive
          leverantör.
        </Text>
        <Text style={{ maxWidth: '500px', textAlign: 'center' }} color={'dark'}>
          Click on the products to place order with the supplier.
        </Text>
      </Flex>
      <Flex
        style={{ flexWrap: 'wrap' }}
        jusifyContent={'center'}
        width={'100%'}
        pr={2}
        pl={2}>
        {products.length > 0 &&
          products.map((product, index) => (
            <ProductHolder
              showAdd={false}
              selected={false}
              onClick={() => {
                window.open(getAffiliateUrl(product.url), '_blank')
              }}
              key={index}>
              <Flex
                flex={1}
                flexDirection={'column'}
                onClick={() => {
                  //window.open(product.url, '_blank')
                }}>
                {product.room && (
                  <Text
                    fontSize={1}
                    color={'primary'}
                    textAlign={'end'}
                    fontWeight={'bold'}
                    style={{
                      overflow: 'hidden',
                      height: '20px',
                      position: 'absolute',
                      top: 10,
                      left: 10
                    }}>
                    {product.room.toUpperCase()}
                  </Text>
                )}
                <ImageContain
                  height={'140px'}
                  width={'100%'}
                  mb={4}
                  src={product.imgSrc ? product.imgSrc : ''}
                />
                <Flex
                  flexDirection={'column'}
                  jusifyContent={'space-between'}
                  flex={1}>
                  <Text
                    fontSize={3}
                    color={'primary'}
                    textAlign={'center'}
                    style={{ overflow: 'hidden', height: '40px' }}
                    fontWeight={'bold'}>
                    {product.title}
                  </Text>

                  {product.vendor && (
                    <Text textAlign={'center'}>
                      {getVendor(product.vendor).name}
                    </Text>
                  )}

                  <Flex jusifyContent={'center'} />

                  <Flex height={'2px'} bg={'secondary'} width={'100%'} />

                  <Text fontWeight={'bold'} textAlign={'center'}>
                    Pris/Price: {ccyFormat(product.price)}
                  </Text>
                  <Text fontWeight={'bold'} textAlign={'center'}>
                    Antal/Qty: {product.quantity}
                  </Text>
                </Flex>
              </Flex>
            </ProductHolder>
          ))}
      </Flex>
      <Flex className={'print-break-before'} flexDirection={'column'}>
        <Text
          style={{ visibility: 'hidden' }}
          textAlign={'center'}
          fontWeight={'bold'}
          fontSize={'0rem'}>
          Summa: {ccyFormat(total(products))}
        </Text>
      </Flex>
    </Flex>
  )
}
