import React, { useState } from 'react'
import { Flex } from '../../components/primitives'
import ApplyForm from './ApplyForm'
import { CREATE_DECORATOR_APPLICATION } from '../../gql/decorators'
import { useMutation } from '@apollo/react-hooks'
import { uploadFiles } from '../../modules/upload'
import TermsDialog from './TermsDialog'

export const ApplyPage = () => {
  const [doCreate] = useMutation(CREATE_DECORATOR_APPLICATION)
  const [submiting, setSubmiting] = useState(false)
  const [termsDialogOpen, setTermsDialogOpen] = useState(false)
  const [errorSnack, setSetErrorSnack] = useState(false)
  const [success, setSuccess] = useState(false)
  return (
    <Flex
      jusifyContent={'center'}
      width={'100%'}
      alignItems={'center'}
      mt={2}
      mb={2}>
      <TermsDialog
        onClose={() => {
          setTermsDialogOpen(false)
        }}
        open={termsDialogOpen}></TermsDialog>
      <ApplyForm
        termsClick={() => {
          setTermsDialogOpen(true)
        }}
        showErrorSnack={errorSnack}
        onErrorSnackClose={() => {
          setSetErrorSnack(false)
        }}
        success={success}
        formSubmitting={submiting}
        onSubmit={({ files, values }) => {
          setSubmiting(true)
          doCreate({
            variables: {
              input: {
                ...values,
                files: files.map((f) => ({ origName: f.name }))
              }
            },
            update: (proxy, mutationResult) => {
              console.log(mutationResult)
              if (!mutationResult.data.createDecoratorApplication.success) {
                setSetErrorSnack(true)
                setSubmiting(false)
              } else {
                const f =
                  mutationResult.data.createDecoratorApplication.application
                    .files
                if (f) {
                  uploadFiles(f.map((f, i) => ({ ...f, ref: files[i] })))
                    .then((result) => {
                      setSubmiting(false)
                      console.log('files done done!')
                      setSuccess(true)
                    })
                    .catch(() => {
                      setSetErrorSnack(true)
                      setSubmiting(false)
                    })
                } else {
                  console.log('done!')
                  setSuccess(true)
                }
              }
            }
          })
        }}
      />
    </Flex>
  )
}
