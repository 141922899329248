import { Flex } from '../../components/primitives'
import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { SET_BLOG_ITEM_LIVE, BLOGSADMIN } from '../../gql/blog'
import { ListItem } from '../../components/sharable/ListItem'
import {
  ButtonWithLoading,
  SecondaryButton
} from '../../components/sharable/button'

const getUrl = () => {
  if (window.location.origin === 'https://adminstage.hemset.se') {
    return 'https://stage.hemset.se'
  }

  if (process.env.NODE_ENV !== 'production') {
    return 'http://localhost:8000'
  }

  return 'https://hemset.se'
}

export const BlogList = ({ edit }) => {
  const { data, refetch } = useQuery(BLOGSADMIN)
  const [setBlogItemLive] = useMutation(SET_BLOG_ITEM_LIVE)

  const setLive = ({ id, live }) => {
    setBlogItemLive({
      variables: { id: id, live: live },
      update: (proxy, mutationResult) => {
        if (!mutationResult.data.setBlogItemLive.success) {
        } else {
          refetch()
        }
      }
    })
  }

  return (
    <>
      <Flex mt={4} width={'100%'} flexDirection={'column'}>
        {data &&
          data.blogsAdmin &&
          data.blogsAdmin.map((blogItem) => {
            return (
              <ListItem
                title={blogItem.title}
                subTitle={blogItem.description}
                onClick={() => {}}
                files={blogItem.files}
                key={blogItem._id}
                actions={() => {
                  return (
                    <Flex>
                      <SecondaryButton
                        mr={2}
                        onClick={() => {
                          window.open(
                            getUrl() + '/previewBlog?id=' + blogItem._id,
                            '_blank'
                          )
                        }}>
                        Preview
                      </SecondaryButton>

                      <SecondaryButton
                        mr={2}
                        onClick={() => {
                          edit(blogItem)
                        }}>
                        Edit
                      </SecondaryButton>
                      {blogItem.live ? (
                        <ButtonWithLoading
                          onClick={() => {
                            setLive({ id: blogItem._id, live: false })
                          }}>
                          Go offline
                        </ButtonWithLoading>
                      ) : (
                        <ButtonWithLoading
                          onClick={() => {
                            setLive({ id: blogItem._id, live: true })
                          }}>
                          Go live
                        </ButtonWithLoading>
                      )}
                    </Flex>
                  )
                }}
              />
            )
          })}
      </Flex>
    </>
  )
}
