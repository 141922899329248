import React from 'react'
import { Flex, Text } from '../../../components/primitives'
import { FlexWithHover } from '../../../components/sharable'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { createAwinUrl } from '../../../modules/util'
import { ImageContain } from '../../../components/primitives/ImageCover'
export const ProductListTwo = ({
  userId,
  isAdmin,
  products,
  onDelete,
  onClick
}) => {
  return (
    <Flex width={'100%'} flexDirection={'column'} mt={2}>
      {products &&
        products.map((product) => {
          return (
            <FlexWithHover
              hoverColor={'#ededed'}
              key={product._id}
              mb={2}
              width={'100%'}
              height={['initial', '100px']}
              bg={'white'}
              p={2}
              flexDirection={['column', 'row']}
              jusifyContent={'space-between'}
              alignItems={'center'}>
              <Flex flex={1} flexDirection={['column', 'row']}>

                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={product.files ? product.files[0].url : ''}>
                  <ImageContain
                    height={'50px'}
                    width={'80px'}
                    src={product.files ? product.files[0].url : ''}
                  />
                </a>
                <Flex
                  flex={1}
                  onClick={() => {
                    onClick(createAwinUrl(product.url))
                  }}
                  ml={2}
                  jusifyContent={'center'}
                  flexDirection={'column'}>
                  <Text
                    fontSize={3}
                    color={'primary'}
                    textAlign={['center', 'left']}
                    fontWeight={'bold'}>
                    {product.name}
                  </Text>
                  <Text textAlign={['center', 'left']}>
                    {product.price &&
                      `${product.price.value} ${product.price.currency}`}
                  </Text>
                </Flex>
              </Flex>
              {userId === product.creatorId || isAdmin ? (
                <Flex>
                  <IconButton
                    aria-label='Delete'
                    onClick={() => onDelete(product._id)}>
                    <DeleteIcon />
                  </IconButton>
                </Flex>
              ) : null}
            </FlexWithHover>
          )
        })}
    </Flex>
  )
}
