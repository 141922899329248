import React, { useContext } from 'react'
import { Button, Flex } from '../../components/primitives'

import AuthContext from '../../modules/AuthContext'
import { isAdmin } from '../../modules/const'
import CreateOrderForm from './CreateOrderForm'

const CreateOrderPage = () => {
  const { currentUser, logout } = useContext(AuthContext)

  return (
    <Flex
      p={2}
      flexDirection={'column'}
      jusifyContent={'center'}
      alignItems={'center'}
      width={'100%'}>
      <Flex>
        {currentUser && isAdmin(currentUser['__typename']) && (
          <CreateOrderForm
            userId={currentUser._id}
            initialValues={{
              email: currentUser.email,
              name: currentUser.name,
              phone: currentUser.phone,
              address: currentUser.address,
              vatNumber: currentUser.vatNumber,
              bankNumber: currentUser.bankNumber
            }}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default CreateOrderPage
