import React, { useState } from 'react'
import { Text } from '../primitives'

export const OrderIdTitle = ({ title, ...props }) => {
  const [short, setShort] = useState(true)

  const getTitle = (text) => {
    if (text) {
      if (short) {
        return text.length > 11 ? text.slice(-5) : text
      }
    }

    return text
  }
  return (
    <Text
      onClick={() => {
        setShort(!short)
      }}
      {...props}>
      {getTitle(title)}
    </Text>
  )
}
