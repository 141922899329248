import React, { useContext, useEffect, useState, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMutation, useSubscription } from '@apollo/react-hooks'
import { LOGINANDGETORDERWITHTOKEN } from '../../gql/customer'
import AuthContext from '../../modules/AuthContext'
import {
  ADD_ORDER_COMMENT,
  COMMENTS_CHANGED,
  NOTIFY_USER_ON_COMPLETE_FILES_ON_COMMENT
} from '../../gql/orders'
import CustomerProducts from './components/CustomerProducts'
import OrderDiscussion from '../orderPage/components/OrderDiscussion'
import { Text } from '../../components/primitives/Text'
import request from 'superagent'
import { Flex, ImageCover } from '../../components/primitives'
import logo from '../../assets/images/hemset-logo.png'
import { CustomerReachProducts } from './components/CustomerReachProducts'
import { ColorCard } from './components/ColorCard'
import '../../style/fix.css'
import axios from 'axios'
import { colors } from '../../modules/colors'
import { ButtonWithLoading } from '../../components/sharable/button'
import { NotAcceptedProducts } from './components/NotAcceptedProducts'
import { ccyFormat } from '../../modules/util'

let apiUrl = 'https://admin.hemset.se/api'

if (window.location.origin === 'https://adminstage.hemset.se') {
  apiUrl = 'https://adminstage.hemset.se/api'
}

if (process.env.NODE_ENV !== 'production') {
  apiUrl = 'http://localhost:4000'
}

const notAcceptedVendors = [
  'hm_home',
  'ikea',
  'granit',
  'ellos',
  'reforma',
  'jotext',
  'nordic-nest',
  'calixter'
]
const getAcceptedVendors = (products) => {
  if (products && products.length > 0) {
    return products.filter((p) => {
      if (notAcceptedVendors.includes(p.vendor)) {
        return false
      }
      return true
    })
  }
  return []
}

const getNotAcceptedVendors = (products) => {
  if (products && products.length > 0) {
    return products.filter((p) => {
      if (notAcceptedVendors.includes(p.vendor)) {
        return true
      }
      return false
    })
  }

  return []
}

const getPDF = async (path) => {
  try {
    const response = await axios({
      method: 'post',
      responseType: 'arraybuffer',
      url: `${apiUrl}/createPdf`,
      data: {
        path: path
      }
    })

    const blob = new Blob([response.data], { type: 'image/png' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `hemset-koncept.png`
    link.click()
  } catch (e) {
    console.log(e)
  }
}
const CustomerPage = ({ match, history }) => {
  const { currentUser, login } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(false)
  const [selectedTotal, setSelectedTotal] = useState(0)
  const [notAccptSelectedTotal, setNotAccptSelectedTotal] = useState(0)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [order, setOrder] = useState(null)
  const [commentFiles, setCommentFiles] = useState([])
  const [uploading, setUploading] = useState(false)
  const [loginAndGetOrder] = useMutation(LOGINANDGETORDERWITHTOKEN)
  const [addOrderComment] = useMutation(ADD_ORDER_COMMENT)
  const [notifyUserOnCompleteFilesOnComment] = useMutation(
    NOTIFY_USER_ON_COMPLETE_FILES_ON_COMMENT
  )

  useSubscription(COMMENTS_CHANGED, {
    variables: { orderId: match.params.orderId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (
        subscriptionData &&
        subscriptionData.data &&
        subscriptionData.data.commentsChanged
      ) {
        const o = order
        o.comments = subscriptionData.data.commentsChanged
        setOrder({ ...o })
      }
    },
    skip: false
  })

  useEffect(() => {
    setIsLoading(true)
    loginAndGetOrder({
      variables: { token: match.params.token },
      update: (proxy, mutationResult) => {
        if (mutationResult.data.loginAndGetOrderWithToken.success) {
          if (mutationResult.data.loginAndGetOrderWithToken.user) {
            login(mutationResult.data.loginAndGetOrderWithToken.user)
          }
          setOrder(mutationResult.data.loginAndGetOrderWithToken.order)
          console.log(mutationResult.data.loginAndGetOrderWithToken.order)
        } else {
          console.log('Cant log in', mutationResult)
          setIsError(true)
        }

        setIsLoading(false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const uploadCommentFiles = ({ comment, fileMap, orderId }) => {
    setUploading(true)
    let ulPromises = []
    comment.files.forEach((f) => {
      const file = fileMap[f.origName]
      if (f.uploadUrl && file) {
        ulPromises.push(
          new Promise((resolve, reject) => {
            request
              .put(f.uploadUrl)
              .send(file)
              .retry()
              .on('progress', (event) => {
                console.log(`Uploading file ${f.origName}`, event)
              })
              .end((err, res) => {
                if (err) {
                  reject(err)
                } else {
                  resolve()
                }
              })
          })
        )
      }
    })
    Promise.all(ulPromises)
      .catch((err) => {
        //setError('There was a problem uploading the files')
        console.log('Failed to upload files', err)
      })
      .finally(() => {
        setUploading(false)
        // setOpen(false)
        notifyUserOnCompleteFilesOnComment({ variables: { orderId: orderId } })
      })
  }

  const handleFilesAddedToComment = (files) => {
    const f = [...commentFiles, ...files]
    setCommentFiles(f)
  }

  const handleCommentAdded = (comment) => {
    if (!comment) {
      return
    }
    const files = []
    const fileMap = {}
    let dontPub = false
    if (commentFiles && commentFiles.length > 0) {
      for (let i = 0; i < commentFiles.length; i++) {
        files.push({ origName: commentFiles[i].name })
        fileMap[commentFiles[i].name] = commentFiles[i]
      }
      dontPub = true
    }

    addOrderComment({
      variables: {
        orderId: order._id,
        input: {
          comment,
          files: files,
          dontPub: dontPub
        }
      },
      update: async (proxy, result) => {
        console.log(result)

        if (!result.data.addOrderComment.success) {
          alert('Gick inte att skicka')
        } else {
          const c = result.data.addOrderComment
          if (c && c.comment.files && c.comment.files.length > 0) {
            await uploadCommentFiles({
              comment: c.comment,
              fileMap,
              orderId: order._id
            })
          }

          setCommentFiles([])
        }
      }
    })
  }

  if (isLoading) {
    return (
      <Flex width={'100%'} height={'100vh'} p={4} jusifyContent={'center'}>
        <CircularProgress />
      </Flex>
    )
  }

  if (isError) {
    return <div>Something went wrong!</div>
  }

  return (
    <>
      <Flex flexDirection={'column'} bg={'white'}>
        {order && (
          <Fragment>
            <div
              id={'customer-page'}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Flex
                height={'60vh'}
                minHeight={'400px'}
                flexDirection={'column'}
                jusifyContent={'center'}
                alignItems={'center'}>
                <ImageCover
                  mb={'35px'}
                  width={['288px', '288px', '288px']}
                  height={['224px', '224px']}
                  src={logo}
                  alt='Logo'
                />

                {/*
                <Text
                  pr={2}
                  pl={2}
                  fontWeight={'bold'}
                  color={'dark'}
                  mb={'35px'}
                  textAlign={'center'}
                  fontSize={[5, 6, 8]}>
                  DITT NYA INREDNINGSKONCEPT!
                </Text>

                <ButtonWithLoading
                  isLoading={downloadLoading}
                  className={'dont-show-in-pdf'}
                  onClick={async () => {
                    try {
                      setDownloadLoading(true)
                      await getPDF(match.url)
                    } catch (e) {
                      alert('Kunda inte skapa bilden')
                    } finally {
                      setDownloadLoading(false)
                    }
                  }}>
                  Ladda ner som bild
                </ButtonWithLoading>*/}
              </Flex>

              <Flex
                pt={'100px'}
                pb={'100px'}
                width={'100%'}
                minHeight={'400px'}
                style={{ backgroundColor: colors.primaryLight }}
                flexDirection={'column'}
                jusifyContent={'center'}
                alignItems={'center'}>
                <Text
                  fontWeight={'bold'}
                  color={'dark'}
                  textAlign={'center'}
                  fontSize={[6, 8]}
                  mb={'35px'}
                  mt={['35px', '0px']}>
                  BESKRIVNING / DESCRIPTION
                </Text>
                <Text
                  mr={['3%', '20%']}
                  ml={['3%', '20%']}
                  mb={['35px', '0px']}
                  textAlign={'center'}
                  fontSize={4}
                  mt={2}>
                  {order.desc}
                </Text>
              </Flex>

              <Flex bg={'white'} flexDirection={'column'}>
                {order.moodboards && order.moodboards.length && (
                  <Flex
                    mt={'140px'}
                    flexDirection={'column'}
                    jusifyContent={'center'}
                    alignItems={'center'}>
                    <Text
                      mb={'35px'}
                      fontWeight={'bold'}
                      color={'dark'}
                      textAlign={'center'}
                      fontSize={[6, 8]}>
                      MOODBOARD
                    </Text>
                    {order.moodboards.map((file) => (
                      <Flex pr={4} pl={4} mb={4} key={file._id}>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={file.url}>
                          <img
                            style={{
                              boxShadow: '0 2px 5px 2px rgba(192,191,191,0.5)',
                              maxWidth: '1000px',
                              maxHeight: '600px',
                              width: '100%',
                              height: 'auto'
                            }}
                            src={file.url}
                            alt=''
                          />
                        </a>
                      </Flex>
                    ))}
                  </Flex>
                )}

                {order.floorplans && order.floorplans.length && (
                  <Flex
                    mt={'140px'}
                    flexDirection={'column'}
                    jusifyContent={'center'}
                    alignItems={'center'}>
                    <Text
                      mb={'35px'}
                      fontWeight={'bold'}
                      color={'dark'}
                      textAlign={'center'}
                      fontSize={[6, 8]}>
                      PLANLÖSNING / FLOOR PLAN
                    </Text>
                    {order.floorplans.map((file) => (
                      <Flex pr={4} pl={4} mb={4} key={file._id}>
                        <a
                          rel='noopener noreferrer'
                          target='_blank'
                          href={file.url}>
                          <img
                            style={{
                              maxWidth: '1000px',
                              maxHeight: '600px',
                              width: '100%',
                              height: 'auto',
                              boxShadow: '0 2px 5px 2px rgba(192,191,191,0.5)'
                            }}
                            src={file.url}
                            alt=''
                          />
                        </a>
                      </Flex>
                    ))}
                  </Flex>
                )}
              </Flex>

              {order.wallColors && order.wallColors.length && (
                <Flex
                  bg={'white'}
                  flexDirection={'column'}
                  width={'100%'}
                  pl={2}
                  pr={2}
                  style={{ alignSelf: 'center' }}
                  alignItems={'center'}
                  jusifyContent={'center'}
                  maxWidth={'1000px'}
                  mt={'140px'}>
                  <Text
                    mb={'35px'}
                    fontWeight={'bold'}
                    color={'dark'}
                    textAlign={'center'}
                    fontSize={[6, 8]}>
                    VÄGGFÄRGER / WALL COLORS
                  </Text>

                  <Flex flexWrap={'wrap'} jusifyContent={'center'}>
                    {order.wallColors.map((c, index) => {
                      return (
                        <ColorCard
                          key={index}
                          title={c.title}
                          color={c.description}
                          url={c.url}
                        />
                      )
                    })}
                  </Flex>
                </Flex>
              )}

              <Flex
                bg={'white'}
                flexDirection={'column'}
                width={'100%'}
                pl={2}
                pr={2}
                style={{ alignSelf: 'center' }}
                alignItems={'center'}
                jusifyContent={'center'}
                maxWidth={'1000px'}
                mt={'140px'}
                mb={'140px'}>
                <Text
                  fontWeight={'bold'}
                  color={'dark'}
                  textAlign={'center'}
                  fontSize={[6, 8]}>
                  PRODUKTER / PRODUCTS
                </Text>
                {order.products && order.products.length !== 0 && (
                  <CustomerProducts
                    budget={
                      order.customerOrder ? order.customerOrder.budget : 0
                    }
                    products={order.products ? order.products : []}
                  />
                )}

                {order &&
                  order.manualProducts &&
                  order.manualProducts.length !== 0 && (
                    <>
                      {/*<CustomerReachProducts
                        onChangeTotal={(t) => {
                          setSelectedTotal(t)
                        }}
                        history={history}
                        orderId={order && order._id}
                        initialProducts={
                          order.reachProducts
                            ? getAcceptedVendors(order.reachProducts)
                            : []
                        }
                      />*/}

                      <NotAcceptedProducts
                        onChangeTotal={(t) => {
                          console.log(t)
                          setNotAccptSelectedTotal(t)
                        }}
                        initialProducts={order.manualProducts.sort(function(
                          a,
                          b
                        ) {
                          const textA = a.room ? a.room.toUpperCase() : ''
                          const textB = b.room ? b.room.toUpperCase() : ''
                          return textA < textB ? -1 : textA > textB ? 1 : 0
                        })}
                      />
                    </>
                  )}

                {order &&
                  order.reachProducts &&
                  order.reachProducts.length !== 0 && (
                    <>
                      {/*<CustomerReachProducts
                        onChangeTotal={(t) => {
                          setSelectedTotal(t)
                        }}
                        history={history}
                        orderId={order && order._id}
                        initialProducts={
                          order.reachProducts
                            ? getAcceptedVendors(order.reachProducts)
                            : []
                        }
                      />*/}

                      <NotAcceptedProducts
                        onChangeTotal={(t) => {
                          setNotAccptSelectedTotal(t)
                        }}
                        initialProducts={order.reachProducts}
                      />
                    </>
                  )}

                <Text
                  mt={6}
                  textAlign={'center'}
                  fontWeight={'bold'}
                  fontSize={'1.375rem'}>
                  Total: {ccyFormat(selectedTotal + notAccptSelectedTotal)}
                </Text>
                <Text
                  mt={2}
                  textAlign={'center'}
                  fontSize={'1.375rem'}
                  mb={'35px'}>
                  Budget:{' '}
                  {order.customerOrder
                    ? ccyFormat(order.customerOrder.budget)
                    : 0}
                </Text>
              </Flex>
            </div>
            {currentUser && (
              <Flex
                bg={'white'}
                flexDirection={'column'}
                width={'100%'}
                mb={'140px'}>
                <>
                  <Text
                    mb={'35px'}
                    fontWeight={'bold'}
                    color={'dark'}
                    textAlign={'center'}
                    fontSize={[6, 8]}>
                    DISKUSSION / DISCUSSION
                  </Text>
                  <Flex mr={['3%', '15%']} ml={['3%', '15%']}>
                    <OrderDiscussion
                      isOpen={order.status === 'completed' ? false : true}
                      title={''}
                      placeholder={'Kommentar till inredaren'}
                      buttonTitle={'Skicka'}
                      comments={order.comments ? order.comments : []}
                      currentUser={currentUser}
                      uploading={uploading}
                      filesToAd={commentFiles}
                      onFilesChanged={handleFilesAddedToComment}
                      onCommentAdded={handleCommentAdded}
                    />
                  </Flex>
                </>
              </Flex>
            )}
          </Fragment>
        )}
      </Flex>
    </>
  )
}

export default withRouter(CustomerPage)
