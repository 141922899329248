import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import AddProductToOrderDialog from './AddProductToOrderDialog'
import { PaperTitle } from './index'
import { Flex } from '../../../components/primitives'
import { ProductCard } from './ProductCard'
import { useMutation } from '@apollo/react-hooks'
import { REMOVE_MANUAL_PRODUCT_FROM_ORDER } from '../../../gql/orders'
const useStyles = makeStyles(() => {
  return {
    header: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    productTable: {
      borderCollapse: 'separate',
      emptyCells: 'hide'
    }
  }
})

function OrderProducts({
  fullScreen,
  onAdded,
  canEdit = false,
  onDelete,
  title = 'PRODUCTS',
  order
}) {
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [removeProduct] = useMutation(REMOVE_MANUAL_PRODUCT_FROM_ORDER)

  const classes = useStyles()

  const handleAddProduct = () => {
    setShowAddDialog(true)
  }

  const handleCloseDialog = (added) => {
    setShowAddDialog(false)
  }

  return (
    <Flex flexDirection={'column'}>
      <div className={classes.header}>
        <PaperTitle title={title} />
        {canEdit && (
          <Tooltip title='Add Product'>
            <IconButton size='small' onClick={handleAddProduct}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
        {canEdit && (
          <AddProductToOrderDialog
            fullScreen={fullScreen}
            open={showAddDialog}
            orderId={order._id}
            onClose={handleCloseDialog}
            onAdd={onAdded}
          />
        )}
      </div>
      <Flex flexWrap={'wrap'} mt={2}>
        {order &&
          order.manualProducts &&
          order.manualProducts
            .sort(function(a, b) {
              const textA = a.room ? a.room.toUpperCase() : ''
              const textB = b.room ? b.room.toUpperCase() : ''
              return textA < textB ? -1 : textA > textB ? 1 : 0
            })
            .map((prdct, index) => {
              return (
                <ProductCard
                  key={index}
                  showDelete={true}
                  showAdd={false}
                  title={
                    prdct.room ? prdct.room + ' : ' + prdct.title : prdct.title
                  }
                  imgSrc={prdct.imgSrc}
                  onClick={() => {
                    window.open(prdct.url, '_blank')
                  }}
                  price={prdct.price}
                  quantity={prdct.quantity}
                  stock={''}
                  deleteClick={() => {
                    removeProduct({
                      variables: {
                        orderId: order._id,
                        productId: prdct.productId
                      },
                      update: (proxy, result) => {
                        if (!result.data.removeManualProduct.success) {
                          alert('Something went wrrong')
                        }

                        onDelete()
                      }
                    })
                  }}
                />
              )
            })}
      </Flex>
    </Flex>
  )
}

export default OrderProducts
