import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Flex, Text } from '../../components/primitives'
import { HookInput } from '../../components/sharable/form/HookInput'
import { ButtonWithLoading } from '../../components/sharable/button'
import { useMutation } from '@apollo/react-hooks'
import { showError } from '../../modules/Alert'
import { LOGIN } from '../../gql/auth'

const BasicSchema = Yup.object().shape({
  email: Yup.string().required('title is required'),
  password: Yup.string().required('password is required')
})

export const LoginForm = ({ loggedIn }) => {
  const [formLoading, setFormLoading] = useState(false)
  const [doLogin] = useMutation(LOGIN)

  return (
    <Flex
      width={'100%'}
      maxWidth={'700px'}
      bg={'white'}
      p={4}
      style={{ borderRadius: '5px' }}
      flexDirection={'column'}>
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={BasicSchema}
        onSubmit={(values, actions) => {
          setFormLoading(true)

          doLogin({
            variables: { email: values.email, password: values.password },
            update: (proxy, mutationResult) => {
              console.log(mutationResult)
              if (!mutationResult.data.login.success) {
                showError({ message: 'Failed! 😥😭' })
                setFormLoading(false)
              } else {
                setFormLoading(false)
                loggedIn(mutationResult.data.login.user)
              }
            }
          })
        }}>
        {(props) => (
          <form style={{ width: '100%' }} onSubmit={props.handleSubmit}>
            <Text fontWeight={'bold'} fontSize={5}>
              Login
            </Text>
            <Flex mb={2} mt={2} flexDirection={'column'}>
              <Text>Email</Text>
              <HookInput
                type={'text'}
                name={'email'}
                onChange={props.handleChange}
                value={props.email}
                onBlur={props.handleBlur}
                style={{ width: '100%' }}
              />
            </Flex>

            <Flex mb={2} mt={2} flexDirection={'column'}>
              <Text>Password</Text>
              <HookInput
                type={'password'}
                name={'password'}
                onChange={props.handleChange}
                value={props.password}
                onBlur={props.handleBlur}
                style={{ width: '100%' }}
              />
            </Flex>

            <Flex
              style={{ justifyContent: 'flex-end' }}
              width={'100%'}
              mt={2}
              mb={2}>
              <ButtonWithLoading isLoading={formLoading} type='submit'>
                Login
              </ButtonWithLoading>
            </Flex>
          </form>
        )}
      </Formik>
    </Flex>
  )
}
