import React, { useEffect, useCallback, useRef } from 'react'
import { fabric } from 'fabric'
import { useDropzone } from 'react-dropzone'
import { Flex } from '../primitives'

import { moodboardOne } from '../../assets/moodboards'
import { clipTo } from '../../modules/canvasHelpers'

const createCollageOverlay = (objects, strokeWidth, color) => {
  const list = []
  objects.forEach((object) => {
    const rect1 = new fabric.Rect({
      originX: 'left',
      originY: 'top',
      left: object.x,
      top: object.y,
      width: object.w - strokeWidth / 2 + 5, // fabricjs avrundar konstigt så får lägga på lite så det inte blir mellanrum
      height: object.h - strokeWidth / 2 + 5,
      stroke: color,
      strokeWidth: strokeWidth / 2,
      selectable: false,
      evented: false,
      fill: 'transparent'
    })
    rect1.isOverlay = true

    list.push({ rect: rect1 })
  })

  return list
}

const createCanvasStroke = ({ height, width, strokeWidth, color }) => {
  const rect1 = new fabric.Rect({
    originX: 'left',
    originY: 'top',
    left: 0,
    top: 0,
    width: width - strokeWidth,
    height: height - strokeWidth,
    stroke: color,
    strokeWidth: strokeWidth,
    selectable: false,
    evented: false,
    fill: 'transparent'
  })
  rect1.isCanvasStroke = true

  return rect1
}

const getOverlaysCoordinates = ({ canvasHeight, canvasWidth, overlays }) => {
  const height = canvasHeight
  const width = canvasWidth

  const list = overlays.map((rect) => {
    const x = rect.x * width
    const y = rect.y * height
    const w = rect.width * width
    const h = rect.height * height
    const position = rect.position
    return { x, y, w, h, position }
  })

  return list
}

const contains = (x, y, rX, rY, rW, rH) => {
  return rX <= x && x <= rX + rW && rY <= y && y <= rY + rH
}

export const MoodboardCreator = ({ onFabricCanvasCreated }) => {
  const canvasRef = useRef()
  const canvasHolderRef = useRef()
  const fileInputRef = useRef()
  const fileInputManyRef = useRef()
  const fabricCanvas = useRef()
  const canvasStroke = useRef()
  const pointer = useRef()
  const overlayRects = useRef()
  const selectedObject = useRef()

  const onDrop = useCallback((acceptedFiles) => {
    const rect = getImageClickedRect()
    if (rect) {
      addImageToCanvas(acceptedFiles[0], rect)
      removeOverlayRect(rect)
      removeImageIfExist(rect)
      console.log('adddd image', rect)
    }
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  // const hasMoved = useRef()

  useEffect(() => {
    fabricCanvas.current = new fabric.Canvas(canvasRef.current, {
      backgroundColor: '#e4e4e4',
      height: canvasHolderRef.current.clientHeight,
      width: canvasHolderRef.current.clientWidth,
      preserveObjectStacking: true
    })

    onFabricCanvasCreated(fabricCanvas.current)

    addCanvasEvent()
    loadCanvas()
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeImageIfExist = (rect) => {
    fabricCanvas.current.getObjects().forEach((obj) => {
      if (obj.isImage) {
        if (rect.position === obj.clipRect.position) {
          fabricCanvas.current.remove(obj)
        }
      }
    })
  }

  const removeOverlayRect = (rect) => {
    overlayRects.current.forEach((r) => {
      if (
        contains(
          rect.x,
          rect.y,
          r.rect.left,
          r.rect.top,
          r.rect.width,
          r.rect.height
        )
      ) {
        //  r.rect.set('fill', 'transparent')
        fabricCanvas.current.renderAll()
      }
    })
  }

  const makeOtherImagesNotClickAble = () => {
    fabricCanvas.current.getObjects().forEach((obj) => {
      if (
        obj.isImage &&
        obj.clipRect.position !== selectedObject.current.clipRect.position
      ) {
        obj.clipTo = clipTo
        obj.opacity = 0.5
        obj.selectable = false
      }
    })
    fabricCanvas.current.renderAll()
  }

  const makeImagesClickAble = () => {
    fabricCanvas.current.getObjects().forEach((obj) => {
      if (obj.isImage) {
        obj.clipTo = clipTo
        obj.opacity = 1
        obj.selectable = true
      }
    })
    fabricCanvas.current.renderAll()
  }

  const bringImageToFront = (img) => {
    fabricCanvas.current.bringToFront(img)
    fabricCanvas.current.getObjects().forEach((obj) => {
      if (obj.isCanvasStroke || obj.isOverlay) {
        fabricCanvas.current.bringToFront(obj)
      }
    })
    fabricCanvas.current.renderAll()
  }

  const addCanvasEvent = () => {
    fabricCanvas.current.on('mouse:up', (options) => {
      pointer.current = {
        x: options.pointer.x,
        y: options.pointer.y
      }
    })

    fabricCanvas.current.on('dragover', (options) => {
      console.log(options.e)
      pointer.current = {
        x: options.e.offsetX,
        y: options.e.offsetY
      }
    })

    fabricCanvas.current.on('object:selected', function(event) {
      if (event.target.isImage) {
        makeImagesClickAble()
        console.log(event)
        event.target.clipTo = null
        event.target.opacity = 1
        bringImageToFront(event.target)
        selectedObject.current = event.target
        makeOtherImagesNotClickAble()
      }
    })

    fabricCanvas.current.on('selection:cleared', function(e) {
      if (selectedObject.current) {
        console.log('selection:cleared')
        //fabricCanvas.current.sendToBack(selectedObject.current)
        makeImagesClickAble()
        selectedObject.current = null
      }
    })

    /*
    fabricCanvas.current.on('object:moving', (options) => {
      //hasMoved.current = true
    }) */

    /*
    fabric.util.addListener(
      fabricCanvas.current.upperCanvasEl,
      'mouseup',
      (e) => {
        if (!hasMoved.current) {
          fileInputRef.current.click()
        }
      }
    ) */

    fabric.util.addListener(
      fabricCanvas.current.upperCanvasEl,
      'dblclick',
      (e) => {
        fileInputRef.current.click()
      }
    )

    /*
    const options = {
      passive: false
    } */
  }
  const loadCanvas = () => {
    overlayRects.current = createCollageOverlay(
      getOverlaysCoordinates({
        canvasHeight: fabricCanvas.current.height,
        canvasWidth: fabricCanvas.current.width,
        overlays: moodboardOne
      }),
      20,
      '#fff'
    )
    overlayRects.current.forEach((obj) => {
      fabricCanvas.current.add(obj.rect)
    })

    canvasStroke.current = createCanvasStroke({
      height: fabricCanvas.current.height,
      width: fabricCanvas.current.width,
      color: '#fff',
      strokeWidth: 20
    })

    fabricCanvas.current.add(canvasStroke.current)

    fabricCanvas.current.renderAll()
  }

  const getRectFromOverlayObject = (object) => {
    let rect = null
    getOverlaysCoordinates({
      canvasHeight: fabricCanvas.current.height,
      canvasWidth: fabricCanvas.current.width,
      overlays: moodboardOne
    }).forEach((r) => {
      if (contains(object.left, object.top, r.x, r.y, r.w, r.h)) {
        console.log(r)
        rect = r
      }
    })
    return rect
  }

  const getImageClickedRect = () => {
    let rect = null
    getOverlaysCoordinates({
      canvasHeight: fabricCanvas.current.height,
      canvasWidth: fabricCanvas.current.width,
      overlays: moodboardOne
    }).forEach((r) => {
      if (contains(pointer.current.x, pointer.current.y, r.x, r.y, r.w, r.h)) {
        rect = r
      }
    })
    return rect
  }

  const addImageToCanvas = (imageSrc, rect) => {
    if (imageSrc) {
      const reader = new FileReader()
      reader.onload = (event) => {
        const imgObj = new Image()
        imgObj.src = event.target.result
        imgObj.onload = function() {
          const image = new fabric.Image(imgObj)

          const canvasAspect = rect.w / rect.h
          const imgAspect = image.width / image.height
          let scaleFactor

          if (canvasAspect >= imgAspect) {
            scaleFactor = rect.w / image.width
            //image.lockMovementX = true
          } else {
            scaleFactor = rect.h / image.height
            //image.lockMovementY = true
          }

          image.set({
            scaleX: scaleFactor,
            scaleY: scaleFactor
          })

          image.hasControls = true
          image.hasRotatingPoint = false
          image.left = rect.x
          image.top = rect.y
          image.hasBorders = false
          image.isImage = true
          image.set({
            clipRect: rect,
            clipTo: clipTo
          })

          fabricCanvas.current.add(image)
          fabricCanvas.current.moveTo(image, 0)

          fabricCanvas.current.renderAll()
        }
      }
      reader.readAsDataURL(imageSrc)
    }
  }

  const onImageSelect = (event) => {
    const rect = getImageClickedRect()
    addImageToCanvas(event.target.files[0], rect)
    removeOverlayRect(rect)
    removeImageIfExist(rect)
  }

  const onImageSelectMultiple = (event) => {
    console.log(event.target.files)

    let count = 0
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i]
      const objects = fabricCanvas.current.getObjects()

      if (objects.length > 0 && count < objects.length) {
        const obj = objects[count]
        if (obj) {
          const rect = getRectFromOverlayObject(obj)
          removeOverlayRect(rect)
          removeImageIfExist(rect)
          addImageToCanvas(file, rect)
          fabricCanvas.current.renderAll()
          console.log('???')
        }
      }
      count = count + 1
    }
  }

  return (
    <Flex
      jusifyContent={'center'}
      alignItems={'center'}
      {...getRootProps({
        onClick: (event) => event.stopPropagation(),
        multiple: false,
        style: { outline: 'none' }
      })}>
      <input {...getInputProps()} />
      <Flex
        ref={canvasHolderRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '600px',
          width: '800px'
        }}>
        <input
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept='image/*'
          type='file'
          onChange={onImageSelect}
        />
        <input
          multiple='multiple'
          ref={fileInputManyRef}
          style={{ display: 'none' }}
          accept='image/*'
          type='file'
          onChange={onImageSelectMultiple}
        />
        <canvas ref={canvasRef} />
      </Flex>
    </Flex>
  )
}
