import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Flex, Text } from '../../components/primitives'
import { HookInput } from '../../components/sharable/form/HookInput'
import { ButtonWithLoading } from '../../components/sharable/button'
import FileContainer from '../../components/FileContainer'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5-build-classic-text-alignment-mc'
import { useMutation } from '@apollo/react-hooks'
import { ADD_BLOG_ITEM, EDIT_BLOG_ITEM } from '../../gql/blog'
import { uploadFiles } from '../../modules/upload'
import { showAlert, showError } from '../../modules/Alert'
import { HookDropdown } from '../../components/sharable/form/HookDropdown'

const editorConfiguration = {
  link: {
    decorators: {
      addTargetToExternalLinks: {
        mode: 'manual',
        label: 'Open in new tab',
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      }
    }
  }
}

const BasicSchema = Yup.object().shape({
  title: Yup.string().required('title is required'),
  description: Yup.string().required('title is description'),
  url: Yup.string().required('url is required')
})

const getCategory = (cat) => {
  if (cat === 'INREDAREN TIPSAR / THE DECORATOR TIPS') {
    return 'tip'
  } else if (cat === 'KUNDEN BERÄTTAR / THE CUSTOMER TELLS') {
    return 'customer'
  } else if (cat === 'OM TJÄNSTEN / ABOUT THE SERVICE') {
    return 'service'
  } else if (cat === 'VÅRA INREDARE / OUR DECORATORS') {
    return 'decorator'
  } else if (cat === 'VÅRA VÄNNER / OUR FRIENDS') {
    return 'friends'
  } else if (cat === 'INSPIRATION') {
    return 'inspo'
  } else {
    return 'inspo'
  }
}

const getCategoryTitle = (cat) => {
  if (cat === 'tip') {
    return 'INREDAREN TIPSAR / THE DECORATOR TIPS'
  } else if (cat === 'customer') {
    return 'KUNDEN BERÄTTAR / THE CUSTOMER TELLS'
  } else if (cat === 'service') {
    return 'OM TJÄNSTEN / ABOUT THE SERVICE'
  } else if (cat === 'decorator') {
    return 'VÅRA INREDARE / OUR DECORATORS'
  } else if (cat === 'friends') {
    return 'VÅRA VÄNNER / OUR FRIENDS'
  } else if (cat === 'inspo') {
    return 'INSPIRATION'
  } else {
    return 'INSPIRATION'
  }
}

export const BlogForm = ({ added, isEdit }) => {
  const [formLoading, setFormLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [html, setHtml] = useState('')
  const [newFile, setNewFile] = useState(false)
  const [addBlogItem] = useMutation(ADD_BLOG_ITEM)
  const [editBlogItem] = useMutation(EDIT_BLOG_ITEM)

  useEffect(() => {
    if (isEdit && isEdit.files) {
      setFiles(isEdit.files)
    }
  }, [isEdit])

  return (
    <Flex
      width={'100%'}
      maxWidth={'700px'}
      bg={'white'}
      p={4}
      style={{ borderRadius: '5px' }}
      flexDirection={'column'}>
      <Formik
        enableReinitialize
        initialValues={{
          title: isEdit && isEdit.title ? isEdit.title : '',
          description: isEdit && isEdit.description ? isEdit.description : '',
          url: isEdit && isEdit.url ? isEdit.url : '',
          metaTitle: isEdit && isEdit.metaTitle ? isEdit.metaTitle : '',
          metaDesc: isEdit && isEdit.metaDesc ? isEdit.metaDesc : '',
          category:
            isEdit && isEdit.category
              ? getCategoryTitle(isEdit.category)
              : getCategoryTitle('tip')
        }}
        validationSchema={BasicSchema}
        onSubmit={(values, actions) => {
          setFormLoading(true)

          if (files && files.length > 0) {
            if (isEdit) {
              console.log(values)
              editBlogItem({
                variables: {
                  ...values,
                  category: getCategory(values.category),
                  id: isEdit._id,
                  isNewFile: newFile,
                  html: html,
                  files: files.map((f) => ({ origName: f.name }))
                },
                update: (proxy, mutationResult) => {
                  console.log(mutationResult)
                  if (!mutationResult.data.editBlogItem.success) {
                    showError({ message: 'Failed! 😥😭' })
                    setFormLoading(false)
                  } else {
                    const f = mutationResult.data.editBlogItem.blog.files
                    if (f && newFile) {
                      uploadFiles(f.map((f, i) => ({ ...f, ref: files[i] })))
                        .then((result) => {
                          setFormLoading(false)
                          showAlert({ message: 'Saved! 👏🏄‍️💾' })
                          added()
                        })
                        .catch((e) => {
                          console.log(e)
                          showError({ message: 'Failed to upload files! 😥😭' })
                          setFormLoading(false)
                        })
                    } else {
                      setFormLoading(false)
                      showAlert({ message: 'Saved! 👏🏄‍️💾' })
                      added()
                    }
                  }
                }
              })
            } else {
              addBlogItem({
                variables: {
                  ...values,
                  category: getCategory(values.category),
                  html: html,
                  files: files.map((f) => ({ origName: f.name }))
                },
                update: (proxy, mutationResult) => {
                  console.log(mutationResult)
                  if (!mutationResult.data.addBlogItem.success) {
                    showError({ message: 'Failed! 😥😭' })
                    setFormLoading(false)
                  } else {
                    const f = mutationResult.data.addBlogItem.blog.files
                    if (f) {
                      uploadFiles(f.map((f, i) => ({ ...f, ref: files[i] })))
                        .then((result) => {
                          setFormLoading(false)
                          showAlert({ message: 'Saved! 👏🏄‍️💾' })
                          added()
                        })
                        .catch((e) => {
                          console.log(e)
                          showError({ message: 'Failed to upload files! 😥😭' })
                          setFormLoading(false)
                        })
                    } else {
                      console.log('done!')
                      showAlert({ message: 'Saved! 👏🏄‍️💾' })
                      setFormLoading(false)
                      added()
                    }
                  }
                }
              })
            }
          }
        }}>
        {(props) => (
          <form style={{ width: '100%' }} onSubmit={props.handleSubmit}>
            <Flex mb={2} mt={2} flexDirection={'column'}>
              <Text>Title</Text>
              <HookInput
                type={'text'}
                name={'title'}
                onChange={props.handleChange}
                value={props.title}
                onBlur={props.handleBlur}
                style={{ width: '100%' }}
              />
            </Flex>
            <Flex mb={2} mt={2} flexDirection={'column'}>
              <Text>Description</Text>
              <HookInput
                type={'text'}
                name={'description'}
                onChange={props.handleChange}
                value={props.description}
                onBlur={props.handleBlur}
                style={{ width: '100%' }}
              />
            </Flex>
            <Flex mb={2} mt={2} flexDirection={'column'}>
              <Text>Meta title</Text>
              <HookInput
                type={'text'}
                name={'metaTitle'}
                onChange={props.handleChange}
                value={props.metaTitle}
                onBlur={props.handleBlur}
                style={{ width: '100%' }}
              />
            </Flex>
            <Flex mb={2} mt={2} flexDirection={'column'}>
              <Text>Meta Description</Text>
              <HookInput
                type={'text'}
                name={'metaDesc'}
                onChange={props.handleChange}
                value={props.metaDesc}
                onBlur={props.handleBlur}
                style={{ width: '100%' }}
              />
            </Flex>

            <Flex mb={2} mt={2} flexDirection={'column'}>
              <Text>
                Url ( Don't start url with / example of a good url:
                stylish-interior-in-kitchen )
              </Text>
              <HookInput
                type={'text'}
                name={'url'}
                onChange={props.handleChange}
                value={props.url}
                onBlur={props.handleBlur}
                style={{ width: '100%' }}
              />
            </Flex>

            <Flex mb={2} mt={2} flexDirection={'column'}>
              <Text>Category</Text>
              <HookDropdown
                onSelect={(value) => {
                  props.setFieldValue('category', value)
                }}
                firstValue={
                  props.category ? props.category : 'INREDAREN TIPSAR / THE DECORATOR TIPS'
                }
                options={[
                  'INREDAREN TIPSAR / THE DECORATOR TIPS',
                  'KUNDEN BERÄTTAR / THE CUSTOMER TELLS',
                  'OM TJÄNSTEN / ABOUT THE SERVICE',
                  'VÅRA INREDARE / OUR DECORATORS',
                  'VÅRA VÄNNER / OUR FRIENDS',
                  'INSPIRATION'
                ]}
              />
            </Flex>

            <Flex mb={2} mt={2} flexDirection={'column'}>
              <Text mb={4}>Add Blog Header image</Text>
              <FileContainer
                files={files}
                onAddFiles={(file) => {
                  setFiles(file)
                  setNewFile(true)
                }}
                onDeleteFile={() => {
                  setFiles([])
                }}
              />
            </Flex>

            <Flex mb={2} mt={4} flexDirection={'column'}>
              <CKEditor
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  setHtml(editor.getData())
                }}
                config={editorConfiguration}
                data={isEdit && isEdit.html ? isEdit.html : ''}
              />
            </Flex>

            <Flex
              style={{ justifyContent: 'flex-end' }}
              width={'100%'}
              mt={2}
              mb={2}>
              <ButtonWithLoading isLoading={formLoading} type='submit'>
                Save
              </ButtonWithLoading>
            </Flex>
          </form>
        )}
      </Formik>
    </Flex>
  )
}
