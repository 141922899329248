import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { ORDER } from '../../gql/orders'
import { Flex } from '../../components/primitives'
import CircularProgress from '@material-ui/core/CircularProgress'
import { EditOrderForm } from './EditOrderForm'

export const EditOrderPage = () => {
  let { id } = useParams()
  const { data, loading } = useQuery(ORDER, {
    variables: { id: id },
    fetchPolicy: 'network-only'
  })

  if (loading) {
    return (
      <Flex width={'100%'} jusifyContent={'center'} p={4}>
        <CircularProgress />
      </Flex>
    )
  }

  console.log(data.order.customerOrder)

  return (
    <Flex jusifyContent={'center'} mt={4}>
      <EditOrderForm
        orderId={data.order._id}
        initial={data.order.customerOrder}
      />
    </Flex>
  )
}
